import { DatePipe, formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { TranslocoCurrencyPipe, TranslocoDecimalPipe, TranslocoLocaleService } from '@jsverse/transloco-locale';
import { MonthIdentifiers } from '@lsr/constants/monthIdentifiers.constants';
import { SanitizeHtmlPipe } from '@lsr/pipes/sanitizeHTML.pipe';
import { LSRAmountCardComponent } from '@lsr/ui-components/lsr-amount-card';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRDialogConfirmComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRTableBoxComponent } from '@lsr/ui-components/lsr-table/lsr-table-box';
import { LSRTableExpandableComponent } from '@lsr/ui-components/lsr-table/lsr-table-expandable';
import { TableData, TableRowData } from '@lsr/ui-components/lsr-table/lsr-table-interfaces';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { filter, first, forkJoin, Observable, take } from 'rxjs';
import { base64ToArrayBuffer, saveByteArray } from '../../../helpers/blobDownload';
import { PensionAcquisitionTypes } from '../../../helpers/constants/pension-acquisition-types.constants';
import { PensionFunds } from '../../../helpers/constants/pension-funds.constants';
import { PensionPaymentTypes } from '../../../helpers/constants/pension-payment-types.constants';
import { pensionBenefitsLSRBToCardData } from '../../../helpers/pensionHelpers';
import {
    EstimatedFuturePensionBenefitsExample,
    ExposedEstimatedFuturePensionBenefitsExamplesRes,
} from '../../../interfaces/responses/exposed/exposedEstimatedFuturePensionBenefitsExamples';
import { ExposedKriaPaidContributionsYearGroupRes } from '../../../interfaces/responses/exposed/exposedKriaPaidContributionsYearGroupRes';
import { ExposedKriaPensionPaymentsYearGroup } from '../../../interfaces/responses/exposed/exposedKriaPensionPaymentsYearGroup';
import { ExposedListKriaPensionPaymentsRes } from '../../../interfaces/responses/exposed/exposedListKriaPensionPaymentsRes';
import { ExposedListPensionBenefitsInOtherFundsRes } from '../../../interfaces/responses/exposed/exposedListPensionBenefitsInOtherFundsRes';
import { ExposedMeRes } from '../../../interfaces/responses/exposed/exposedMeRes';
import { ExposedPensionBenefitsLSRARes } from '../../../interfaces/responses/exposed/exposedPensionBenefitsLSRARes';
import { ExposedPensionBenefitsLSRBRes } from '../../../interfaces/responses/exposed/exposedPensionBenefitsLSRBRes';
import { ContributionsService } from '../../../services/contributions/contributions.service';
import { PensionService } from '../../../services/pension/pension.service';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-common-pension',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRAmountCardComponent,
        LSRTableExpandableComponent,
        LSRTableBoxComponent,
        LSRDialogConfirmComponent,
        LSRButtonComponent,
        LSRLoaderComponent,
        TranslocoCurrencyPipe,
        TranslocoDecimalPipe,
        TranslocoPipe,
        SanitizeHtmlPipe,
    ],
    templateUrl: 'common-pension.component.html',
    styleUrl: './common-pension.component.scss',
    host: { class: 'main__content' },
})
export class CommonPensionComponent implements OnInit, AfterViewInit {
    @ViewChild(LSRDialogConfirmComponent) confirmDialog!: LSRDialogConfirmComponent;
    userDetails?: ExposedMeRes;
    loading: boolean = false;
    pensionBenefitsLSRA?: ExposedPensionBenefitsLSRARes;

    pensionBenefitsLSRB?: ExposedPensionBenefitsLSRBRes;
    pensionBenefitsLSRBCardData?: { amountString: string; description: string };

    contributionsLSRA?: ExposedKriaPaidContributionsYearGroupRes[];
    contributionsLSRB?: ExposedKriaPaidContributionsYearGroupRes[];

    pensionPaymentsLSRA?: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[];
    latestPensionPaymentsLSRACardData?: { amount: number; description: string };
    pensionPaymentsLSRB?: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[];
    latestPensionPaymentsLSRBCardData?: { amount: number; description: string };

    pensionBenefitsInOtherFundsLoading: boolean = false;
    pensionBenefitsInOtherFunds?: ExposedListPensionBenefitsInOtherFundsRes[];
    pensionBenefitsInOtherFundsIncludeAmounts?: boolean;
    pensionBenefitsInOtherFundsCardData?: { amount: number; age: number; showIncompleteDataMessage: boolean };

    estimatedFuturePensionBenefitsExamples?: ExposedEstimatedFuturePensionBenefitsExamplesRes;
    estimatedFuturePensionBenefitsExamplesMinifiedAgesShown = [60, 65, 67, 70];
    estimatedFuturePensionbenefitsExamplesTableExpanded: boolean = false;

    loadingPensionSummaryLSRA = false;
    loadingPensionSummaryLSRB = false;

    contributionsLSRATableData: TableData = {
        headerTitles: [
            'mppTableHeaderPeriod',
            'mppTableHeaderFundPartnerContribution',
            'mppTableHeaderEmployerContribution',
            'mppTableHeaderTotal',
        ],
        rows: [],
    };

    contributionsLSRBTableData: TableData = {
        headerTitles: [
            'mppTableHeaderPeriod',
            'mppTableHeaderFundPartnerContribution',
            'mppTableHeaderEmployerContribution',
            'mppTableHeaderTotal',
        ],
        rows: [],
    };

    paymentsLSRATableData: TableData = {
        headerTitles: [
            'mppTableHeaderPeriod',
            'mppTableHeaderPayout',
            'mppTableHeaderWithholdingTax',
            'mppTableHeaderTotal',
        ],
        rows: [],
    };

    paymentsLSRBTableData: TableData = {
        headerTitles: [
            'mppTableHeaderPeriod',
            'mppTableHeaderPayout',
            'mppTableHeaderWithholdingTax',
            'mppTableHeaderTotal',
        ],
        rows: [],
    };

    estimatedFuturePensionBenefitsExamplesTableData: TableData = {
        headerTitles: [
            'mppTableHeaderAgeAtRetirement',
            'mppTableHeaderNoFurtherContributions',
            'mppTableHeaderIncludingFurtherContributions',
        ],
        rows: [],
    };

    pensionBenefitsInOtherFundsTableData: TableData = {
        headerTitles: [],
        rows: [],
    };

    // Define pension acquisition types to use in the component template
    PensionAcquisitionTypes = PensionAcquisitionTypes;

    constructor(
        private contributionService: ContributionsService,
        private pensionService: PensionService,
        private userService: UserService,
        private translocoService: TranslocoService,
        private localeService: TranslocoLocaleService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.userService.currentUser
            .pipe(
                filter((x) => x != null),
                take(1)
            )
            .subscribe((user) => {
                this.userDetails = user;
                this.loading = true;
                const requests = {} as {
                    pensionBenefitsLSRA: Observable<ExposedPensionBenefitsLSRARes | null>;
                    contributionsLSRA: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
                    pensionPaymentsLSRA: Observable<
                        ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]
                    >;

                    pensionBenefitsLSRB: Observable<ExposedPensionBenefitsLSRBRes | null>;
                    contributionsLSRB: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
                    pensionPaymentsLSRB: Observable<
                        ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]
                    >;

                    pensionInOtherFunds: Observable<ExposedListPensionBenefitsInOtherFundsRes[]>;

                    estimatedFuturePensionBenefitsExamples: Observable<ExposedEstimatedFuturePensionBenefitsExamplesRes | null>;
                };

                if (this.userDetails.funds.memberOfLSRA) {
                    requests.pensionBenefitsLSRA = this.pensionService.getPensionBenefitsLSRA();
                    requests.contributionsLSRA = this.contributionService.contributionsLSRA();
                    requests.pensionPaymentsLSRA = this.pensionService.getPensionPayments(PensionFunds.LSRA);
                }

                if (this.userDetails.funds.memberOfLSRB) {
                    requests.pensionBenefitsLSRB = this.pensionService.getPensionBenefitsLSRB();
                    requests.contributionsLSRB = this.contributionService.contributionsLSRB();
                    requests.pensionPaymentsLSRB = this.pensionService.getPensionPayments(PensionFunds.LSRB);
                }

                const termsConfirmation = this.pensionService.pensionInOtherFundsTermsConfirmation;
                if (termsConfirmation) {
                    requests.pensionInOtherFunds = this.pensionService.getBenefitsInOtherFunds(
                        termsConfirmation.termsConfirmed
                    );
                }
                requests.estimatedFuturePensionBenefitsExamples =
                    this.pensionService.getEstimatedFuturePensionBenefitsExamples();

                forkJoin(requests)
                    .subscribe((res) => {
                        this.processPensionBenefitsLSRA(res.pensionBenefitsLSRA);
                        this.processContributionsLSRA(res.contributionsLSRA);
                        this.processPensionPaymentsLSRA(res.pensionPaymentsLSRA);

                        this.processPensionBenefitsLSRB(res.pensionBenefitsLSRB);
                        this.processContributionsLSRB(res.contributionsLSRB);
                        this.processPensionPaymentsLSRB(res.pensionPaymentsLSRB);

                        this.processBenefitsInOtherFundsResponse(
                            res.pensionInOtherFunds,
                            termsConfirmation?.termsConfirmed ?? false
                        );

                        this.processEstimatedFuturePensionBenefitsExamples(res.estimatedFuturePensionBenefitsExamples);

                        if (
                            res.contributionsLSRA &&
                            res.contributionsLSRA.length > 0 &&
                            res.pensionPaymentsLSRA &&
                            res.pensionPaymentsLSRA.length > 0
                        ) {
                            this.processLatestPensionPaymentsLSRA(res.pensionPaymentsLSRA, res.contributionsLSRA);
                        }

                        if (
                            res.contributionsLSRB &&
                            res.contributionsLSRB.length > 0 &&
                            res.pensionPaymentsLSRB &&
                            res.pensionPaymentsLSRB.length > 0
                        ) {
                            this.processLatestPensionPaymentsLSRB(res.pensionPaymentsLSRB, res.contributionsLSRB);
                        }
                    })
                    .add(() => {
                        this.loading = false;
                    });
            });

        // Reprocess latest pension payments on language changes to update card data text translations
        this.translocoService.langChanges$.subscribe(() => {
            // setTimeout is necessary here to do this in the next tick, otherwise the translations will not be displayed correctly on the first language change
            setTimeout(() => {
                if (this.pensionPaymentsLSRA && this.contributionsLSRA) {
                    this.processLatestPensionPaymentsLSRA(this.pensionPaymentsLSRA, this.contributionsLSRA);
                }

                if (this.pensionPaymentsLSRB && this.contributionsLSRB) {
                    this.processLatestPensionPaymentsLSRB(this.pensionPaymentsLSRB, this.contributionsLSRB);
                }
            });
        });
    }

    ngAfterViewInit() {
        // Open the pension in other funds dialog if the terms have not been confirmed or denied
        if (!this.pensionService.pensionInOtherFundsTermsConfirmation) {
            this.openPensionBenefitsInOtherFundsTermsConfirmationDialog();
            // The dialog view child isn't available until this lifecycle hook, but as it alters the state of the dialog this results in an error in the console.
            // To prevent this error change detection must be triggered to run again manually.
            this.changeDetectorRef.detectChanges();
        }
    }

    processPensionBenefitsLSRA = (res: ExposedPensionBenefitsLSRARes | null) => {
        if (!res) {
            return;
        }
        this.pensionBenefitsLSRA = res;
    };

    processPensionBenefitsLSRB = (res: ExposedPensionBenefitsLSRBRes | null) => {
        if (!res) {
            return;
        }
        this.pensionBenefitsLSRB = res;
        this.pensionBenefitsLSRBCardData = pensionBenefitsLSRBToCardData(
            res,
            this.userDetails!.age,
            this.localeService
        );
    };

    processContributionsLSRA = (res: ExposedKriaPaidContributionsYearGroupRes[]) => {
        if (!res || res.length == 0) {
            return;
        }
        this.contributionsLSRA = res;
        this.contributionsLSRATableData = {
            ...this.contributionsLSRATableData,
            rows: res.map((row) => {
                return {
                    rowTitles: [
                        row.year,
                        this.localeService.localizeNumber(row.contributionMemberTotal!, 'currency'),
                        this.localeService.localizeNumber(row.contributionEmployerTotal!, 'currency'),
                        this.localeService.localizeNumber(row.total!, 'currency'),
                    ],
                    innerTable: {
                        headerTitles: [
                            'mppTableHeaderDate',
                            'mppTableHeaderEmployer',
                            'mppTableHeaderFundPartnerContribution',
                            'mppTableHeaderEmployerContribution',
                            'mppTableHeaderTotal',
                        ],
                        rows: row.contributions.map((c) => {
                            return {
                                rowTitles: [
                                    formatDate(c.paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                                    c.employerName,
                                    this.localeService.localizeNumber(c.contributionMemberTotal!, 'currency'),
                                    this.localeService.localizeNumber(c.contributionEmployerTotal!, 'currency'),
                                    this.localeService.localizeNumber(c.total!, 'currency'),
                                ],
                            };
                        }),
                    },
                } as TableRowData;
            }),
        };
    };

    processContributionsLSRB = (res: ExposedKriaPaidContributionsYearGroupRes[]) => {
        if (!res || res.length == 0) {
            return;
        }
        this.contributionsLSRB = res;
        this.contributionsLSRBTableData = {
            ...this.contributionsLSRBTableData,
            rows: res.map((row) => {
                return {
                    rowTitles: [
                        row.year,
                        this.localeService.localizeNumber(row.contributionMemberTotal, 'currency'),
                        this.localeService.localizeNumber(row.contributionEmployerTotal, 'currency'),
                        this.localeService.localizeNumber(row.total, 'currency'),
                    ],
                    innerTable: {
                        headerTitles: [
                            'mppTableHeaderDate',
                            'mppTableHeaderEmployer',
                            'mppTableHeaderFundPartnerContribution',
                            'mppTableHeaderEmployerContribution',
                            'mppTableHeaderTotal',
                        ],
                        rows: row.contributions.map((c) => {
                            return {
                                rowTitles: [
                                    formatDate(c.paymentDate!, 'dd.MM.yyyy', 'en-EN'),
                                    c.employerName,
                                    this.localeService.localizeNumber(c.contributionMemberTotal!, 'currency'),
                                    this.localeService.localizeNumber(c.contributionEmployerTotal!, 'currency'),
                                    this.localeService.localizeNumber(c.total!, 'currency'),
                                ],
                            };
                        }),
                    },
                } as TableRowData;
            }),
        };
    };

    processPensionPaymentsLSRA = (res: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]) => {
        if (!res || res.length == 0) {
            return;
        }
        this.pensionPaymentsLSRA = res;
        this.paymentsLSRATableData = {
            ...this.paymentsLSRATableData,
            rows: res.map((row) => {
                return {
                    rowTitles: [
                        row.year,
                        this.localeService.localizeNumber(row.paymentAfterTaxTotal, 'currency'),
                        this.localeService.localizeNumber(row.paymentTaxTotal, 'currency'),
                        this.localeService.localizeNumber(row.paymentWithTaxTotal, 'currency'),
                    ],
                    innerTable: {
                        headerTitles: [
                            'mppTableHeaderDate',
                            'mppTableHeaderPaymentType',
                            'mppTableHeaderPayout',
                            'mppTableHeaderWithholdingTax',
                            'mppTableHeaderTotal',
                        ],
                        rows: row.payments.map((p) => {
                            return {
                                rowTitles: [
                                    formatDate(p.paymentDate, 'dd.MM.yyyy', 'en-EN'),
                                    p.pensionType,
                                    this.localeService.localizeNumber(p.paymentAfterTax, 'currency'),
                                    this.localeService.localizeNumber(p.paymentTax, 'currency'),
                                    this.localeService.localizeNumber(p.paymentWithTax, 'currency'),
                                ],
                            } as TableRowData;
                        }),
                    },
                } as TableRowData;
            }),
        };
    };

    processPensionPaymentsLSRB = (res: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]) => {
        if (!res || res.length == 0) {
            return;
        }
        this.pensionPaymentsLSRB = res;
        this.paymentsLSRBTableData = {
            ...this.paymentsLSRBTableData,
            rows: res.map((row) => {
                return {
                    rowTitles: [
                        row.year,
                        this.localeService.localizeNumber(row.paymentAfterTaxTotal, 'currency'),
                        this.localeService.localizeNumber(row.paymentTaxTotal, 'currency'),
                        this.localeService.localizeNumber(row.paymentWithTaxTotal, 'currency'),
                    ],
                    innerTable: {
                        headerTitles: [
                            'mppTableHeaderDate',
                            'mppTableHeaderPaymentType',
                            'mppTableHeaderPayout',
                            'mppTableHeaderWithholdingTax',
                            'mppTableHeaderTotal',
                        ],
                        rows: row.payments.map((p) => {
                            return {
                                rowTitles: [
                                    formatDate(p.paymentDate, 'dd.MM.yyyy', 'en-EN'),
                                    p.pensionType,
                                    this.localeService.localizeNumber(p.paymentAfterTax, 'currency'),
                                    this.localeService.localizeNumber(p.paymentTax, 'currency'),
                                    this.localeService.localizeNumber(p.paymentWithTax, 'currency'),
                                ],
                            } as TableRowData;
                        }),
                    },
                } as TableRowData;
            }),
        };
    };

    processLatestPensionPaymentsLSRA = (
        paymentsRes: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[],
        contributionsRes: ExposedKriaPaidContributionsYearGroupRes[]
    ) => {
        this.latestPensionPaymentsLSRACardData = this.processLatestPensionPaymentsCardData(
            paymentsRes,
            contributionsRes
        );
    };

    processLatestPensionPaymentsLSRB = (
        paymentsRes: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[],
        contributionsRes: ExposedKriaPaidContributionsYearGroupRes[]
    ) => {
        this.latestPensionPaymentsLSRBCardData = this.processLatestPensionPaymentsCardData(
            paymentsRes,
            contributionsRes
        );
    };

    processLatestPensionPaymentsCardData = (
        paymentsRes: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[],
        contributionsRes: ExposedKriaPaidContributionsYearGroupRes[]
    ): { amount: number; description: string } | undefined => {
        const latestPayment = new Date(paymentsRes[0].payments[0].paymentDate);
        const latestContribution = new Date(contributionsRes[0].contributions[0].paymentDate!);

        if (latestPayment > latestContribution!) {
            const allPaymentsAtLatestDate = paymentsRes[0].payments.filter(
                (p) => p.paymentDate == paymentsRes[0].payments[0].paymentDate
            );

            const mixedPaymentTypes = allPaymentsAtLatestDate.some(
                (p) => p.pensionTypeIdentifier != allPaymentsAtLatestDate[0].pensionTypeIdentifier
            );

            const month = MonthIdentifiers[latestPayment.getMonth()];
            const monthName = this.translocoService.translate(month.translationKey);
            // Not strictly necessary but in english month names are always capitalized but not in icelandic, so the name is only cast to lowercase if the language is not 'en'
            const monthYearString = `${this.translocoService.getActiveLang() == 'en' ? monthName : monthName.toLocaleLowerCase()} ${latestPayment.getFullYear()}`;
            let description = '';
            if (mixedPaymentTypes) {
                description = `${this.translocoService.translate('mppLatestPensionPaymentLSRASubtitleTypeTotal')} ${monthYearString}`;
            } else {
                switch (allPaymentsAtLatestDate[0].pensionTypeIdentifier) {
                    case PensionPaymentTypes.Pension:
                        description = `${this.translocoService.translate('mppLatestPensionPaymentLSRASubtitleTypePension')} ${monthYearString}`;
                        break;
                    case PensionPaymentTypes.Child:
                        description = `${this.translocoService.translate('mppLatestPensionPaymentLSRASubtitleTypeChild')} ${monthYearString}`;
                        break;
                    case PensionPaymentTypes.Spouse:
                        description = `${this.translocoService.translate('mppLatestPensionPaymentLSRASubtitleTypeSpouse')} ${monthYearString}`;
                        break;
                    case PensionPaymentTypes.Disability:
                        description = `${this.translocoService.translate('mppLatestPensionPaymentLSRASubtitleTypeDisability')} ${monthYearString}`;
                        break;
                    default:
                        description = `${this.translocoService.translate('mppLatestPensionPaymentLSRASubtitleTypeTotal')} ${monthYearString}`;
                }
            }

            return {
                amount: allPaymentsAtLatestDate.reduce((sum, p) => sum + p.paymentWithTax, 0),
                description,
            };
        }
        return;
    };

    openPensionBenefitsInOtherFundsTermsConfirmationDialog = () => {
        this.confirmDialog.open();
        this.confirmDialog.dialogClosed.pipe(first()).subscribe((confirmed) => {
            if (confirmed == this.pensionBenefitsInOtherFundsIncludeAmounts) {
                return;
            }
            this.pensionBenefitsInOtherFundsLoading = true;
            this.pensionService
                .getBenefitsInOtherFunds(confirmed)
                .subscribe((res) => {
                    this.pensionService.pensionInOtherFundsTermsConfirmation = {
                        ssn: this.userDetails!.ssn,
                        termsConfirmed: confirmed,
                    };
                    this.processBenefitsInOtherFundsResponse(res, confirmed);
                })
                .add(() => {
                    this.pensionBenefitsInOtherFundsLoading = false;
                });
        });
    };

    processBenefitsInOtherFundsResponse = (
        pensionBenefitsInOtherFunds: ExposedListPensionBenefitsInOtherFundsRes[],
        termsConfirmed: boolean
    ) => {
        if (!pensionBenefitsInOtherFunds) {
            return;
        }
        this.pensionBenefitsInOtherFunds = pensionBenefitsInOtherFunds;
        this.pensionBenefitsInOtherFundsIncludeAmounts = termsConfirmed;

        // Determine if amounts should be included for any age
        const includeAmountsAges = {
            65: false,
            67: false,
            70: false,
        };

        pensionBenefitsInOtherFunds
            .find((item) => item.monthlyPayments != null)
            ?.monthlyPayments.forEach((item) => {
                if (item.age == 65 || item.age == 67 || item.age == 70) {
                    includeAmountsAges[item.age] = true;
                }
            });

        const amountTotalsAges = {
            65: 0,
            67: 0,
            70: 0,
        };

        this.pensionBenefitsInOtherFundsTableData.headerTitles = [
            'mppTableHeaderFund',
            'mppTableHeaderLastPayment',
            ...(includeAmountsAges[65] ? ['mppTableHeaderMonthlyPaymentAt65'] : []),
            ...(includeAmountsAges[67] ? ['mppTableHeaderMonthlyPaymentAt67'] : []),
            ...(includeAmountsAges[70] ? ['mppTableHeaderMonthlyPaymentAt70'] : []),
        ];

        let showIncompleteDataCardMessage = false;
        this.pensionBenefitsInOtherFundsTableData.rows = pensionBenefitsInOtherFunds.map((item) => {
            const rowData = {
                rowTitles: [item.pensionFundName, `${item.lastContributionMonth}/${item.lastContributionYear}`],
            } as TableRowData;

            // If no data is being fetched from other funds no further mapping is necessary
            if (!termsConfirmed) {
                return rowData;
            }

            let showNoDataMessage = true;
            if (includeAmountsAges[65]) {
                const payment = item.monthlyPayments.find((m) => m.age == 65);
                if (payment) {
                    amountTotalsAges[65] += payment.amount;
                    rowData.rowTitles.push(this.localeService.localizeNumber(payment.amount, 'currency'));
                    showNoDataMessage = false;
                }
            }
            if (includeAmountsAges[67]) {
                const payment = item.monthlyPayments.find((m) => m.age == 67);
                if (payment) {
                    amountTotalsAges[67] += payment.amount;
                    rowData.rowTitles.push(this.localeService.localizeNumber(payment.amount, 'currency'));
                    showNoDataMessage = false;
                }
            }
            if (includeAmountsAges[70]) {
                const payment = item.monthlyPayments.find((m) => m.age == 70);
                if (payment) {
                    amountTotalsAges[70] += payment.amount;
                    rowData.rowTitles.push(this.localeService.localizeNumber(payment.amount, 'currency'));
                    showNoDataMessage = false;
                }
            }

            if (showNoDataMessage) {
                rowData.rowTitles.push({
                    text: 'mppAcquiredBeneftisOtherFundsNoDataMessage',
                    options: {
                        // Span same number of columns as ages that get included
                        colSpan: [includeAmountsAges[65], includeAmountsAges[67], includeAmountsAges[70]].filter(
                            (item) => item
                        ).length,
                        modifiers: 'cell__center',
                    },
                });
                showIncompleteDataCardMessage = true;
            }
            return rowData;
        });

        if (termsConfirmed) {
            // Add total row
            this.pensionBenefitsInOtherFundsTableData.rows.push({
                rowTitles: [
                    'mppTableHeaderTotal',
                    '',
                    ...(includeAmountsAges[65]
                        ? [this.localeService.localizeNumber(amountTotalsAges[65], 'currency')]
                        : []),
                    ...(includeAmountsAges[67]
                        ? [this.localeService.localizeNumber(amountTotalsAges[67], 'currency')]
                        : []),
                    ...(includeAmountsAges[70]
                        ? [this.localeService.localizeNumber(amountTotalsAges[70], 'currency')]
                        : []),
                ],
                modifiers: 'row__bold',
            } as TableRowData);

            const cardData = {} as { amount: number; age: number; showIncompleteDataMessage: boolean };
            if (includeAmountsAges[67]) {
                cardData.amount = amountTotalsAges[67];
                cardData.age = 67;
                cardData.showIncompleteDataMessage = showIncompleteDataCardMessage;
            } else if (includeAmountsAges[70]) {
                cardData.amount = amountTotalsAges[70];
                cardData.age = 70;
                cardData.showIncompleteDataMessage = showIncompleteDataCardMessage;
            }
            this.pensionBenefitsInOtherFundsCardData = cardData;
        }
    };

    scrollToSection = (sectionId: string) => {
        document.getElementById(sectionId)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    };

    processEstimatedFuturePensionBenefitsExamples = (res: ExposedEstimatedFuturePensionBenefitsExamplesRes | null) => {
        if (!res) {
            return;
        }
        this.estimatedFuturePensionBenefitsExamples = res;
        this.processEstimatedFuturePensionBenefitsExamplesList(
            res.examples.filter((item) =>
                this.estimatedFuturePensionBenefitsExamplesMinifiedAgesShown.includes(item.age)
            )
        );
    };

    toggleEstimatedFuturePensionBenefitsExamplesTableExpansion = () => {
        this.estimatedFuturePensionbenefitsExamplesTableExpanded =
            !this.estimatedFuturePensionbenefitsExamplesTableExpanded;

        if (this.estimatedFuturePensionbenefitsExamplesTableExpanded) {
            this.processEstimatedFuturePensionBenefitsExamplesList(
                this.estimatedFuturePensionBenefitsExamples!.examples
            );
        } else {
            this.processEstimatedFuturePensionBenefitsExamplesList(
                this.estimatedFuturePensionBenefitsExamples!.examples.filter((item) =>
                    this.estimatedFuturePensionBenefitsExamplesMinifiedAgesShown.includes(item.age)
                )
            );
        }
    };

    processEstimatedFuturePensionBenefitsExamplesList = (list: EstimatedFuturePensionBenefitsExample[]) => {
        this.estimatedFuturePensionBenefitsExamplesTableData.rows = list.map((item) => {
            return {
                rowTitles: [
                    {
                        text: 'mppTableCellAgeText',
                        options: {
                            translationParams: { age: item.age },
                        },
                    },
                    this.localeService.localizeNumber(item.amountEstimatedNoFurtherDeposits, 'currency'),
                    this.localeService.localizeNumber(item.amountEstimatedFurtherDeposits, 'currency'),
                ],
            } as TableRowData;
        });
    };

    downloadPensionOverviewSummaryLSRA = () => {
        const currentDate = new Date();
        const yearAgoDate = new Date();
        yearAgoDate.setFullYear(currentDate.getFullYear() - 1);

        const datePipe = new DatePipe('en-US');
        this.loadingPensionSummaryLSRA = true;
        this.pensionService
            .getPensionOverviewSummaryLSRA({
                ssn: this.userDetails!.ssn,
                paymentFromDate: datePipe.transform(yearAgoDate, 'YYYY-MM-dd')!,
                paymentToDate: datePipe.transform(currentDate, 'YYYY-MM-dd')!,
                showSalaryDate: true,
                letterMail: false,
            })
            .subscribe((res) => {
                const byteArray = base64ToArrayBuffer(res.base64File);
                saveByteArray(`Yfirlit_A-deild_${this.userDetails?.ssn}`, byteArray);
            })
            .add(() => {
                this.loadingPensionSummaryLSRA = false;
            });
    };

    downloadPensionOverviewSummaryLSRB = () => {
        const currentDate = new Date();
        const yearAgoDate = new Date();
        yearAgoDate.setFullYear(currentDate.getFullYear() - 1);

        const datePipe = new DatePipe('en-US');
        this.loadingPensionSummaryLSRB = true;
        this.pensionService
            .getPensionOverviewSummaryLSRB({
                ssn: this.userDetails!.ssn,
                paymentFromDate: datePipe.transform(yearAgoDate, 'YYYY-MM-dd')!,
                paymentToDate: datePipe.transform(currentDate, 'YYYY-MM-dd')!,
                showSalaryDate: true,
                letterMail: false,
            })
            .subscribe((res) => {
                const byteArray = base64ToArrayBuffer(res.base64File);
                saveByteArray(`Yfirlit_B-deild_${this.userDetails?.ssn}`, byteArray);
            })
            .add(() => {
                this.loadingPensionSummaryLSRB = false;
            });
    };
}
