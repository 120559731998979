export const formatPhone = (value: string): string => {
    if (!value) {
        return '';
    }
    value = value.replace(/ /g, '');
    if (value.length > 3) {
        value = value.substring(0, 3) + '-' + value.substring(3);
    }
    return value;
};
