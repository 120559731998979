import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, map, Observable, of, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExposedAccountsLoginReq } from '../../interfaces/requests/exposedAccountsLoginReq';
import { ExposedAccountsLoginRes } from '../../interfaces/responses/exposed/exposedAccountsLoginRes';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';
import { CacheService } from '../cache/cache.service';
import { UserService } from '../user/user.service';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public authenticated = false;
    public refreshingToken = false;
    private impersonating = false;

    private _accessTokenExpiryDate: Date | null = null;
    private accessTokenExpiryDateSubject: BehaviorSubject<Date | null> = new BehaviorSubject<Date | null>(null);
    public accessTokenExpiryDate: Observable<Date | null> = this.accessTokenExpiryDateSubject.asObservable();

    get accessToken(): string | null {
        return localStorage.getItem('lsr-access-token');
    }

    set accessToken(token: string | null) {
        if (token == null) {
            localStorage.removeItem('lsr-access-token');
            return;
        }
        localStorage.setItem('lsr-access-token', token);
    }

    get refreshToken(): string | null {
        return localStorage.getItem('lsr-refresh-token');
    }

    set refreshToken(token: string | null) {
        if (token == null) {
            localStorage.removeItem('lsr-refresh-token');
            return;
        }
        localStorage.setItem('lsr-refresh-token', token);
    }

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private cacheService: CacheService
    ) {}

    public login(req: ExposedAccountsLoginReq): Observable<ExposedAccountsLoginRes | null> {
        return this.http.post<HttpStatus<ExposedAccountsLoginRes>>(environment.localApi + 'auth/login', req).pipe(
            tap((res: HttpStatus<ExposedAccountsLoginRes>): void => {
                this.processAccessResponse(res.returnItem);
            }),
            map((res: HttpStatus<ExposedAccountsLoginRes>) => {
                return res.returnItem || null;
            }),
            catchError((error) => {
                return throwError(() => error);
            })
        );
    }

    // Send a logout request to the API and remove tokens and other authentication related data from localstorage
    public logout(): void {
        this.http.post<HttpStatus<string>>(environment.localApi + 'auth/logout', {}).subscribe();

        this.authenticated = false;
        this.accessToken = null;
        this.refreshToken = null;
        this.setAccessTokenExpiryDate(null);
        this.cacheService.clearCache();

        this.userService.setUserDetails(null);
    }

    public getVerificationCode(hashString: string): Observable<string | null> {
        return this.http
            .get<HttpStatus<string>>(environment.localApi + 'auth/login/verificationCode/' + hashString)
            .pipe(
                map((response: HttpStatus<string>) => response.returnItem || null),
                catchError((error) => {
                    return of(error);
                })
            );
    }

    public refreshAccessToken(): Observable<boolean> {
        if (!this.refreshToken) {
            return of(false);
        }

        // If a token refresh is already in progress, don't try to also refresh the token to prevent multiple simultaneous refresh requests to the API
        if (this.refreshingToken) {
            return of(false);
        }

        this.refreshingToken = true;
        return this.http
            .get<HttpStatus<ExposedAccountsLoginRes>>(environment.localApi + `auth/refresh/${this.refreshToken}`)
            .pipe(
                map((res: HttpStatus<ExposedAccountsLoginRes>) => {
                    return this.processAccessResponse(res.returnItem);
                }),
                catchError(() => {
                    return of(false);
                }),
                finalize(() => {
                    this.refreshingToken = false;
                })
            );
    }

    public isImpersonating = (): boolean => {
        return this.impersonating;
    };

    // Check to see if the user is currently authenticated
    public checkAuthenticated = (): Observable<boolean> => {
        // If the in-memory access token property is set and the expiration date hasn't passed, the user is considered authenticated
        if (this.accessToken && this._accessTokenExpiryDate && this._accessTokenExpiryDate > new Date()) {
            return of(true);
        }

        // Otherwise try to obtain a new access token using the refresh token from local storage
        return this.refreshAccessToken();
    };

    private processAccessResponse = (item: ExposedAccountsLoginRes) => {
        if (
            item != null &&
            item.accessToken &&
            item.refreshToken &&
            item.accessTokenExpireDate != null &&
            item.refreshTokenExpireDate != null
        ) {
            this.authenticated = true;
            this.impersonating = item.kennitala !== item.primaryKennitala;
            this.accessToken = item.accessToken;
            this.setAccessTokenExpiryDate(new Date(item.accessTokenExpireDate));
            this.refreshToken = item.refreshToken;
            this.userService.userDetails();

            return true;
        }
        return false;
    };

    private setAccessTokenExpiryDate = (date: Date | null) => {
        this._accessTokenExpiryDate = date;
        this.accessTokenExpiryDateSubject.next(date);
    };

    public generateExternalServiceJWT(system: string): Observable<string | null> {
        return this.http
            .get<HttpStatus<string>>(environment.localApi + 'Auth/GenerateExternalServiceJWT/' + system)
            .pipe(
                map((response: HttpStatus<string>) => {
                    if (response.returnItem) {
                        localStorage.setItem('lsr-external-service-access-token', response.returnItem);
                    }
                    return response.returnItem || null;
                }),
                catchError((error) => {
                    console.log(error);
                    return of(null);
                })
            );
    }

    public authenticateLibraLoan(token: string) {
        return new Promise<void>((resolve) => {
            // Using jQuery ajax here is necessary because the Angular http client always sends an Options preflight request which fails on a CORS error
            // ajax skips this preflight request.
            $.ajax({
                type: 'POST',
                crossDomain: true,
                xhrFields: {
                    withCredentials: true,
                },
                url: `${environment.libraPortalUrl}/Notandi/JwtToken/`,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                beforeSend: function (xhr: any) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                success: () => {
                    resolve();
                },
            });
        });
    }

    public checkHasRole = (roleName: string): Observable<boolean> => {
        return this.http
            .get<HttpStatus<boolean>>(environment.localApi + 'Auth/roles/check', { params: { roleName } })
            .pipe(
                map((response: HttpStatus<boolean>) => {
                    return response.returnItem;
                }),
                catchError(() => {
                    return of(false);
                })
            );
    };
}
