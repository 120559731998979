<div
    class="pretty-check {{ identifier }} {{ modifier }}"
    (click)="toggleCheckbox()"
    (keyup.space)="toggleCheckbox()"
    (keyup.enter)="toggleCheckbox()"
    tabindex="0"
>
    <input
        type="checkbox"
        [id]="id"
        [name]="type === 'radio' ? name : id"
        [disabled]="isDisabled"
        [checked]="isChecked"
        readonly
    />
    @if (label.length > 0) {
        <label class="label {{ isLabelHidden ? 'label--hidden' : '' }}" for="{{ id }}">
            {{ label }}
            @if (isRequired) {
                <span class="label__asterisk">*</span>
            }
        </label>
    }
</div>
