import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'safe',
    standalone: true,
})
export class SafePipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {}
    transform(url: string | undefined) {
        if (url) {
            return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
        }
        return null;
    }
}
