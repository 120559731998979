<div class="table">
    <h2 class="table__headline headline4" tabindex="0">{{ title }}</h2>
    @if (data) {
        <div class="table__table-container">
            <div class="table__table">
                <table>
                    <thead>
                        <tr>
                            @if (data.headerTitles && data.headerTitles.length > 0) {
                                <!-- First column has different styling -->
                                <th style="width: 100%">{{ data.headerTitles[0] | transloco }}</th>
                                @for (hTitle of data.headerTitles.slice(1); track hTitle) {
                                    <th>
                                        {{ hTitle | transloco }}
                                    </th>
                                }
                            }
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of dataDisplayed?.rows; track row; let index = $index) {
                            @if (!row?.isLoading) {
                                <tr (click)="rowClickEvent(row)">
                                    <td [ngClass]="{ rowHover: rowHoverText }" [attr.title]="rowHoverText">
                                        <p class="table__status">
                                            <span class="table__status-dot"></span>{{ row.rowTitles[0] }}
                                        </p>
                                    </td>
                                    @for (rowCell of row.rowTitles.slice(1); track rowCell; let index = $index) {
                                        <td
                                            [ngClass]="{ rowHover: rowHoverText }"
                                            [attr.title]="rowHoverText"
                                            [attr.colspan]="!isString(rowCell) ? rowCell.options?.colSpan : 1"
                                        >
                                            {{
                                                isString(rowCell)
                                                    ? (rowCell | transloco)
                                                    : (rowCell.text | transloco: rowCell?.options?.translationParams)
                                            }}
                                        </td>
                                    }
                                </tr>
                            } @else {
                                <tr>
                                    <td [attr.colspan]="row.rowTitles.length.toString()">
                                        <lsr-loader></lsr-loader>
                                    </td>
                                </tr>
                            }
                        }
                    </tbody>
                    @if (linkHref) {
                        <tfoot>
                            <tr>
                                <td [attr.colspan]="data.headerTitles?.length ?? 0">
                                    <div class="table__button-container">
                                        <a
                                            [routerLink]="linkHref"
                                            class="button button--secondary"
                                            title="{{ linkTitle }}"
                                            >{{ linkTitle }}</a
                                        >
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    } @else if (totalPages > 1) {
                        <tfoot>
                            <tr>
                                <td colspan="5">
                                    <lsr-pagination
                                        [totalPages]="totalPages"
                                        [currentPage]="currentPage"
                                        (pageChange)="setPage($event)"
                                    >
                                    </lsr-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    }
                </table>
            </div>
        </div>
    }
</div>
