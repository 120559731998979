<div class="amount-card">
    @if (headerClickable) {
        <a class="amount-card__title body1" tabindex="0" (click)="onHeaderClick()" (keyup.enter)="onHeaderClick()">
            {{ title }}
        </a>
    } @else {
        <p class="amount-card__title body1" tabindex="0">
            {{ title }}
        </p>
    }

    @if (isLoading) {
        <lsr-loader loadingText="Sæki gögn"></lsr-loader>
    } @else {
        <p class="amount-card__amount headline3" tabindex="0">{{ amountString }}</p>
        <p class="amount-card__subtitle body3" tabindex="0">{{ subtitle }}</p>

        <!-- Button option -->
        @if (type === 'button') {
            <lsr-button
                buttonType="click"
                identifier="amount-card__button"
                modifier="button--secondary button--small button--pdf"
                [text]="buttonTitle"
                [icon]="buttonIcon"
                (click)="onButtonClick()"
                [isLoading]="buttonLoading"
            ></lsr-button>
        }

        <!-- Regular link option -->
        @if (type === 'link' && linkHref) {
            <a class="amount-card__link body2" [routerLink]="linkHref">{{ linkTitle }}</a>
        }
        <ng-content></ng-content>
    }
</div>
