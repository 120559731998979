import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';

@Component({
    selector: 'lsr-pagination',
    standalone: true,
    imports: [LSRButtonComponent],
    templateUrl: './lsr-pagination.component.html',
    styleUrl: './lsr-pagination.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class LSRPaginationComponent {
    private _totalPages?: number;

    @Input()
    set totalPages(value: number) {
        this._totalPages = value;
        this.populatePaginator();
    }
    get totalPages(): number {
        return this._totalPages ?? 1;
    }
    @Input() currentPage: number = 1;
    @Output() pageChange = new EventEmitter<number>();
    pages: number[] = [];

    populatePaginator = () => {
        for (let i = 1; i < this.totalPages + 1; i++) {
            this.pages.push(i);
        }
    };

    goToPage(page: number) {
        this.pageChange.emit(page);
    }
}
