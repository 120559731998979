import { NgClass } from '@angular/common';
import { AfterContentInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SafePipe } from '@lsr/pipes/safe.pipe';
import { LSRLoaderComponent } from '../lsr-loader';

@Component({
    selector: 'lsr-iframe-wrapper',
    standalone: true,
    templateUrl: `lsr-iframe-wrapper.component.html`,
    styleUrl: `lsr-iframe-wrapper.component.scss`,
    encapsulation: ViewEncapsulation.None,
    imports: [NgClass, SafePipe, LSRLoaderComponent],
    host: { class: 'lsr-iframe-wrapper' },
})
export class LSRIframeWrapperComponent implements OnInit, AfterContentInit {
    @Input() redirectUrl: string = '';
    @Input() url: string | undefined = '';
    @Input() id: string = '';
    @Input() iframeStyles = '';
    @Input() showLoader: boolean = false;
    @Input() loadingText: string = 'Hleð síðu';
    @Output() postbackMessageEvent = new EventEmitter<MessageEvent>();

    iframeReady = false;

    ngOnInit(): void {
        window.addEventListener('message', (event) => {
            this.postbackMessageEvent.emit(event);
        });
    }

    ngAfterContentInit(): void {
        if (this.redirectUrl) {
            this.handleRedirect();
        } else {
            // If there is no redirect required we make sure to display the iframe ASAP.
            this.iframeReady = true;
        }
    }

    // Handle redirecting to the specified redirect url
    handleRedirect() {
        const iframe = document.querySelector('iframe');

        if (iframe) {
            iframe.onload = () => {
                if (iframe.src == this.url) {
                    iframe.src = this.redirectUrl;
                } else if (iframe.src == this.redirectUrl) {
                    this.iframeReady = true;
                    // Remove the onload handler after the redirect is complete
                    iframe.onload = null;
                }
            };
        }
    }

    postMessage(message: object) {
        const iframe = document.querySelector('iframe');
        if (iframe) {
            if (this.iframeReady && this.url) {
                iframe.contentWindow?.postMessage(message, this.url);
            } else {
                iframe.onload = () => {
                    if (this.url) {
                        iframe.contentWindow?.postMessage(message, this.url);
                    }
                };
            }
        }
    }
}
