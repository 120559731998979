<nav class="{{ identifier }} {{ modifier }}" role="navigation">
    <ul class="{{ identifier }}__list {{ identifier }}__list--top-level">
        @for (route of routes; track route.path) {
            <ng-container>
                @if (
                    route &&
                    route.path !== '' &&
                    route.path !== '**' &&
                    route.path !== 'stillingar' &&
                    route.path !== 'lanaumsoknir' &&
                    route.path !== 'login' &&
                    route.data?.['showInMenu']
                ) {
                    <lsr-navigation-item
                        [route]="route"
                        [identifier]="identifier"
                        [displayChildren]="displayChildren"
                    ></lsr-navigation-item>
                }
            </ng-container>
        }
        @if (displayPriority) {
            <ng-container>
                <li
                    class="priority-navigation__dropdown priority-navigation__dropdown--hidden {{
                        isPriorityOpen ? 'priority-navigation__dropdown--open' : ''
                    }}"
                >
                    <button class="priority-navigation__dropdown-toggle" (click)="togglePriorityDropdown()">
                        Opna leiðarkerfi
                    </button>
                    <ul class="priority-navigation__dropdown-list">
                        @for (route of routes; track route.path) {
                            <ng-container>
                                @if (
                                    route &&
                                    route.path !== '' &&
                                    route.path !== '**' &&
                                    route.path !== 'stillingar' &&
                                    route.path !== 'lanaumsoknir' &&
                                    route.path !== 'login' &&
                                    route.data?.['showInMenu']
                                ) {
                                    <lsr-navigation-item
                                        [route]="route"
                                        [identifier]="identifier"
                                        [displayChildren]="displayChildren"
                                    ></lsr-navigation-item>
                                }
                            </ng-container>
                        }
                    </ul>
                </li>
            </ng-container>
        }
    </ul>
</nav>
