<!-- Dialog wrapper around any content -->

<!-- Todo Mike: animation for open / close dialog -->
<div class="dialog {{ modifier }} {{ isOpen ? '' : 'dialog--closed' }}">
    <div class="dialog__wrapper" (keydown)="onKeyDown($event)" tabindex="0">
        <div class="dialog__box">
            <div class="dialog__header">
                @if (headerIcon) {
                    <lsr-icon icon="{{ headerIcon }}" color="#0f8b8d"></lsr-icon>
                }
                @if (headerTitle) {
                    <h1 class="headline4 dialog__header-title" tabindex="0">{{ headerTitle }}</h1>
                }
                <lsr-button modifier="dialog__close-button" icon="close" (click)="close()"></lsr-button>
            </div>
            <div class="dialog__body body2 richtext richtext--flex" tabindex="0">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div
        class="dialog__overlay"
        (click)="close()"
        (keydown.enter)="close()"
        (keydown.space)="close()"
        role="button"
        tabindex="0"
    ></div>
</div>
