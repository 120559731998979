export const TAX_DETAILS_VALUE = {
    BRACKET_1: 'SK1',
    BRACKET_2: 'SK2',
    BRACKET_3: 'SK3',
    OTHER: 'other',
    PERSONAL_TAX_CREDIT: 'personalTaxCredit',
    NO_PERSONAL_TAX_CREDIT: 'noPersonalTaxCredit',
    SPOUSE_PERSONAL_TAX_CREDIT: 'spousePersonalTaxCredit',
    NO_SPOUSE_PERSONAL_TAX_CREDIT: 'noSpousePersonalTaxCredit',
};
