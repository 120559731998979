import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.checkAuthenticated().pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    this.router.navigate(['login'], { queryParams: { redirectUrl: state.url } });
                    return of(false);
                }
                return of(true);
            })
        );
    }
}
