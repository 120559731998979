@if (totalPages > 1) {
    <nav class="pagination">
        <!-- arrow left -->
        <lsr-button
            modifier="pagination__button button--secondary button--small button--square button--arrow button--arrow-left"
            icon="arrow-left"
            [isDisabled]="currentPage <= 1"
            (click)="goToPage(currentPage - 1)"
        >
        </lsr-button>

        <!--
      CASE 1:
      Less than 5 pages
    -->
        @if (totalPages <= 5) {
            @for (page of pages; track page) {
                <lsr-button
                    modifier="pagination__button button--secondary button--small button--square button--square-text {{
                        page === currentPage ? 'pagination__button--active' : ''
                    }}"
                    text="{{ page }}"
                    (click)="goToPage(page)"
                >
                </lsr-button>
            }
        }

        <!--
      CASE 2:
      More than 5 pages & current page is in the beginning
    -->
        @if (totalPages > 5 && currentPage <= 3) {
            <!-- first 4 pages -->
            @for (page of pages.slice(0, 4); track page) {
                <lsr-button
                    modifier="pagination__button button--secondary button--small button--square button--square-text {{
                        page === currentPage ? 'pagination__button--active' : ''
                    }}"
                    text="{{ page }}"
                    (click)="goToPage(page)"
                >
                </lsr-button>
            }

            <!-- divider -->
            <lsr-button
                modifier="pagination__button pagination__button--dots button--secondary button--small button--square button--square-text"
                text="..."
            >
            </lsr-button>

            <!-- last page -->
            <lsr-button
                modifier="pagination__button button--secondary button--small button--square button--square-text"
                text="{{ totalPages }}"
                (click)="goToPage(totalPages)"
            >
            </lsr-button>
        }

        <!--
      CASE 3:
      More than 5 pages and current page is in the middle
    -->
        @if (totalPages > 5 && currentPage > 3 && currentPage < totalPages - 2) {
            <!-- first page -->
            <lsr-button
                modifier="pagination__button button--secondary button--small button--square button--square-text"
                text="{{ 1 }}"
                (click)="goToPage(1)"
            >
            </lsr-button>
            <!-- divider -->
            <lsr-button
                modifier="pagination__button pagination__button--dots button--secondary button--small button--square button--square-text"
                text="..."
            >
            </lsr-button>
            <!-- middle pages -->
            @for (page of pages.slice(currentPage - 1, currentPage + 2); track page) {
                <lsr-button
                    modifier="pagination__button button--secondary button--small button--square button--square-text {{
                        page === currentPage ? 'pagination__button--active' : ''
                    }}"
                    text="{{ page }}"
                    (click)="goToPage(page)"
                >
                </lsr-button>
            }

            <!-- divider -->
            <lsr-button
                modifier="pagination__button pagination__button--dots button--secondary button--small button--square button--square-text"
                text="..."
            >
            </lsr-button>

            <!-- last page -->
            <lsr-button
                modifier="pagination__button button--secondary button--small button--square button--square-text"
                text="{{ totalPages }}"
                (click)="goToPage(totalPages)"
            >
            </lsr-button>
        }

        <!--
        CASE 4:
        More than 5 pages & current page is in the end
      -->
        @if (totalPages > 5 && currentPage >= totalPages - 2) {
            <!-- first page -->
            <lsr-button
                modifier="pagination__button button--secondary button--small button--square button--square-text"
                text="{{ 1 }}"
                (click)="goToPage(1)"
            >
            </lsr-button>

            <!-- divider -->
            <lsr-button
                modifier="pagination__button pagination__button--dots button--secondary button--small button--square button--square-text"
                text="..."
            >
            </lsr-button>

            <!-- last 4 pages -->
            @for (page of pages.slice(totalPages - 3, totalPages); track page) {
                <lsr-button
                    modifier="pagination__button button--secondary button--small button--square button--square-text {{
                        page === currentPage ? 'pagination__button--active' : ''
                    }}"
                    text="{{ page }}"
                    (click)="goToPage(page)"
                >
                </lsr-button>
            }
        }

        <!-- arrow right -->
        <lsr-button
            modifier="pagination__button button--secondary button--small button--square button--arrow button--arrow-right"
            icon="arrow-right"
            [isDisabled]="currentPage >= totalPages"
            (click)="goToPage(currentPage + 1)"
        >
        </lsr-button>
    </nav>
}
