import { AbstractControl, ValidationErrors } from '@angular/forms';

export function ValidateEmail(c: AbstractControl): ValidationErrors | null {
    const REGEXP = new RegExp(
        /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return REGEXP.test(c.value)
        ? null
        : ({
              validEmail: true,
          } as ValidationErrors);
}
