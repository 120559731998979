import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { formatPhone } from '../../../utils/formatPhone';
import { formatSSN } from '../../../utils/formatSSN';
import { LSRIconComponent } from '../../lsr-icon/lsr-icon.component';

@Component({
    selector: 'lsr-input',
    standalone: true,
    imports: [FormsModule, LSRIconComponent,],
    templateUrl: `lsr-input-text.component.html`,
    styleUrl: `../lsr-input-base.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-input' },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LSRInputTextComponent,
            multi: true,
        },
    ],
})
export class LSRInputTextComponent implements ControlValueAccessor {
    @Input() identifier: string = '';
    @Input() modifier: string = '';
    @Input() label: string = '';
    @Input() isRequired?: boolean;
    @Input() isLabelHidden?: boolean;
    @Input() icon?: string;
    @Input() iconColor?: string;
    @Input() suffix?: string;
    @Input() isDisabled: boolean = false;
    @Input() id: string = '';
    @Input() name: string = '';
    @Input() type: string = '';
    @Input() placeholder: string = '';
    @Input() errorMessage: string = '';
    @Input() isChecked?: boolean;
    @Input() maxLength?: string | null | undefined = undefined;
    @Input() formControlName: string = '';
    @Input() format: 'phone' | 'ssn' | 'number' | null = null;

    // Output event for value change
    @Output() valueChange = new EventEmitter<string>();
    @Output() blur = new EventEmitter<void>();

    public displayValue: string = '';
    private numberValue: number | null = null;
    private _value: string = '';

    constructor(
        private localeService: TranslocoLocaleService,
    ) {}

    set value(value: string) {
        this._value = value;
        if (this.onChange) {
            this.displayValue = this.formatValue(value);
            if (this.format == 'number') {
                this.onChange(this.parseInputToNumber(value));
            }
            else {
                this.onChange(value);
            }
        }
    }

    get value() {
        return this._value;
    }

    onChange: ((value: any) => void) | null = null;
    onTouched: (() => void) | null = null;

    onInputChange = (event: Event) => {
        const displayValue = (event.target! as HTMLInputElement).value;

        if (this.format) {
            this.value = this.unformatValue(displayValue);
        } else {
            this.value = displayValue;
            if (this.format == 'number') {
                this.numberValue = this.parseInputToNumber(displayValue);
            }
        }
    };

    onInputBlur = () => {
        if (this.format == 'number') {
            this.formatDisplayValue();
        }
        this.blur.emit();
    };

    onInputFocus = () => {
        // Reset to unformatted value on focus
        if (this.numberValue !== null) {
            this.displayValue = this.numberValue.toString();
        }
    }

    // Functions implementing control value accessor
    writeValue = (value: any): void => {
        this.displayValue = this.formatValue(value);
        if (this.format == 'number' && value != undefined) {
            this.numberValue = value;
            // Initial display value formatting
            this.formatDisplayValue();
        }
    };

    registerOnChange = (fn: (value: string) => void): void => {
        this.onChange = fn;
    };

    registerOnTouched = (fn: () => void): void => {
        this.onTouched = fn;
    };

    setDisabledState? = (isDisabled: boolean): void => {
        this.isDisabled = isDisabled;
    };

    unformatValue = (value: string): string => {
        if (!this.format) {
            return value;
        }

        switch (this.format) {
            case 'phone':
            case 'ssn':
                return value.replace(/\D/g, '');
            default:
                return value;
        }
    };

    formatValue = (value: string): string => {
        if (!this.format) {
            return value;
        }

        switch (this.format) {
            case 'phone':
                return formatPhone(value);
            case 'ssn':
                return formatSSN(value);
            default:
                return value;
        }
    };

    // Parse input value into a number
    parseInputToNumber(numberString: string): number | null {
        const parts = new Intl.NumberFormat(this.localeService.getLocale()).formatToParts(12345.6);
        const groupSeparator = parts.find(x => x.type == 'group')!.value;
        numberString = numberString.replace(new RegExp(groupSeparator.replace(/[.]/g, '\\$&'), 'g'), '');
        this.numberValue = parseFloat(numberString);
        return isNaN(this.numberValue) ? 0 : this.numberValue;
    }

    // Format display value based on locale on blur
    formatDisplayValue() {
        if (!isNaN(this.numberValue!)) {
            this.displayValue = this.localeService.localizeNumber(this.numberValue!, 'decimal');
        }
        else {
            this.numberValue = null;
        }
    }
}
