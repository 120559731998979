<lsr-title text="{{ 'mpdPageTitle' | transloco }}"></lsr-title>
<form [formGroup]="form">
    <section class="search-bar">
        <lsr-input
            formControlName="searchString"
            class="search-bar__search"
            label="{{ 'mpdPlaceholderSearch' | transloco }}"
            [isLabelHidden]="true"
            placeholder="{{ 'mpdPlaceholderSearch' | transloco }}"
            icon="search"
            id="documents-search"
        >
        </lsr-input>
        <lsr-select
            class="search-bar__select"
            label="{{ 'mpdPlaceholderType' | transloco }}"
            [isLabelHidden]="true"
            placeholder="{{ 'mpdPlaceholderType' | transloco }}"
            formControlName="documentTypeId"
            id="documents-select"
            [options]="documentTypeOptions"
            [clearable]="true"
        >
        </lsr-select>
        <lsr-datepicker
            class="search-bar__datepicker"
            id="documents-date-from"
            placeholder="{{ 'mpdPlaceholderFromDate' | transloco }}"
            formControlName="dateFrom"
            [maxDate]="form.get('dateTo')?.value"
        ></lsr-datepicker>
        <lsr-datepicker
            class="search-bar__datepicker"
            placeholder="{{ 'mpdPlaceholderToDate' | transloco }}"
            id="documents-date-to"
            formControlName="dateTo"
            [minDate]="form.get('dateFrom')?.value"
        ></lsr-datepicker>
        <lsr-button
            class="search-bar__button"
            text="{{ 'mpdTableButtonSearch' | transloco }}"
            modifier="button--default"
            buttonType="click"
            (clickEvent)="fetchDocumentsClick()"
            [isLoading]="isLoading"
        >
        </lsr-button>
    </section>
</form>

@if (!isLoading) {
    <lsr-table-preview
        [data]="data"
        [pageSize]="5"
        rowHoverText="{{ 'mpdHoverDownload' | transloco }}"
        (rowClick)="fetchDocument($event)"
    >
    </lsr-table-preview>
} @else {
    <lsr-loader></lsr-loader>
}
