import { Component, Input } from '@angular/core';
import { LSRAmountCardComponent } from '@lsr/ui-components/lsr-amount-card';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRNotificationComponent } from '@lsr/ui-components/lsr-notification';
import { LSRTablePreviewComponent } from '@lsr/ui-components/lsr-table/lsr-table-preview';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';

@Component({
    selector: 'app-work-in-progress',
    standalone: true,
    imports: [
        LSRAmountCardComponent,
        LSRNotificationComponent,
        LSRTablePreviewComponent,
        LSRTitleComponent,
        LSRIconComponent,
    ],
    templateUrl: 'work-in-progress.component.html',
    styleUrl: './work-in-progress.component.scss',
    host: { class: 'main__content' },
})
export class WorkInProgressComponent {
    @Input() title: string | null = null;
    @Input() description: string | null = null;
    @Input() showIcon: boolean = false;
}
