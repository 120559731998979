import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslocoService } from '@jsverse/transloco';

@Component({
    selector: 'lsr-calculator',
    standalone: true,
    templateUrl: `lsr-calculator.component.html`,
    styleUrl: `lsr-calculator.component.scss`,
})
export class LSRCalculatorComponent implements OnInit, AfterViewInit {
    @Input() embed: string = '';
    @ViewChild('iframeContainer') iframeContainer!: ElementRef;
    safeUrl: SafeResourceUrl;

    constructor(
        private sanitizer: DomSanitizer,
        private translocoService: TranslocoService
    ) {
        this.safeUrl = this.sanitizeUrl(this.embed);
    }

    sanitizeUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    ngOnInit() {
        this.safeUrl = this.sanitizeUrl(this.embed);
        this.translocoService.langChanges$.subscribe((value) => {
            const iframe = document.querySelector('iframe');
            if (iframe?.contentWindow) {
                iframe.contentWindow.postMessage({ language: value }, this.embed);
            }
        });
    }

    ngAfterViewInit() {
        const allowedDomains = [/^https?:\/\/localhost:30[0-9]{2}$/, /^https?:\/\/(?:.*\.)?lsr\.is$/];
        const iframeContainer = this.iframeContainer.nativeElement;

        window.addEventListener('message', (event) => {
            const originAllowed = allowedDomains.some((pattern) =>
                typeof pattern === 'string' ? pattern === event.origin : pattern.test(event.origin)
            );

            if (originAllowed) {
                if (event.data.source === 'react-devtools-content-script') {
                    return;
                }

                if (event.data.iframeHeight) {
                    setHeight(event.data);
                }

                if (event.data.scrollIntoView) {
                    scrollIntoView(event.data);
                }
            }
        });

        const scrollIntoView = (data: { scrollAmount: number; scrollIntoView: boolean }) => {
            const scrollModifier = window.innerWidth < 1024 ? 30 : 40;
            const scrollTo =
                window.scrollY + iframeContainer.getBoundingClientRect().top + data.scrollAmount - scrollModifier;

            window.scrollTo({
                top: scrollTo,
                behavior: 'smooth',
            });
        };

        const setHeight = (data: { iframeHeight: number }) => {
            iframeContainer.style.height = `${data.iframeHeight}px`;
        };
    }
}
