import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'lsr-loader',
    standalone: true,
    templateUrl: `lsr-loader.component.html`,
    styleUrl: `lsr-loader.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-loader' },
})
export class LSRLoaderComponent implements OnInit {
    @Input() modifier?: string;
    @Input() loadingText?: string;
    @Input() size?: string; // small or large - default is medium size
    sizeClass: string = "loader--medium";
    textClass: string = "body2"

    ngOnInit() {
      if (this.size === "small") {
        this.sizeClass = "loader--small";
        this.textClass = "body4";
      }
      else if (this.size === "large") {
        this.sizeClass = "loader--large";
        this.textClass = "headline4";
      }
    }
}
