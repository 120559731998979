import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LSRIconComponent } from '../../lsr-icon/lsr-icon.component';

@Component({
    selector: 'lsr-radio',
    standalone: true,
    imports: [FormsModule, LSRIconComponent],
    templateUrl: `lsr-input-radio.component.html`,
    styleUrl: `../lsr-input-base.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-input-radio' },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LSRInputRadioComponent,
            multi: true,
        },
    ],
})
export class LSRInputRadioComponent implements ControlValueAccessor {
    @Input() value: string = '';
    @Input() name: string = '';
    @Input() isRequired?: boolean;
    @Input() isChecked?: boolean = false;
    @Input() identifier: string = '';
    @Input() modifier: string = '';
    @Input() label: string = '';
    @Input() isLabelHidden?: boolean;
    @Input() isDisabled: boolean = false;
    @Input() id: string = '';
    @Input() errorMessage: string = '';
    @Input() formControlName: string = '';

    // The internal value of the radio button (selected value)
    public selectedValue?: string;

    onChange: ((value: string) => void) | null = null;
    onTouched: (() => void) | null = null;

    // Called when the value of the radio button changes
    writeValue(value: string): void {
        this.selectedValue = value;
    }

    // Registers the onChange function (called by Angular when the value changes)
    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    // Registers the onTouched function (called by Angular when the control is touched)
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Method to update the value on user interaction
    selectValue(): void {
        this.selectedValue = this.value;
        if (this.onChange) {
            this.onChange(this.value);
        }
        if (this.onTouched) {
            this.onTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
