/**
 * Kemur date yfir á læsilegt, íslenskt form.
 * @param dateA Date á strengjarformi
 */
export function formatDate(dateA: string, showHour: boolean = true): string {
    const date = new Date(dateA);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    let retval = day + '.' + month + '.' + year;

    if (showHour) {
        const hourString = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
        retval = retval + ' kl. ' + hourString;
    }
    return retval;
}
