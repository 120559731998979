import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { PensionFunds } from '../../helpers/constants/pension-funds.constants';
import { PersonalPensionTypes } from '../../helpers/constants/personal-pension-types.constants';
import { ExposedPensionSummaryReq } from '../../interfaces/requests/exposedPensionSummaryReq';
import { ExposedUpdatePersonalTaxCreditsReq } from '../../interfaces/requests/exposedUpdatePersonalTaxCreditsReq';
import { ExposedUpdateTaxBracketsReq } from '../../interfaces/requests/exposedUpdateTaxBracketsReq';
import { ExposedEstimatedFuturePensionBenefitsExamplesRes } from '../../interfaces/responses/exposed/exposedEstimatedFuturePensionBenefitsExamples';
import { ExposedGeneratedPdfRes } from '../../interfaces/responses/exposed/exposedGeneratedPdfRes';
import { ExposedKriaPensionPaymentsYearGroup } from '../../interfaces/responses/exposed/exposedKriaPensionPaymentsYearGroup';
import { ExposedListKriaPensionPaymentsPersonalPension } from '../../interfaces/responses/exposed/exposedListKriaPensionPaymentsPersonalPensionRes';
import { ExposedListKriaPensionPaymentsRes } from '../../interfaces/responses/exposed/exposedListKriaPensionPaymentsRes';
import { ExposedListPensionBenefitsInOtherFundsRes } from '../../interfaces/responses/exposed/exposedListPensionBenefitsInOtherFundsRes';
import { ExposedPensionBenefitsLSRARes } from '../../interfaces/responses/exposed/exposedPensionBenefitsLSRARes';
import { ExposedPensionBenefitsLSRBRes } from '../../interfaces/responses/exposed/exposedPensionBenefitsLSRBRes';
import { ExposedPersonalPensionSavingsRes } from '../../interfaces/responses/exposed/exposedPersonalPensionSavingsRes';
import { ExposedReadDocumentRes } from '../../interfaces/responses/exposed/exposedReadDocumentRes';
import { ExposedTaxDetailsRes } from '../../interfaces/responses/exposedTaxDetailsRes';
import { ExposedlTaxConfigs } from '../../interfaces/responses/exposedlTaxConfigs';
import { PensionInOtherFundsTermsConfirmation } from '../../interfaces/support/pension/pensionInOtherFundsTermsConfirmation';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';
import { CacheService } from '../cache/cache.service';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class PensionService {
    // Fetches stored information about whether or not the user has accepted the terms to allow LSR to fetch data from other pension funds.
    get pensionInOtherFundsTermsConfirmation(): PensionInOtherFundsTermsConfirmation | null {
        const storedValue = localStorage.getItem('lsr-pension-in-other-funds-terms-confirmation');
        return storedValue ? JSON.parse(storedValue) : null;
    }

    set pensionInOtherFundsTermsConfirmation(value: PensionInOtherFundsTermsConfirmation | null) {
        if (value == null) {
            localStorage.removeItem('lsr-pension-in-other-funds-terms-confirmation');
            return;
        }
        localStorage.setItem('lsr-pension-in-other-funds-terms-confirmation', JSON.stringify(value));
    }

    constructor(
        private http: HttpClient,
        private userService: UserService,
        private cacheService: CacheService
    ) {
        userService.currentUser.pipe(filter((x) => x != null)).subscribe((user) => {
            const termsConfirmation = this.pensionInOtherFundsTermsConfirmation;
            if (termsConfirmation && termsConfirmation.ssn != user?.ssn) {
                this.pensionInOtherFundsTermsConfirmation = null;
            }
        });
    }

    getPensionBenefitsLSRA = (): Observable<ExposedPensionBenefitsLSRARes | null> => {
        return this.cacheService
            .getOrFetch(
                'pension-benefits_LSRA',
                () =>
                    this.http.get<HttpStatus<ExposedPensionBenefitsLSRARes>>(
                        environment.localApi + 'Pension/pensionBenefitsLSRA'
                    ),
                true
            )
            .pipe(
                map((res: HttpStatus<ExposedPensionBenefitsLSRARes>) => res?.returnItem ?? null),
                catchError((error) => {
                    console.log(error);
                    return of(null);
                })
            );
    };

    getPensionBenefitsLSRB = (): Observable<ExposedPensionBenefitsLSRBRes | null> => {
        return this.cacheService
            .getOrFetch(
                'pension-benefits_LSRB',
                () =>
                    this.http.get<HttpStatus<ExposedPensionBenefitsLSRBRes>>(
                        environment.localApi + 'Pension/pensionBenefitsLSRB'
                    ),
                true
            )
            .pipe(
                map((res: HttpStatus<ExposedPensionBenefitsLSRBRes>) => res?.returnItem ?? null),
                catchError((error) => {
                    console.log(error);
                    return of(null);
                })
            );
    };

    getPensionPayments = (
        fund: string
    ): Observable<ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]> => {
        if (!(fund == PensionFunds.LSRA || fund == PensionFunds.LSRB)) {
            return of([]);
        }

        return this.cacheService
            .getOrFetch(`pension-payments_${fund}`, () =>
                this.http.get<HttpStatus<ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]>>(
                    `${environment.localApi}Pension/pensionPayments/${fund}`
                )
            )
            .pipe(
                map(
                    (res: HttpStatus<ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]>) =>
                        res.returnItem
                ),
                catchError((error) => {
                    console.log(error);
                    return of([] as ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsRes>[]);
                })
            );
    };

    getPersonalPensionSavings = (): Observable<ExposedPersonalPensionSavingsRes> => {
        return this.cacheService
            .getOrFetch(`personal-pension-savings`, () =>
                this.http.get<HttpStatus<ExposedPersonalPensionSavingsRes>>(
                    `${environment.localApi}Pension/personalPensionSavings`
                )
            )
            .pipe(
                map((res: HttpStatus<ExposedPersonalPensionSavingsRes>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of([] as ExposedPersonalPensionSavingsRes);
                })
            );
    };

    getPersonalPensionPayments = (
        type: string
    ): Observable<ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]> => {
        if (!(type == PersonalPensionTypes.Regular || type == PersonalPensionTypes.Specified)) {
            return of([]);
        }

        return this.cacheService
            .getOrFetch(`personal-pension-payments_${type}`, () =>
                this.http.get<
                    HttpStatus<ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]>
                >(`${environment.localApi}Pension/personalPensionPayments/${type}`)
            )
            .pipe(
                map(
                    (
                        res: HttpStatus<
                            ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
                        >
                    ) => res.returnItem
                ),
                catchError((error) => {
                    console.log(error);
                    return of(
                        [] as ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
                    );
                })
            );
    };

    getBenefitsInOtherFunds = (includeAmounts: boolean): Observable<ExposedListPensionBenefitsInOtherFundsRes[]> => {
        const key = `benefits-other-funds`;
        const cachedData = this.cacheService.get<ExposedListPensionBenefitsInOtherFundsRes[]>(key);

        if (
            cachedData != null &&
            ((!includeAmounts && cachedData!.every((x) => x.monthlyPayments == null)) ||
                (includeAmounts && cachedData!.every((x) => x.monthlyPayments != null && x.monthlyPayments.length > 0)))
        ) {
            return of(cachedData!);
        }
        return this.http
            .get<HttpStatus<ExposedListPensionBenefitsInOtherFundsRes[]>>(
                environment.localApi + 'Pension/pensionBenefitsInOtherFunds',
                {
                    params: {
                        includeAmounts,
                    },
                }
            )
            .pipe(
                map((res: HttpStatus<ExposedListPensionBenefitsInOtherFundsRes[]>) => {
                    if (
                        !includeAmounts ||
                        res.returnItem.every((x) => x.monthlyPayments != null && x.monthlyPayments.length > 0)
                    ) {
                        this.cacheService.set(key, res.returnItem);
                    }
                    return res.returnItem;
                }),
                catchError((error) => {
                    console.log(error);
                    return of([] as ExposedListPensionBenefitsInOtherFundsRes[]);
                })
            );
    };

    getEstimatedFuturePensionBenefitsExamples =
        (): Observable<ExposedEstimatedFuturePensionBenefitsExamplesRes | null> => {
            return this.cacheService
                .getOrFetch(
                    'estimated-future-pension-benefits',
                    () =>
                        this.http.get<HttpStatus<ExposedEstimatedFuturePensionBenefitsExamplesRes>>(
                            environment.localApi + 'Pension/estimatedFuturePensionBenefitsExamples'
                        ),
                    true
                )
                .pipe(
                    map((res: HttpStatus<ExposedEstimatedFuturePensionBenefitsExamplesRes>) => res?.returnItem ?? null),
                    catchError((error) => {
                        console.log(error);
                        return of(null);
                    })
                );
        };

    getPensionOverviewSummaryLSRA = (req: ExposedPensionSummaryReq): Observable<ExposedReadDocumentRes> => {
        return this.http
            .post<HttpStatus<ExposedReadDocumentRes>>(environment.localApi + 'pension/PensionSummaryA_deildPDF', req)
            .pipe(
                map((res: HttpStatus<ExposedReadDocumentRes>) => res.returnItem),
                catchError((error) => {
                    throw error;
                })
            );
    };

    getPensionOverviewSummaryLSRB = (req: ExposedPensionSummaryReq): Observable<ExposedReadDocumentRes> => {
        return this.http
            .post<HttpStatus<ExposedReadDocumentRes>>(environment.localApi + 'pension/PensionSummaryB_deildPDF', req)
            .pipe(
                map((res: HttpStatus<ExposedReadDocumentRes>) => res.returnItem),
                catchError((error) => {
                    throw error;
                })
            );
    };

    getPersonalPensionOverviewSummary = (req: ExposedPensionSummaryReq): Observable<ExposedReadDocumentRes> => {
        return this.http
            .post<HttpStatus<ExposedReadDocumentRes>>(environment.localApi + 'pension/PensionSummarySereignPDF', req)
            .pipe(
                map((res: HttpStatus<ExposedReadDocumentRes>) => res.returnItem),
                catchError((error) => {
                    throw error;
                })
            );
    };

    public getPdfPensionPaycheck(paycheckNumber: string | null): Observable<ExposedGeneratedPdfRes | null> {
        return this.http
            .get<
                HttpStatus<ExposedReadDocumentRes>
            >(environment.localApi + 'pension/paychecks/PdfPensionPaycheck/' + paycheckNumber)
            .pipe(
                map((res: HttpStatus<ExposedReadDocumentRes>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of(null as ExposedReadDocumentRes | null);
                })
            );
    }

    public getTaxDetails = (): Observable<ExposedTaxDetailsRes> => {
        return this.http.get<HttpStatus<ExposedTaxDetailsRes>>(environment.localApi + 'pension/getTaxDetails/').pipe(
            map((res: HttpStatus<ExposedTaxDetailsRes>) => res.returnItem),
            catchError((error) => {
                throw error;
            })
        );
    };

    public getTaxConfigs = (): Observable<ExposedlTaxConfigs> => {
        return this.http.get<HttpStatus<ExposedlTaxConfigs>>(environment.localApi + 'pension/getTaxConfigs/').pipe(
            map((res: HttpStatus<ExposedlTaxConfigs>) => res.returnItem),
            catchError((error) => {
                throw error;
            })
        );
    };

    public updatePersonalTaxCredits(req: ExposedUpdatePersonalTaxCreditsReq): Observable<boolean> {
        return this.http.post<HttpStatus<boolean>>(environment.localApi + 'pension/updatePersonalTaxCredits', req).pipe(
            map(() => true),
            catchError((error) => {
                throw error;
            })
        );
    }

    public updateTaxBracket(req: ExposedUpdateTaxBracketsReq): Observable<boolean> {
        return this.http.post<HttpStatus<boolean>>(environment.localApi + 'pension/updateTaxBrackets', req).pipe(
            map(() => true),
            catchError((error) => {
                throw error;
            })
        );
    }
}
