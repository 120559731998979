import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRLoaderComponent } from '../lsr-loader';

@Component({
    selector: 'lsr-amount-card',
    standalone: true,
    imports: [LSRIconComponent, LSRButtonComponent, LSRLoaderComponent, RouterLink],
    templateUrl: `lsr-amount-card.component.html`,
    styleUrl: `lsr-amount-card.component.scss`,
    host: { class: 'lsr-amount-card' },
})
export class LSRAmountCardComponent {
    @Input() title: string = '';
    @Input() amountString: string = '';
    @Input() subtitle?: string;
    @Input() type: 'link' | 'button' = 'link';
    @Input() buttonTitle?: string;
    @Input() buttonLoading: boolean = false;
    @Input() buttonIcon?: string;
    @Input() linkTitle?: string;
    @Input() linkHref?: string; // if pdf, put file link to download
    @Input() isLoading: boolean = false;
    @Output() buttonClicked = new EventEmitter<void>();
    @Input() headerClickable: boolean = false;
    @Output() headerClicked = new EventEmitter<void>();

    onButtonClick = () => {
        this.buttonClicked.emit();
    };

    onHeaderClick = () => {
        this.headerClicked.emit();
    };
}
