export const MonthIdentifiers = [
    { identifier: 'jan', translationKey: 'mpgMonthJanuary' },
    { identifier: 'feb', translationKey: 'mpgMonthFebruary' },
    { identifier: 'mar', translationKey: 'mpgMonthMarch' },
    { identifier: 'apr', translationKey: 'mpgMonthApril' },
    { identifier: 'may', translationKey: 'mpgMonthMay' },
    { identifier: 'jun', translationKey: 'mpgMonthJune' },
    { identifier: 'jul', translationKey: 'mpgMonthJuly' },
    { identifier: 'aug', translationKey: 'mpgMonthAugust' },
    { identifier: 'sep', translationKey: 'mpgMonthSeptember' },
    { identifier: 'oct', translationKey: 'mpgMonthOctober' },
    { identifier: 'nov', translationKey: 'mpgMonthNovember' },
    { identifier: 'dec', translationKey: 'mpgMonthDecember' },
];
