<div class="pretty-check {{ identifier }} {{ modifier }}">
    <input
        type="radio"
        [name]="name"
        [value]="value"
        [checked]="selectedValue === value"
        (change)="selectValue()"
        [disabled]="isDisabled"
    />
    @if (label.length > 0) {
        <label class="label {{ isLabelHidden ? 'label--hidden' : '' }}" for="{{ id }}">
            {{ label }}
            @if (isRequired) {
                <span class="label__asterisk">*</span>
            }
        </label>
    }
</div>
