<div class="confirmation-message">
    @if (success !== undefined) {
        @if (success) {
            <div class="confirmation-message__header">
                <lsr-icon icon="email" modifier="large"></lsr-icon>
                <lsr-title text="Netfang staðfest"></lsr-title>
            </div>
            <p class="body1">Netfangið þitt hefur verið staðfest!</p>
            <lsr-button
                buttonType="link"
                text="Fara á yfirlitssíðu"
                modifier="button--secondary"
                href="/yfirlit"
            ></lsr-button>
        } @else {
            <div class="confirmation-message__header">
                <lsr-icon icon="email" modifier="large"></lsr-icon>
                <lsr-title text="Villa kom upp"></lsr-title>
            </div>
            <p class="body1">Ekki tókst að staðfesta netfangið!</p>
            <lsr-button
                buttonType="link"
                text="Fara á innskráningarsíðu"
                modifier="button--secondary"
                href="/login"
            ></lsr-button>
        }
    }
</div>
