import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet, Routes } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { TranslocoCurrencyPipe } from '@jsverse/transloco-locale';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRAmountCardComponent } from '@lsr/ui-components/lsr-amount-card';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRNavigationComponent } from '@lsr/ui-components/lsr-navigation';
import { LSRTableBoxComponent } from '@lsr/ui-components/lsr-table/lsr-table-box';
import { LSRTableExpandableComponent } from '@lsr/ui-components/lsr-table/lsr-table-expandable';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { filter, forkJoin, Observable, take } from 'rxjs';
import { PersonalPensionTypes } from '../../../helpers/constants/personal-pension-types.constants';
import { ExposedKriaPaidContributionsYearGroupRes } from '../../../interfaces/responses/exposed/exposedKriaPaidContributionsYearGroupRes';
import { ExposedKriaPensionPaymentsYearGroup } from '../../../interfaces/responses/exposed/exposedKriaPensionPaymentsYearGroup';
import { ExposedListKriaPensionPaymentsPersonalPension } from '../../../interfaces/responses/exposed/exposedListKriaPensionPaymentsPersonalPensionRes';
import { ExposedMeRes } from '../../../interfaces/responses/exposed/exposedMeRes';
import {
    ExposedPersonalPensionChartData,
    ExposedPersonalPensionSavingsRes,
    ExposedPersonalPensionTypeBalanceRes,
    ExposedSpecifiedPersonalPensionChartData,
} from '../../../interfaces/responses/exposed/exposedPersonalPensionSavingsRes';
import { ContributionsService } from '../../../services/contributions/contributions.service';
import { PensionService } from '../../../services/pension/pension.service';
import { UserService } from '../../../services/user/user.service';
import { PersonalPensionSavingsChartOptions } from './personal-pension-chart-options';

@Component({
    selector: 'app-personal-pension',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRAmountCardComponent,
        LSRTableExpandableComponent,
        LSRTableBoxComponent,
        LSRNavigationComponent,
        LSRActionCardComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
        TranslocoCurrencyPipe,
        ChartComponent,
        NgApexchartsModule,
        RouterOutlet,
    ],
    templateUrl: 'personal-pension.component.html',
    styleUrl: './personal-pension.component.scss',
    host: { class: 'main__content' },
})
export class PersonalPensionComponent implements OnInit {
    @ViewChild('chart') chart?: ChartComponent;
    chartOptions?: PersonalPensionSavingsChartOptions;
    routes: Routes = [];
    loading = true;
    userDetails?: ExposedMeRes;
    currentDateString: string = formatDate(new Date(), 'dd.MM.yyyy', 'en-EN');

    traditionalSavings?: ExposedPersonalPensionTypeBalanceRes<ExposedPersonalPensionChartData>;
    traditionalPayments?: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[];
    traditionalContributions?: ExposedKriaPaidContributionsYearGroupRes[];

    specifiedSavings?: ExposedPersonalPensionTypeBalanceRes<ExposedSpecifiedPersonalPensionChartData>;
    specifiedPayments?: ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[];
    specifiedContributions?: ExposedKriaPaidContributionsYearGroupRes[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private pensionService: PensionService,
        private contributionsService: ContributionsService,
        private userService: UserService
    ) {
        this.routes =
            this.router.config
                .find((route) => route.data?.['domain'] === 'menuItems')
                ?.children?.find((child) => child.path === 'sereign')?.children || [];
    }

    ngOnInit(): void {
        this.userService.currentUser
            .pipe(
                filter((x) => x != null),
                take(1)
            )
            .subscribe((user) => {
                this.userDetails = user;
                this.loading = true;

                const requests = {} as {
                    savings: Observable<ExposedPersonalPensionSavingsRes>;
                    traditionalPayments: Observable<
                        ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
                    >;
                    specifiedPayments: Observable<
                        ExposedKriaPensionPaymentsYearGroup<ExposedListKriaPensionPaymentsPersonalPension>[]
                    >;
                    traditionalContributions: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
                    specifiedContributions: Observable<ExposedKriaPaidContributionsYearGroupRes[]>;
                };

                requests.savings = this.pensionService.getPersonalPensionSavings();
                requests.traditionalPayments = this.pensionService.getPersonalPensionPayments(
                    PersonalPensionTypes.Regular
                );
                requests.traditionalContributions = this.contributionsService.contributionsLSRSER();
                requests.specifiedPayments = this.pensionService.getPersonalPensionPayments(
                    PersonalPensionTypes.Specified
                );
                requests.specifiedContributions = this.contributionsService.contributionsLSRTS();

                forkJoin(requests)
                    .subscribe((res) => {
                        this.traditionalSavings = res.savings.personalPension;
                        this.traditionalPayments = res.traditionalPayments;
                        this.traditionalContributions = res.traditionalContributions;

                        this.specifiedSavings = res.savings.specifiedPersonalPension;
                        this.specifiedPayments = res.specifiedPayments;
                        this.specifiedContributions = res.specifiedContributions;

                        // If the individual only has spevified personal pension then that tab gets automatically selected by default
                        if (!this.traditionalSavings && this.specifiedSavings) {
                            this.router.navigate(['tilgreind-sereign-lsr'], { relativeTo: this.route });
                        }
                    })
                    .add(() => {
                        this.loading = false;
                    });
            });
    }
}
