import { DefaultNumberTransformer, NumberStyles } from '@jsverse/transloco-locale';

export class CustomNumberTransformer extends DefaultNumberTransformer {
    public override transform(
        value: number | string,
        type: NumberStyles,
        locale: string,
        options: Intl.NumberFormatOptions
    ): string {
        // An ugly hack to make sure numbers are represented correctly according to Icelandic standards, the number format is shared with Germany so we use it instead
        // In some cases the icelandic locale is not supported for numbers
        if (locale === 'is-IS') {
            locale = 'de-DE';

            if (type === 'currency') {
                const currencyValue = super.transform(value, type, locale, options);
                // By default currency values are displayed as "100 ISK" so replace "ISK" with "kr."
                return currencyValue.replace('ISK', 'kr.');
            }
        }
        // Fallback to default transformer
        return super.transform(value, type, locale, options);
    }
}
