@if (data) {
    <td colspan="5">
        <!-- inner table component -->
        <div class="table__table-container" [ngStyle]="{ 'max-width': maxTableWidth, 'left': tableLeft }">
            <div class="table__table">
                <table>
                    <thead>
                        <tr>
                            @for (innerHeaderTitle of data.headerTitles; track innerHeaderTitle) {
                                <th tabindex="0">
                                    {{ innerHeaderTitle | transloco }}
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        @for (innerRow of data.rows.slice(startIndex, endIndex); track innerRow; let index = $index) {
                            <tr>
                                @for (innerRowCell of innerRow.rowTitles; track $index) {
                                    <td tabindex="0">
                                        {{
                                            isString(innerRowCell)
                                                ? (innerRowCell | transloco)
                                                : (innerRowCell.text
                                                  | transloco: innerRowCell?.options?.translationParams)
                                        }}
                                    </td>
                                }
                            </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="7">
                                <lsr-pagination
                                    [totalPages]="totalPages"
                                    [currentPage]="currentPage"
                                    (pageChange)="setPage($event)"
                                >
                                </lsr-pagination>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </td>
}
