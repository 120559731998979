import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExposedUpdateBankAccountsReq } from '../../interfaces/requests/exposedUpdateBankAccountsReq';
import { ExposedBankAccountsRes } from '../../interfaces/responses/exposed/exposedBankAccountsRes';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';

@Injectable({
    providedIn: 'root',
})
export class BankAccountsService {
    constructor(private http: HttpClient) {}

    public getPensionBankAccounts = (): Observable<ExposedBankAccountsRes> => {
        return this.http
            .get<HttpStatus<ExposedBankAccountsRes>>(environment.localApi + 'bankAccounts/getPensionBankAccounts/')
            .pipe(
                map((res: HttpStatus<ExposedBankAccountsRes>) => res.returnItem),
                catchError((error) => {
                    throw error;
                })
            );
    };

    public updatePensionBankAccounts(req: ExposedUpdateBankAccountsReq): Observable<boolean> {
        return this.http
            .post<HttpStatus<boolean>>(environment.localApi + 'bankAccounts/updatePensionBankAccounts', req)
            .pipe(
                map(() => true),
                catchError((error) => {
                    throw error;
                })
            );
    }

    public validateBankAccount = (req: ExposedUpdateBankAccountsReq): Observable<boolean> => {
        return this.http.post<HttpStatus<boolean>>(environment.localApi + 'bankAccounts/validateBankAccount', req).pipe(
            map((res: HttpStatus<boolean>) => res.returnItem),
            catchError((error) => {
                throw error;
            })
        );
    };
}
