import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LSRFooterComponent } from '@lsr/ui-components/lsr-footer';
import { LSRHeaderComponent } from '@lsr/ui-components/lsr-header';
import { LSRMobileHeaderComponent } from '@lsr/ui-components/lsr-mobile-header';
import { LSRSplashScreenComponent } from "@lsr/ui-components/lsr-splash-screen";
import { LSRToastComponent } from '@lsr/ui-components/lsr-toast';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs';
import { routes } from '../app/app.routes';
import { AuthService } from '../services/auth/auth.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        LSRFooterComponent,
        LSRHeaderComponent,
        LSRMobileHeaderComponent,
        LSRSplashScreenComponent,
        CommonModule,
        TranslocoModule,
        LSRToastComponent,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    title = 'customer-portal';
    routes = routes;

    constructor(
        private authService: AuthService,
        private router: Router,
        public translocoService: TranslocoService,
        private config: PrimeNGConfig
    ) {
        this.translocoService.load('is').subscribe();

        // Add translations for PrimeNG components
        this.translocoService.langChanges$.subscribe((res) => {
            this.translocoService.load(res).subscribe(() => {
                this.config.setTranslation(JSON.parse(this.translocoService.translateObject('PrimeNG')));
            });
        });
    }

    ngOnInit() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            if (this.authService.authenticated && this.checkUrl()) {
                this.authService.refreshAccessToken().subscribe();
            }
        });
    }

    checkUrl = () => {
        if (window.location.href.indexOf('stadfesting-netfangs') > -1 || window.location.href.indexOf('login') > -1) {
            return false;
        } else {
            return true;
        }
    };
}
