<header class="mobile-header" [ngClass]="{ 'mobile-header--navigation-active': showMobileMenu }">
    <div class="mobile-header__bar">
        <a class="mobile-header__logo" routerLink="/" title="Til baka á forsíðu">
            <lsr-icon icon="logo"></lsr-icon>
            Lífeyrissjóður starfsmanna ríkisins
        </a>
        <div class="mobile-header__trigger">
            <lsr-button
                buttonType="button"
                identifier="mobile-header__bar-button mobile-header__bar-button--open"
                modifier="button--secondary button--square"
                text="Opna leiðarkerfi"
                icon="hamburger"
                (click)="onMenuClick()"
            ></lsr-button>
            <lsr-button
                buttonType="button"
                identifier="mobile-header__bar-button mobile-header__bar-button--close"
                modifier="button--secondary button--square"
                text="Loka leiðarkerfi"
                icon="close"
                (click)="onMenuClick()"
            ></lsr-button>
        </div>
    </div>
    <div class="mobile-header__modal">
        <lsr-navigation [routes]="routes" identifier="mobile-navigation" [displayChildren]="true"></lsr-navigation>
        <div class="mobile-header__modal-buttons">
            @for (lang of translocoService.getAvailableLangs(); track lang) {
                @if (lang !== translocoService.getActiveLang()) {
                    <lsr-button
                        buttonType="button"
                        identifier="mobile-header__modal-button mobile-header__modal-button--language"
                        modifier="button--secondary button--big button--red"
                        text="{{ lang.toString().toUpperCase() }}"
                        title="{{ 'butChooseLanguage' | transloco }}"
                        (click)="setLang(lang.toString())"
                    ></lsr-button>
                }
            }
            <lsr-button
                buttonType="link"
                identifier="mobile-header__modal-button mobile-header__modal-button--settings"
                modifier="button--secondary button--square button--big button--red"
                text="Stillingar"
                icon="settings"
                routerLink="/stillingar"
            ></lsr-button>
            <lsr-button
                buttonType="link"
                identifier="mobile-header__modal-button mobile-header__modal-button--logout"
                modifier="button--default button--big"
                text="Útskráning"
                icon="padlock"
                [isLoading]="isLoggingOut"
                (click)="logoutClick()"
            ></lsr-button>
        </div>
    </div>
</header>
