<div class="table">
    @if (title) {
        <h2 class="table__headline headline4" tabindex="0">{{ title }}</h2>
    }
    @if (data) {
        <div class="table__table-container">
            <div class="table__table">
                <table>
                    <thead>
                        <tr>
                            @for (title of data.headerTitles; track title) {
                                <th tabindex="0">
                                    {{ title | transloco }}
                                </th>
                            }
                            <th></th>
                            <!-- inner table arrow header -->
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of data.rows.slice(startIndex, endIndex); track $index) {
                            <tr class="table__expandable-table-row">
                                @for (rowCell of row.rowTitles; track $index) {
                                    <td tabindex="0">
                                        {{
                                            isString(rowCell)
                                                ? (rowCell | transloco)
                                                : (rowCell.text | transloco: rowCell?.options?.translationParams)
                                        }}
                                    </td>
                                }

                                <td class="table__toggle-container">
                                    @if (row.innerTable) {
                                        <lsr-button
                                            modifier="table__toggle"
                                            (click)="toggleInnerTable($event)"
                                            icon="chevron-up"
                                        >
                                        </lsr-button>
                                    }
                                </td>
                            </tr>
                            <!-- inner table -->
                            <lsr-table-expandable-inner [maxRows]="innerTableMaxRows" [data]="row.innerTable">
                            </lsr-table-expandable-inner>
                        }
                    </tbody>
                    @if (linkHref) {
                        <tfoot>
                            <tr>
                                <td colspan="3">
                                    <a href="{{ linkHref }}" class="button button--secondary" title="{{ linkTitle }}">{{
                                        linkTitle
                                    }}</a>
                                </td>
                            </tr>
                        </tfoot>
                    } @else {
                        <tfoot>
                            <tr>
                                <td colspan="5">
                                    <lsr-pagination
                                        [totalPages]="totalPages"
                                        [currentPage]="currentPage"
                                        (pageChange)="setPage($event)"
                                    >
                                    </lsr-pagination>
                                </td>
                            </tr>
                        </tfoot>
                    }
                </table>
            </div>
        </div>
    }
</div>
