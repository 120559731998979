import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LSRButtonComponent } from '../lsr-button';
import { LSRIconComponent } from '../lsr-icon';

@Component({
    selector: 'lsr-action-card',
    standalone: true,
    imports: [LSRIconComponent, LSRButtonComponent],
    templateUrl: `lsr-action-card.component.html`,
    styleUrl: `lsr-action-card.component.scss`,
    host: { class: 'lsr-action-card' },
})
export class LSRActionCardComponent {
    @Input() title: string = '';
    @Input() text?: string;
    @Input() buttonText?: string;
    @Input() linkHref?: string; // if pdf, put file link to download
    @Input() isDialogButton?: boolean;
    @Input() isLoading: boolean = false;

    @Output() buttonClick = new EventEmitter<boolean>();

    onClick() {
        this.buttonClick.emit(true);
    }

    // TODO: Connect with opening dialog outside of card, or inside if content is general
}
