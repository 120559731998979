import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { ExposedPensionBenefitsLSRBRes } from '../interfaces/responses/exposed/exposedPensionBenefitsLSRBRes';

export const pensionBenefitsLSRBToCardData = (
    res: ExposedPensionBenefitsLSRBRes | null,
    age: number,
    localeService: TranslocoLocaleService
) => {
    if (!res) {
        return;
    }

    let amountString = '';
    let description = '';
    if (res.benefitsParked) {
        amountString = localeService.localizeNumber(res.currentAcquiredPensionBenefitsAmount, 'currency');
        description =
            age > 65
                ? 'mppAcquiredBenefitsLSRBCardSubtitleMonthlyCurrent'
                : 'mppAcquiredBenefitsLSRBCardSubtitleMonthly65';
    } else {
        amountString = localeService.localizeNumber(
            res.currentAcquiredPensionBenefitsPercentage,
            'percent',
            undefined,
            { minimumFractionDigits: 3, maximumFractionDigits: 3 }
        );
        description = 'mppAcquiredBenefitsLSRBCardSubtitleSalaryRatio';
    }

    return {
        amountString,
        description,
    } as { amountString: string; description: string };
};
