<!-- Dialog wrapper around any content -->
<div class="dialog {{ modifier }} {{ isOpen ? '' : 'dialog--closed' }}">
    <div class="dialog__wrapper" (keydown)="onKeyDown($event)" tabindex="0">
        <div class="dialog__box">
            <div class="dialog__header">
                @if (headerIcon) {
                    <lsr-icon icon="{{ headerIcon }}" color="#0f8b8d"></lsr-icon>
                }
                @if (headerTitle) {
                    <h1 class="headline4 dialog__header-title" tabindex="0">{{ headerTitle }}</h1>
                }
                <lsr-button modifier="dialog__close-button" icon="close" (click)="onCloseButton()"></lsr-button>
            </div>
            <div class="dialog__body body2 richtext richtext--flex" tabindex="0">
                <ng-content></ng-content>
                <div class="dialog__action-bar">
                    <lsr-button
                        [text]="confirmButtonText ?? 'Staðfesta'"
                        (click)="confirm()"
                        modifier="button--default"
                        [isDisabled]="confirmButtonDisabled"
                        [isLoading]="confirmButtonLoading"
                    >
                    </lsr-button>
                    @if (includeCancelButton) {
                        <lsr-button
                            [text]="cancelButtonText ?? 'Hætta við'"
                            (click)="dismiss()"
                            modifier="button--secondary"
                        >
                        </lsr-button>
                    }
                </div>
            </div>
        </div>
    </div>
    <div
        class="dialog__overlay"
        (click)="dismiss()"
        (keydown.enter)="dismiss()"
        (keydown.space)="dismiss()"
        role="button"
        tabindex="0"
    ></div>
</div>
