import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(private translocoService: TranslocoService) {}

    public showTranslationKeys = () => {
        const langs = this.translocoService.getAvailableLangs();
        for (const lang of langs) {
            const translation = this.translocoService.getTranslation(lang as string);
            for (const t in translation) {
                if (t === 'PrimeNG') {
                    continue;
                }
                translation[t] = t;
            }
            this.translocoService.setTranslation(translation, lang as string);
        }
    };
}
