import { Component, Input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { TableData } from '../lsr-table-interfaces';
import { isString } from '../lsr-table.utils';

@Component({
    selector: 'lsr-table-box',
    standalone: true,
    imports: [TranslocoPipe],
    templateUrl: `lsr-table-box.component.html`,
    styleUrl: `lsr-table-box.component.scss`,
    host: { class: 'lsr-table-box' },
})
export class LSRTableBoxComponent {
    @Input() modifier?: string;
    @Input() title?: string;
    @Input() subtitle?: string;
    @Input() text?: string;
    @Input() cornerLinkTitle?: string;
    @Input() cornerLinkHref?: string;
    @Input() cornerLinkImage?: string; // image src
    @Input() data?: TableData;

    // Declare util function for use in component template
    isString = isString;
}
