@if (buttonType === 'button') {
    <button
        [ngClass]="{ 'button--loading': isLoading }"
        class="button {{ modifier }} {{ identifier }}"
        title="{{ title || text }}"
        [disabled]="isDisabled || isLoading"
    >
        @if (icon) {
            <span class="button__icon">
                <lsr-icon [icon]="icon" color="{{ iconColor }}"></lsr-icon>
            </span>
        }
        @if (text) {
            <span class="button__text"> {{ text }} </span>
        }
    </button>
}

@if (buttonType === 'link') {
    @if (href) {
        <a class="button {{ modifier }} {{ identifier }}" [href]="href" title="{{ title || text }}">
            @if (icon) {
                <span class="button__icon">
                    <lsr-icon [icon]="icon" color="{{ iconColor }}"></lsr-icon>
                </span>
            }
            @if (text) {
                <span class="button__text">
                    {{ text }}
                </span>
            }
        </a>
    } @else if (routerLink) {
        <a class="button {{ modifier }} {{ identifier }}" [routerLink]="routerLink" title="{{ title || text }}">
            @if (icon) {
                <span class="button__icon">
                    <lsr-icon [icon]="icon" color="{{ iconColor }}"></lsr-icon>
                </span>
            }
            @if (text) {
                <span class="button__text">
                    {{ text }}
                </span>
            }
        </a>
    }
}

@if (buttonType === 'click') {
    <button
        [ngClass]="{ 'button--loading': isLoading }"
        class="button {{ modifier }} {{ identifier }}"
        title="{{ title || text }}"
        (click)="onClick()"
        [disabled]="isDisabled || isLoading"
    >
        @if (icon) {
            <span class="button__icon">
                <lsr-icon [icon]="icon" color="{{ iconColor }}"></lsr-icon>
            </span>
        }
        @if (text) {
            <span class="button__text">
                {{ text }}
            </span>
        }
    </button>
}
