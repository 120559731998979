import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM } from '@lsr/constants/generateExternalServiceJWTSystem.constants';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { environment } from '../../../../environments/environment';
import { CoreDataApplicationInfo } from '../../../../interfaces/support/applications/coreDataApplicationInfo';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'app-loans-applications',
    templateUrl: 'loans-applications.component.html',
    styleUrl: './loans-applications.component.scss',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRActionCardComponent,
        LSRIframeWrapperComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
    ],
    host: { class: 'main__content' },
})
export class LoanApplicationsComponent {
    @ViewChild(LSRDialogBasicComponent) dialog!: LSRDialogBasicComponent;

    public redirectUrl = '';
    public url = '';
    public selectedApplication: CoreDataApplicationInfo | undefined;
    public applications: CoreDataApplicationInfo[] = [];

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.initApplications();
        this.checkUrl();
    }

    /**
     * Opens the loan application dialog for the selected application.
     * Retrieves a token and handles redirection based on the application type.
     * @param application The loan application to open
     */
    openApplication(application: CoreDataApplicationInfo) {
        application.isLoading = true;
        this.selectedApplication = application;
        this.url = '';
        this.redirectUrl = '';

        const updateUrlPath = (pathSuffix: string) => this.location.go('/lanaumsoknir/' + pathSuffix);

        const openDialogAndSubscribeClose = () => {
            this.dialog.open();
            this.dialog.beingClosed.subscribe(() => {
                updateUrlPath('');
            });
        };

        if (application.coreDataPageId === 'nytt-lan') {
            updateUrlPath('nytt-lan');
            this.authService
                .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.LIBRA)
                .subscribe((token) => {
                    if (token) {
                        this.authService.authenticateLibraLoan(token).then(() => {
                            this.url = environment.libraPortalUrl + '/applications/overview';
                            application.isLoading = false;
                            openDialogAndSubscribeClose();
                        });
                    } else {
                        application.isLoading = false;
                    }
                });
        } else {
            updateUrlPath(application.coreDataPageId + '/' + application.coreDataFormId);
            this.authService
                .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.COREDATA)
                .subscribe((token) => {
                    this.url = `${environment.coredataPortalUrl}?token=${token}`;
                    this.redirectUrl = `${environment.coredataPortalUrl}/${application.coreDataPageId}/forms/${application.coreDataFormId}/`;
                    application.isLoading = false;
                    openDialogAndSubscribeClose();
                });
        }
    }

    /**
     * Checks the current route for specific application parameters and, if present,
     * opens the corresponding application.
     */
    checkUrl() {
        if (this.route.snapshot.paramMap.get('coreDataPageId')) {
            const application = this.applications.find(
                (x) =>
                    x.coreDataPageId == this.route.snapshot.paramMap.get('coreDataPageId') &&
                    x.coreDataFormId == this.route.snapshot.paramMap.get('coreDataFormId')
            );
            this.openApplication(application as CoreDataApplicationInfo);
        }
    }

    /**
     * Initializes the available loan applications with their respective details.
     */
    initApplications() {
        this.applications = [
            {
                titleTranslation: 'mpaLoanApplicationTitle',
                descriptionTranslation: 'mpaLoanApplicationDescription',
                coreDataPageId: 'nytt-lan',
                coreDataFormId: null,
                isLoading: false,
            },
            {
                titleTranslation: 'mpaLoanDefermentTitle',
                descriptionTranslation: 'mpaLoanDefermentDescription',
                coreDataPageId: '28',
                coreDataFormId: '56',
                isLoading: false,
            },
        ];
    }
}
