<div class="action-card">
    <h3 class="headline4 action-card__title" tabindex="0">{{ title }}</h3>
    <p class="body2" tabindex="0">{{ text }}</p>

    @if (isDialogButton) {
        <lsr-button
            modifier="button--secondary action-card__button"
            (click)="onClick()"
            text="{{ buttonText }}"
            [isLoading]="isLoading"
        ></lsr-button>
    }
    @if (!isDialogButton && linkHref) {
        <a class="button button--secondary action-card__button" href="{{ linkHref }}"
            ><span class="button__text">{{ buttonText }}</span></a
        >
    }
    <div class="action-card__border"></div>
</div>
