import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExposedConfirmEmailReq } from '../../interfaces/requests/exposedConfirmEmailReq';
import { ExposedUpdateUserPublicReq } from '../../interfaces/requests/exposedUpdateUserPublicReq';
import { ExposedMeRes } from '../../interfaces/responses/exposed/exposedMeRes';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private currentUserSubject: BehaviorSubject<ExposedMeRes | null> = new BehaviorSubject<ExposedMeRes | null>(null);
    public currentUser: Observable<ExposedMeRes | null> = this.currentUserSubject.asObservable();

    constructor(private http: HttpClient) {}

    setUserDetails = (details: ExposedMeRes | null) => {
        this.currentUserSubject.next(details);
    };

    userDetails = () => {
        this.http
            .get<HttpStatus<ExposedMeRes>>(environment.localApi + 'user/me')
            .pipe(
                tap((res: HttpStatus<ExposedMeRes>) => this.currentUserSubject.next(res.returnItem)),
                catchError((error) => {
                    console.log(error);
                    this.currentUserSubject.next(null);
                    return of(null);
                })
            )
            .subscribe();
    };

    sendConfirmationEmail = (): Observable<boolean> => {
        return this.http.post<void>(environment.localApi + 'user/sendConfirmationEmail', {}).pipe(
            map(() => true),
            catchError((error) => {
                throw error;
            })
        );
    };

    confirmEmail = (token: string): Observable<boolean> => {
        return this.http
            .post<void>(environment.localApi + 'user/confirmEmail', {
                token,
            } as ExposedConfirmEmailReq)
            .pipe(
                map(() => true),
                catchError(() => {
                    return of(false);
                })
            );
    };

    public updateUserPublic = (req: ExposedUpdateUserPublicReq): Observable<boolean> => {
        return this.http.post<HttpStatus<number>>(environment.localApi + 'user/updateUserPublic', req).pipe(
            map(() => true),
            catchError((error) => {
                throw error;
            })
        );
    };
}
