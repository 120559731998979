import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LSRButtonComponent } from '../lsr-button';
import { LSRIconComponent } from '../lsr-icon';

@Component({
    selector: 'lsr-bottom-bar',
    standalone: true,
    imports: [LSRIconComponent, LSRButtonComponent],
    templateUrl: `lsr-bottom-bar.component.html`,
    styleUrl: `lsr-bottom-bar.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-bottom-bar' },
})
export class LSRBottomBarComponent {
    @Input() modifier?: string;
    @Input() show: boolean = true;
}
