import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
    selector: 'app-calculator',
    standalone: true,
    imports: [LSRTitleComponent, LSRIframeWrapperComponent, TranslocoPipe],
    templateUrl: 'calculator.component.html',
    styleUrl: './calculator.component.scss',
    host: { class: 'main__content' },
})
export class CalculatorComponent implements OnInit {
    @ViewChild('iframeContainer') container!: ElementRef;
    @Output() postbackMessageEvent = new EventEmitter<MessageEvent>();
    @ViewChild(LSRIframeWrapperComponent) wrapper!: LSRIframeWrapperComponent;

    public url: string = '';
    public token: string = '';

    constructor(
        private authService: AuthService,
        private translocoService: TranslocoService
    ) {
        this.getCalculatorUrl();
        this.token = authService.accessToken!;
    }

    ngOnInit(): void {
        this.translocoService.langChanges$.subscribe((value) => {
            if (this.wrapper) {
                this.wrapper.postMessage({ language: value });
            }
        });
    }

    getCalculatorUrl() {
        this.url = `${environment.pensionCalculatorUrl}?compact=true&heading=false&language=${this.translocoService.getActiveLang()}`;
    }

    onPostbackMessageEvent(event: MessageEvent) {
        if (this.wrapper != null && !!event.data === event.data) {
            this.wrapper.postMessage({ authToken: this.token });
            return;
        }

        // Handle iframe resize event from calculator iframe
        const allowedDomains = [/^https?:\/\/localhost:30[0-9]{2}$/, /^https?:\/\/(?:.*\.)?lsr\.is$/];
        const iframeContainer = this.container?.nativeElement;

        if (iframeContainer == null) {
            return;
        }

        const setHeight = (data: { iframeHeight: number }) => {
            iframeContainer.style.height = `${data.iframeHeight}px`;
        };

        const originAllowed = allowedDomains.some((pattern) =>
            typeof pattern === 'string' ? pattern === event.origin : pattern.test(event.origin)
        );

        if (originAllowed) {
            if (event.data.source === 'react-devtools-content-script') {
                return;
            }

            if (event.data.iframeHeight) {
                setHeight(event.data);
            }
        }
    }
}
