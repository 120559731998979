import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Translation } from '@jsverse/transloco';
import { forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExposedListUmbracoTranslationsRes } from '../../interfaces/responses/exposed/exposedListUmbracoTranslationsRes';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader {
    private http = inject(HttpClient);
    private translationsCache = new Map<string, Translation>(); // Translation cache to store translations for each language

    getTranslation(lang: string): Promise<Translation> {
        // Initialize the translation cache if it doesn't exist
        if (!this.translationsCache) {
            this.translationsCache = new Map<string, Translation>();
        }

        // Check if the requested translation already exists in the cache
        if (this.translationsCache.has(lang)) {
            return Promise.resolve(this.translationsCache.get(lang)!); // Return from cache if found
        }

        // If the translation is not in the cache, fetch it and store in cache
        return new Promise<Translation>((resolve, reject) => {
            if (lang === 'en' || lang === 'is') {
                forkJoin({
                    en: this.http.get<HttpStatus<ExposedListUmbracoTranslationsRes[]>>(
                        environment.localApi + `translation/umbraco/en`
                    ),
                    is: this.http.get<HttpStatus<ExposedListUmbracoTranslationsRes[]>>(
                        environment.localApi + `translation/umbraco/is`
                    ),
                }).subscribe({
                    next: (translations) => {
                        // Create translation maps for both English and Icelandic
                        const enTranslationMap: Translation = {};
                        const isTranslationMap: Translation = {};

                        // Populate translation maps with key-value pairs from the responses
                        translations.en.returnItem.forEach(
                            (item) => (enTranslationMap[item.translationKey] = item.translationValue)
                        );
                        translations.is.returnItem.forEach(
                            (item) => (isTranslationMap[item.translationKey] = item.translationValue)
                        );

                        // Cache both languages
                        this.translationsCache.set('en', enTranslationMap);
                        this.translationsCache.set('is', isTranslationMap);

                        // Return the translation for the requested language
                        resolve(this.translationsCache.get(lang)!);
                    },
                    error: (err) => {
                        reject(err);
                    },
                });
            }
        });
    }
}
