import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon/lsr-icon.component';

@Component({
    selector: 'lsr-checkbox',
    standalone: true,
    imports: [FormsModule, LSRIconComponent],
    templateUrl: `lsr-input-checkbox.component.html`,
    styleUrl: `../lsr-input-base.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-input-checkbox' },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: LSRInputCheckboxComponent,
            multi: true,
        },
    ],
})
export class LSRInputCheckboxComponent implements ControlValueAccessor {
    @Input() isChecked?: boolean = false;
    @Input() identifier: string = '';
    @Input() modifier: string = '';
    @Input() label: string = '';
    @Input() isRequired?: boolean;
    @Input() isLabelHidden?: boolean;
    @Input() isDisabled: boolean = false;
    @Input() id: string = '';
    @Input() name: string = '';
    @Input() type: string = '';
    @Input() errorMessage: string = '';
    @Input() formControlName: string = '';

    onChange: ((value: boolean) => void) | null = null;
    onTouched: (() => void) | null = null;

    writeValue = (value: boolean): void => {
        this.isChecked = value;
    };

    registerOnChange = (fn: () => void): void => {
        this.onChange = fn;
    };

    registerOnTouched = (fn: () => void): void => {
        this.onTouched = fn;
    };

    setDisabledState = (isDisabled: boolean): void => {
        this.isDisabled = isDisabled;
    };

    toggleCheckbox() {
        this.isChecked = !this.isChecked;
        if (this.onChange) {
            this.onChange(this.isChecked);
        }
        if (this.onTouched) {
            this.onTouched();
        }
    }
}
