import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { Observable, Subject } from 'rxjs';
import { LSRDialogBaseComponent } from '../lsr-dialog-base/lsr-dialog-base.component';

@Component({
    selector: 'lsr-dialog-confirm',
    standalone: true,
    imports: [LSRButtonComponent, LSRIconComponent],
    templateUrl: `lsr-dialog-confirm.component.html`,
    styleUrls: ['lsr-dialog-confirm.component.scss', '../lsr-dialog-base/lsr-dialog-base.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-dialog' },
})
export class LSRDialogConfirmComponent extends LSRDialogBaseComponent {
    @Input() confirmButtonText?: string;
    @Input() confirmButtonDisabled: boolean = false;
    @Input() confirmButtonLoading: boolean = false;
    @Input() includeCancelButton: boolean = true;
    @Input() cancelButtonText?: string;
    @Input() closeOnAction: boolean = true;

    private dialogClosedSubject = new Subject<boolean>();
    dialogClosed: Observable<boolean> = this.dialogClosedSubject.asObservable();

    override onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            this.dismiss();
        }
    };

    // Close button should always close the dialog regardless if the closeOnAction flag is set or not
    onCloseButton = () => {
        this.dialogClosedSubject.next(false);
        this.close();
    };

    dismiss = () => {
        this.dialogClosedSubject.next(false);
        if (this.closeOnAction) {
            this.close();
        }
    };

    confirm = () => {
        this.dialogClosedSubject.next(true);
        if (this.closeOnAction) {
            this.close();
        }
    };
}
