import { DatePipe, NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRDatepickerComponent } from '@lsr/ui-components/lsr-datepicker';
import { LSRInputTextComponent } from '@lsr/ui-components/lsr-input/lsr-input-text';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRSelectComponent, SelectOption } from '@lsr/ui-components/lsr-select';
import { TableData, TableRowData } from '@lsr/ui-components/lsr-table/lsr-table-interfaces';
import { LSRTablePreviewComponent } from '@lsr/ui-components/lsr-table/lsr-table-preview';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { base64ToArrayBuffer, saveByteArray } from '../../../helpers/blobDownload';
import { ExposedListDocumentRes } from '../../../interfaces/responses/exposed/exposedListDocumentRes';
import { ExposedListDocumentTypeRes } from '../../../interfaces/responses/exposed/exposedListDocumentTypeRes';
import { ExposedMeRes } from '../../../interfaces/responses/exposed/exposedMeRes';
import { DocumentsService } from '../../../services/documents/documents.service';
import { PensionService } from '../../../services/pension/pension.service';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'app-documents',
    standalone: true,
    imports: [
        FormsModule,
        LSRButtonComponent,
        LSRDatepickerComponent,
        LSRInputTextComponent,
        LSRSelectComponent,
        LSRTitleComponent,
        LSRDatepickerComponent,
        TranslocoModule,
        LSRTablePreviewComponent,
        LSRButtonComponent,
        ReactiveFormsModule,
        NgClass,
        TranslocoModule,
        LSRLoaderComponent,
    ],
    providers: [DatePipe],
    templateUrl: 'documents.component.html',
    styleUrl: './documents.component.scss',
    host: { class: 'main__content' },
})
export class DocumentsComponent implements OnInit {
    //#region Globals and constructor

    pageNumber: number = 0;
    data: TableData = { headerTitles: [], rows: [] };
    userDetails: ExposedMeRes | null = null;
    isLoading = true;
    pageSize: number = 5;
    documentTypes: ExposedListDocumentTypeRes[] = [];
    documentTypeOptions: SelectOption[] = [];

    // Indicates whether the initial data fetch has been completed.
    initFetch = false;

    form = {} as FormGroup<{
        dateFrom: FormControl<Date | null>;
        dateTo: FormControl<Date | null>;
        documentTypeId: FormControl<number | null>;
        searchString: FormControl<string | null>;
    }>;

    constructor(
        private datePipe: DatePipe,
        private documentsService: DocumentsService,
        private userService: UserService,
        private fb: FormBuilder,
        public translocoService: TranslocoService,
        public pensionService: PensionService
    ) {}

    //#endregion

    //#region Init

    ngOnInit(): void {
        this.initForm();

        this.userService.currentUser.subscribe((user) => {
            this.userDetails = user;

            // Data is fetched for the user only when the current user details are available.
            if (user?.ssn && !this.initFetch) {
                this.initFetch = true;
                this.fetchData();
            }
        });
    }

    initForm() {
        const dateFrom = new Date();
        dateFrom.setMonth(dateFrom.getMonth() - 6);
        this.form = this.fb.group({
            dateFrom: [dateFrom, [Validators.required]],
            dateTo: [new Date(), [Validators.required]],
            documentTypeId: [null as number | null, []],
            searchString: ['', []],
        });
    }

    //#endregion

    //#region HTTP

    fetchData() {
        forkJoin([this.fetchDocumentTypes(), this.fetchDocuments()])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe();
    }

    fetchDocumentTypes(): Observable<void> {
        return this.documentsService.listTypes().pipe(
            map((options) => {
                this.documentTypeOptions = options.map((option) => ({
                    title: option.title,
                    value: option.documentTypeId,
                }));
            })
        );
    }

    fetchDocuments(): Observable<ExposedListDocumentRes[]> {
        this.isLoading = true;
        const searchString = this.form.get('searchString')?.value ?? null;

        return this.documentsService
            .listDocuments({
                dateFrom: this.form.get('dateFrom')?.value ?? null,
                dateTo: this.form.get('dateTo')?.value ?? null,
                documentTypeId: this.form.get('documentTypeId')?.value ?? null,
                sSN: this.userDetails?.ssn,
                searchString: this.cleanSearchString(searchString),
                skip: 0,
                take: 9999999,
            })
            .pipe(
                map((documents) => {
                    this.data = {
                        headerTitles: [
                            'mpdTableColumnTitle',
                            'mpdTableColumnDate',
                            'mpdTableColumnType',
                            'mpdTableColumnSubtype',
                        ],
                        rows: documents.map(
                            (document) =>
                                ({
                                    rowTitles: [
                                        document.title,
                                        this.datePipe.transform(document.documentDate, 'dd.MM.yyyy') ?? '',
                                        document.typeTitle ?? '',
                                        document.subcategoryTitle ?? '',
                                    ],
                                    rowData: document,
                                }) as TableRowData
                        ),
                    };
                    return documents;
                })
            );
    }

    fetchDocumentsClick() {
        this.fetchDocuments().subscribe(() => (this.isLoading = false));
    }

    fetchDocument(row: TableRowData) {
        row.isLoading = true;
        const doc: ExposedListDocumentRes = row.rowData;

        if (doc.fileExtension === 'paycheck_web') {
            this.pensionService.getPdfPensionPaycheck(doc.documentSystemExternalReference).subscribe((fileRes) => {
                if (fileRes) {
                    const byteArray = base64ToArrayBuffer(fileRes.base64File);
                    saveByteArray('lsr_launasedill_' + doc.ssn + "_" + doc.documentSystemExternalReference + fileRes.fileExtension, byteArray);
                }
                row.isLoading = false;
            });
        } else {
            this.documentsService.readDocument({ documentId: doc.documentId }, true).subscribe((fileRes) => {
                if (fileRes) {
                    const byteArray = base64ToArrayBuffer(fileRes.base64File);
                    saveByteArray(doc.fileName!, byteArray);
                }
                row.isLoading = false;
            });
        }
    }

    //#endregion

    //#region Helpers

    cleanSearchString(searchString: string | null): string | null {
        if (!searchString || searchString.trim() === '') return null;
        return isNaN(+searchString.replace('-', '')) ? searchString : searchString.replace('-', '');
    }

    //#endregion
}
