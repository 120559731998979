import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Route, RouterLink, RouterLinkActive } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';

@Component({
    selector: 'lsr-navigation-item',
    standalone: true,
    imports: [NgClass, RouterLink, RouterLinkActive, LSRIconComponent, TranslocoPipe],
    templateUrl: './lsr-navigation-item.component.html',
    styleUrl: `lsr-navigation-item.component.scss`,
    host: { class: 'lsr-navigation-item' },
})
export class LSRNavigationItemComponent {
    @Input() route?: Route;
    @Input() identifier = '';
    @Input() displayChildren?: boolean = false;
    @Input() isChildItem?: boolean = false;

    routeChildrens() {
        return this.route?.children?.filter((x) => x.data?.['showInMenu']) || [];
    }

    itemClassNames() {
        return {
            [`${this.identifier}__item`]: true,
            [`${this.identifier}__item--has-children`]: this.route?.children && this.route?.children.length > 0,
        };
    }

    backTrigger(event: MouseEvent) {
        const htmlElement = event.target as HTMLElement;
        const navigationItem = htmlElement.closest('.mobile-navigation__item--active');
        const currentLink = navigationItem?.firstElementChild;
        const currentChevron = navigationItem?.firstElementChild?.nextElementSibling;
        const otherNavigationItemContainers = navigationItem?.parentElement?.parentElement?.children;

        navigationItem?.classList.toggle('mobile-navigation__item--active');
        currentLink?.removeAttribute('style');
        currentChevron?.removeAttribute('style');

        if (otherNavigationItemContainers) {
            Array.from(otherNavigationItemContainers).forEach((element) => {
                element.removeAttribute('style');
            });
        }
    }

    forwardTrigger(event: MouseEvent) {
        const htmlElement = event.target as HTMLElement;
        const navigationItem = htmlElement.parentElement;
        const navigationItemContainer = htmlElement?.parentElement?.parentElement;
        const currentLink = navigationItem?.firstElementChild;
        const currentChevron = htmlElement;
        const otherNavigationItemContainers = navigationItem?.parentElement?.parentElement?.children;

        navigationItem?.classList.toggle('mobile-navigation__item--active');

        if (otherNavigationItemContainers) {
            Array.from(otherNavigationItemContainers).forEach((element) => {
                (element as HTMLElement).style.display = 'none';
            });
        }

        if (currentLink) {
            (currentLink as HTMLElement).style.display = 'none';
        }
        currentChevron.style.display = 'none';
        navigationItemContainer?.removeAttribute('style');
    }
}
