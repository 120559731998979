import { NgClass } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';

@Component({
    selector: 'lsr-title',
    standalone: true,
    imports: [LSRButtonComponent, NgClass],
    templateUrl: `lsr-title.component.html`,
    styleUrl: `lsr-title.component.scss`,
    encapsulation: ViewEncapsulation.None,
})
export class LSRTitleComponent {
    @Input() text?: string = '';
    @Input() subtitle?: string;
    @Input() linkHref?: string;
    @Input() linkText?: string;
    @Input() size?: 'small' | 'medium' | 'large' = 'large';
}
