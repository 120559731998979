import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRLoaderComponent } from '../lsr-loader';

@Component({
    selector: 'lsr-button',
    standalone: true,
    imports: [RouterLink, LSRIconComponent, LSRLoaderComponent, NgClass],
    templateUrl: `lsr-button.component.html`,
    styleUrl: `lsr-button.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-button' },
})
export class LSRButtonComponent {
    @Input() modifier?: string = '';
    @Input() identifier?: string = '';
    @Input() text?: string;
    @Input() buttonType?: string = 'button'; // link or button
    @Input() type?: string; // button or submit
    @Input() title?: string;
    @Input() href?: string;
    @Input() routerLink?: string;
    @Input() icon?: string;
    @Input() iconColor?: string = '#912D27';
    @Input() isDisabled?: boolean;
    @Input() isLoading: boolean = false;

    @Output() clickEvent = new EventEmitter<string>();

    onClick() {
        this.clickEvent.emit();
    }
}
