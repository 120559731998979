import { Component, ViewEncapsulation } from '@angular/core';
import { LSRButtonComponent } from '../../lsr-button';
import { LSRIconComponent } from '../../lsr-icon/lsr-icon.component';
import { LSRDialogBaseComponent } from '../lsr-dialog-base/lsr-dialog-base.component';

@Component({
    selector: 'lsr-dialog-basic',
    standalone: true,
    imports: [LSRIconComponent, LSRButtonComponent],
    templateUrl: `lsr-dialog-basic.component.html`,
    styleUrls: ['../lsr-dialog-base/lsr-dialog-base.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-dialog' },
})
export class LSRDialogBasicComponent extends LSRDialogBaseComponent {}
