/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexMarkers,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';

export interface PersonalPensionSavingsChartOptions {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    title: ApexTitleSubtitle;
    dataLabels: ApexDataLabels;
    stroke: ApexStroke;
    tooltip: ApexTooltip;
    markers: ApexMarkers;
    fill: ApexFill;
    yaxis: ApexYAxis;
    annotations: ApexAnnotations;
    colors: any;
    toolbar: any;
    grid: any;
}

const ApexChartLocalesOptionsIs = {
    months: [
        'Janúar',
        'Febrúar',
        'Mars',
        'Apríl',
        'Maí',
        'Júní',
        'Júlí',
        'Ágúst',
        'September',
        'Október',
        'Nóvember',
        'Desember',
    ],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Maí', 'Jún', 'Júl', 'Ágú', 'Sep', 'Okt', 'Nóv', 'Des'],
    days: ['Sunnudagur', 'Mánudagur', 'Þriðjudagur', 'Miðvikudagur', 'Fimmtudagur', 'Föstudagur', 'Laugardagur'],
    shortDays: ['Sun', 'Mán', 'Þri', 'Mið', 'Fim', 'Fös', 'Lau'],
    toolbar: {
        download: 'Hlaða niður SVG',
        selection: 'Svæði',
        selectionZoom: 'Stækka svæði',
        zoomIn: 'Stækka',
        zoomOut: 'Minnka',
        pan: 'Áætlun',
        reset: 'Endurstilla aðdrátt',
    },
};

export const defaultChartOptions = {
    chart: {
        defaultLocale: 'en',
        locales: [
            {
                name: 'en',
                options: ApexChartLocalesOptionsIs,
            },
        ],
        selection: {
            enabled: false,
        },
        type: 'area',
        height: 300,
        animations: {
            speed: 400,
            animateGradually: {
                enabled: false,
            },
        },
        fontFamily: 'inherit',
        foreColor: 'inherit',
        width: '100%',
        toolbar: {
            show: false,
        },
        events: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dataPointMouseEnter: function (event: any) {
                event.fromElement.style.cursor = 'pointer';
            },
        },
        zoom: {
            enabled: false,
        },
    },
    dataLabels: {
        enabled: false,
    },
    markers: {
        size: 0,
    },
    xaxis: {
        type: 'datetime',
        tickAmount: 6,
    },
    yaxis: {
        min: 0,
        decimalsInFloat: 0,
        tickAmount: 6,
        forceNiceScale: true,
        labels: {
            formatter: function (val: string | number) {
                let value = val.toLocaleString();
                value = value.replace(/,/g, '.');
                return value;
            },
        },
    },
    fill: {
        type: 'solid',
        opacity: 0.4,
    },
} as PersonalPensionSavingsChartOptions;
