@if (loading) {
    <div class="loader-wrapper">
        <lsr-loader loadingText="{{ 'mpoPageLoadingText' | transloco }}"></lsr-loader>
    </div>
} @else {
    <section class="grid grid--4-columns">
        @if (pensionBenefitsLSRA) {
            <lsr-amount-card
                title="{{ 'mpoAcquiredBenefitsLSRACardTitle' | transloco }}"
                amountString="{{ pensionBenefitsLSRA.currentAcquiredPensionBenefits | translocoCurrency }}"
                subtitle="{{
                    'mppAcquiredBenefitsLSRACardSubtitle'
                        | transloco
                            : {
                                  age:
                                      (pensionBenefitsLSRA.retirementAge
                                      | translocoDecimal: { maximumFractionDigits: 0 }),
                              }
                }}"
                linkTitle="{{ 'mpoAcquiredBenefitsLSRACardLinkText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-amount-card>
        }
        @if (pensionBenefitsLSRB) {
            <lsr-amount-card
                title="{{ 'mpoAcquiredBenefitsLSRBCardTitle' | transloco }}"
                amountString="{{ pensionBenefitsLSRBCardData?.amountString }}"
                subtitle="{{ pensionBenefitsLSRBCardData?.description | transloco }}"
                linkTitle="{{ 'mpoAcquiredBenefitsLSRBCardLinkText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-amount-card>
        }
        @if (traditionalPersonalPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoPersonalPensionCardTitle' | transloco }}"
                amountString="{{ traditionalPersonalPensionCardData.amountString }}"
                subtitle="{{ traditionalPersonalPensionCardData.description }}"
                linkTitle="{{ 'mpoPersonalPensionCardLinkText' | transloco }}"
                linkHref="/sereign/hefdbundin-sereign"
            ></lsr-amount-card>
        }
        @if (specifiedPersonalPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoSpecifiedPersonalPensionCardTitle' | transloco }}"
                amountString="{{ specifiedPersonalPensionCardData.amountString }}"
                subtitle="{{ specifiedPersonalPensionCardData.description }}"
                linkTitle="{{ 'mpoSpecifiedPersonalPensionCardLinkText' | transloco }}"
                linkHref="/sereign/tilgreind-sereign-lsr"
            ></lsr-amount-card>
        }
        @if (latestPaymentsCommonPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoLatestPaymentsCommonPensionCardTitle' | transloco }}"
                amountString="{{ latestPaymentsCommonPensionCardData.amountString }}"
                subtitle="{{ latestPaymentsCommonPensionCardData.description }}"
                linkTitle="{{ 'mpoLatestPaymentsCardLinkText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-amount-card>
        }
        @if (latestPaymentsPersonalPensionCardData) {
            <lsr-amount-card
                title="{{ 'mpoLatestPaymentsPersonalPensionCardTitle' | transloco }}"
                amountString="{{ latestPaymentsPersonalPensionCardData.amountString }}"
                subtitle="{{ latestPaymentsPersonalPensionCardData.description }}"
                linkTitle="{{ 'mpoLatestPaymentsCardLinkText' | transloco }}"
                linkHref="/sereign/hefdbundin-sereign"
            ></lsr-amount-card>
        }
        @if (loansCardData) {
            <lsr-amount-card
                title="{{ 'mpoLoansCardTitle' | transloco }}"
                amountString="{{ loansCardData.amountString }}"
                subtitle="{{ loansCardData.description }}"
                linkTitle="{{ 'mpoLoanCardLinkText' | transloco }}"
                linkHref="/lan"
            ></lsr-amount-card>
        }
    </section>
    <section class="grid grid--2-columns">
        @if (pensionContributionsTableData) {
            <lsr-table-preview
                title="{{ 'mpoContributionsTableTitle' | transloco }}"
                [data]="pensionContributionsTableData"
                linkTitle="{{ 'mpoContributionsTableButtonText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-table-preview>
        }
        @if (personalPensionContributionsTableData) {
            <lsr-table-preview
                title="{{ 'mpoPersonalPensionContributionsTableTitle' | transloco }}"
                [data]="personalPensionContributionsTableData"
                linkTitle="{{ 'mpoPersonalPensionContributionsTableButtonText' | transloco }}"
                linkHref="/sereign"
            ></lsr-table-preview>
        }
        @if (pensionPaymentsTableData) {
            <lsr-table-preview
                title="{{ 'mpoPaymentsTableTitle' | transloco }}"
                [data]="pensionPaymentsTableData"
                linkTitle="{{ 'mpoPaymentsTableButtonText' | transloco }}"
                linkHref="/lifeyrir"
            ></lsr-table-preview>
        }
        @if (personalPensionPaymentsTableData) {
            <lsr-table-preview
                title="{{ 'mpoPersonalPensionPaymentsTableTitle' | transloco }}"
                [data]="personalPensionPaymentsTableData"
                linkTitle="{{ 'mpoPersonalPensionPaymentsTableButtonText' | transloco }}"
                linkHref="/sereign"
            ></lsr-table-preview>
        }
    </section>
}
