<lsr-title
    text="{{ 'mppPageTitle' | transloco }}"
    linkText="{{ 'mpaApplicationsAndFormsTitle' | transloco }}"
    linkHref="/lifeyrir/umsoknir"
></lsr-title>
@if (loading) {
    <div class="loader-wrapper">
        <lsr-loader loadingText="{{ 'mppPageLoadingText' | transloco }}"></lsr-loader>
    </div>
} @else {
    <section class="grid grid--3-columns">
        @if (userDetails?.funds?.memberOfLSRA && pensionBenefitsLSRA) {
            <lsr-amount-card
                title="{{ 'mppAcquiredBenefitsLSRACardTitle' | transloco }} - {{
                    (pensionBenefitsLSRA.pensionAcquisitionType === PensionAcquisitionTypes.EqualAcquisition
                        ? 'mppEqualAcquisition'
                        : 'mppAgeRelatedAcquisition'
                    ) | transloco
                }}"
                amountString="{{ pensionBenefitsLSRA.currentAcquiredPensionBenefits | translocoCurrency }}"
                subtitle="{{
                    'mppAcquiredBenefitsLSRACardSubtitle'
                        | transloco
                            : {
                                  age:
                                      (pensionBenefitsLSRA.retirementAge
                                      | translocoDecimal: { maximumFractionDigits: 0 }),
                              }
                }}"
                type="button"
                (buttonClicked)="downloadPensionOverviewSummaryLSRA()"
                [buttonLoading]="loadingPensionSummaryLSRA"
                buttonTitle="{{ 'mppAcquiredBenefitsLSRACardButtonText' | transloco }}"
                buttonIcon="pdf"
                [headerClickable]="true"
                (headerClicked)="scrollToSection('lsra-section')"
            ></lsr-amount-card>
        }
        @if (userDetails?.funds?.memberOfLSRA && latestPensionPaymentsLSRACardData) {
            <lsr-amount-card
                title="{{ 'mppLatestPensionPaymentLSRACardTitle' | transloco }}"
                amountString="{{ latestPensionPaymentsLSRACardData.amount | translocoCurrency }}"
                subtitle="{{ latestPensionPaymentsLSRACardData.description }}"
                [headerClickable]="true"
                (headerClicked)="scrollToSection('lsra-payments-table')"
            ></lsr-amount-card>
        }
        @if (userDetails?.funds?.memberOfLSRB && pensionBenefitsLSRB) {
            <lsr-amount-card
                title="{{ 'mppAcquiredBenefitsLSRBCardTitle' | transloco }}"
                amountString="{{ pensionBenefitsLSRBCardData?.amountString }}"
                subtitle="{{ pensionBenefitsLSRBCardData?.description | transloco }}"
                type="button"
                (buttonClicked)="downloadPensionOverviewSummaryLSRB()"
                [buttonLoading]="loadingPensionSummaryLSRB"
                buttonTitle="{{ 'mppAcquiredBenefitsLSRBCardButtonText' | transloco }}"
                buttonIcon="pdf"
                [headerClickable]="true"
                (headerClicked)="scrollToSection('lsrb-section')"
            ></lsr-amount-card>
        }
        @if (userDetails?.funds?.memberOfLSRB && latestPensionPaymentsLSRBCardData) {
            <lsr-amount-card
                title="{{ 'mppLatestPensionPaymentLSRBCardTitle' | transloco }}"
                amountString="{{ latestPensionPaymentsLSRBCardData.amount | translocoCurrency }}"
                subtitle="{{ latestPensionPaymentsLSRBCardData.description }}"
                [headerClickable]="true"
                (headerClicked)="scrollToSection('lsrb-payments-table')"
            ></lsr-amount-card>
        }
        @if (pensionBenefitsInOtherFundsIncludeAmounts) {
            <lsr-amount-card
                title="{{ 'mppAcquiredBenefitsOtherFundsCardTitle' | transloco }}"
                [isLoading]="pensionBenefitsInOtherFundsLoading"
                [amountString]="pensionBenefitsInOtherFundsCardData?.amount ?? 0 | translocoCurrency"
                subtitle="{{
                    'mppAcquiredBenefitsOtherFundsCardSubtitle'
                        | transloco: { age: pensionBenefitsInOtherFundsCardData?.age ?? 0 }
                }}"
                type="button"
                buttonTitle="{{ 'mppAcquiredBenefitsOtherFundsCardButtonText' | transloco }}"
                (buttonClicked)="scrollToSection('other-funds-table')"
                [headerClickable]="true"
                (headerClicked)="scrollToSection('other-funds-table')"
            >
                @if (pensionBenefitsInOtherFundsCardData?.showIncompleteDataMessage) {
                    <p class="other-funds-incomplete-data-message">
                        {{ 'mppAcquiredBenefitsOtherFundsCardIncompleteDataMessage' | transloco }}
                    </p>
                }
            </lsr-amount-card>
        }
    </section>

    @if (
        userDetails?.funds?.memberOfLSRA &&
        (estimatedFuturePensionBenefitsExamples ||
            (pensionPaymentsLSRA && pensionPaymentsLSRA.length > 0) ||
            (contributionsLSRA && contributionsLSRA.length > 0))
    ) {
        <div id="lsra-section" class="fund-section">
            @if (estimatedFuturePensionBenefitsExamples) {
                <lsr-table-box
                    id="estimated-future-pension-benefits-table"
                    title="{{ 'mppEstimatedFutureBenefitsTableTitle' | transloco }}"
                    text="{{ 'mppEstimatedFutureBenefitsTableText' | transloco }}"
                    linkHref="/lifeyrisreiknivel"
                    [data]="estimatedFuturePensionBenefitsExamplesTableData"
                >
                    <div id="table-footer">
                        <p class="body3">
                            {{
                                'mppEstimatedFutureBenefitsTableSubText'
                                    | transloco
                                        : {
                                              salary:
                                                  (estimatedFuturePensionBenefitsExamples.monthlySalary
                                                  | translocoDecimal),
                                          }
                            }}
                        </p>
                        <lsr-button
                            modifier="button--secondary"
                            text="{{
                                (estimatedFuturePensionbenefitsExamplesTableExpanded
                                    ? 'mppEstimatedFutureBenefitsButtonShowLessText'
                                    : 'mppEstimatedFutureBenefitsButtonShowMoreText'
                                ) | transloco
                            }}"
                            (click)="toggleEstimatedFuturePensionBenefitsExamplesTableExpansion()"
                        ></lsr-button>
                    </div>
                </lsr-table-box>
            }

            @if (pensionPaymentsLSRA && pensionPaymentsLSRA.length > 0) {
                <lsr-table-expandable
                    id="lsra-payments-table"
                    title="{{ 'mppPensionPaymentsLSRATableTitle' | transloco }}"
                    [data]="paymentsLSRATableData"
                    [innerTableMaxRows]="12"
                >
                </lsr-table-expandable>
            }
            @if (contributionsLSRA && contributionsLSRA.length > 0) {
                <lsr-table-expandable
                    title="{{ 'mppContributionsLSRATableTitle' | transloco }}"
                    [data]="contributionsLSRATableData"
                    [innerTableMaxRows]="12"
                ></lsr-table-expandable>
            }
        </div>
    }
    @if (
        userDetails?.funds?.memberOfLSRB &&
        ((pensionPaymentsLSRB && pensionPaymentsLSRB.length > 0) || (contributionsLSRB && contributionsLSRB.length > 0))
    ) {
        <div id="lsrb-section" class="fund-section">
            @if (pensionPaymentsLSRB && pensionPaymentsLSRB.length > 0) {
                <lsr-table-expandable
                    id="lsrb-payments-table"
                    title="{{ 'mppPensionPaymentsLSRBTableTitle' | transloco }}"
                    [data]="paymentsLSRBTableData"
                    [innerTableMaxRows]="12"
                >
                </lsr-table-expandable>
            }
            @if (contributionsLSRB && contributionsLSRB.length > 0) {
                <lsr-table-expandable
                    title="{{ 'mppContributionsLSRBTableTitle' | transloco }}"
                    [data]="contributionsLSRBTableData"
                    [innerTableMaxRows]="12"
                ></lsr-table-expandable>
            }
        </div>
    }
    @if (pensionBenefitsInOtherFunds) {
        <lsr-table-box
            id="other-funds-table"
            title="{{ 'mppAcquiredBenefitsOtherFundsTableTitle' | transloco }}"
            cornerLinkHref="https://www.lifeyrismal.is/"
            cornerLinkImage="lifeyrismal--200.png"
            cornerLinkTitle="Lífeyrismál.is"
            [data]="pensionBenefitsInOtherFundsTableData"
        >
            @if (!pensionBenefitsInOtherFundsIncludeAmounts) {
                <div id="table-footer">
                    <lsr-button
                        modifier="button--secondary"
                        text="{{ 'mppAcquiredBenefitsOtherFundsTableButtonText' | transloco }}"
                        (click)="openPensionBenefitsInOtherFundsTermsConfirmationDialog()"
                        [isLoading]="pensionBenefitsInOtherFundsLoading"
                    ></lsr-button>
                </div>
            }
        </lsr-table-box>
    }
}

<lsr-dialog-confirm
    headerTitle="{{ 'mppAcquiredBenefitsOtherFundsCardTitle' | transloco }}"
    headerIcon="padlock"
    confirmButtonText="{{ 'mppAcquiredBenefitsOtherFundsDialogConfirmButtonText' | transloco }}"
    cancelButtonText="{{ 'mppAcquiredBenefitsOtherFundsDialogCancelButtonText' | transloco }}"
>
    <div
        class="other-funds-confirmation-dialog-body"
        [innerHtml]="'mppAcquiredBenefitsOtherFundsDialogText' | transloco | sanitizeHtml"
    ></div>
</lsr-dialog-confirm>
