import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { CalendarModule } from 'primeng/calendar';

@Component({
    selector: 'lsr-datepicker',
    standalone: true,
    imports: [CalendarModule, FormsModule, LSRIconComponent],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LSRDatepickerComponent),
            multi: true,
        },
    ],
    templateUrl: `lsr-datepicker.component.html`,
    styleUrl: `lsr-datepicker.component.scss`,
    host: { class: 'lsr-datepicker' },
})
export class LSRDatepickerComponent implements ControlValueAccessor {
    @Input() id: string = '';
    @Input() placeholder: string = '';
    @Input() minDate: Date | null | undefined = null;
    @Input() maxDate: Date | null | undefined = null;
    @Input() label: string = '';
    @Input() isDisabled: boolean = false;
    @Input() isRequired?: boolean;
    @Input() isLabelHidden?: boolean;
    @Input() formControlName: string = '';

    date: Date | null = null;

    writeValue(value: Date): void {
        this.date = value;
    }

    registerOnChange(fn: (value: Date | null) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    onDateChange(event: Date): void {
        this.date = event;

        if (this.onChange) {
            this.onChange(this.date);
        }

        if (this.onTouched) {
            this.onTouched();
        }
    }

    preventInput(event: KeyboardEvent): void {
        event.preventDefault();
    }

    onChange?: (value: Date | null) => void;
    onTouched?: () => void;
}
