import { NgClass } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRPaginationComponent } from '../../lsr-pagination';
import { TableData } from '../lsr-table-interfaces';
import { isString } from '../lsr-table.utils';

@Component({
    selector: 'lsr-table-preview',
    standalone: true,
    imports: [LSRPaginationComponent, NgClass, LSRLoaderComponent, TranslocoModule, RouterLink],
    templateUrl: `lsr-table-preview.component.html`,
    styleUrl: `lsr-table-preview.component.scss`,
    host: { class: 'lsr-table-preview' },
})
export class LSRTablePreviewComponent implements OnInit, AfterViewInit {
    @Input() title?: string;
    @Input() linkTitle?: string;
    @Input() pageSize: number = 5;
    @Input() linkHref?: string;
    @Input() rowHoverText?: string;
    @Input() data?: TableData;
    currentPage: number = 1;
    totalPages: number = 1;
    dataDisplayed?: TableData;

    @Output() rowClick = new EventEmitter<any>();

    // Declare util function for use in component template
    isString = isString;

    constructor(
        public translocoService: TranslocoService,
        private elRef: ElementRef<HTMLElement>
    ) {}

    ngOnInit() {
        this.setPage(this.currentPage);
    }

    ngAfterViewInit() {
        this.centerTableButton();
        window.addEventListener('resize', this.centerTableButton.bind(this));
    }

    centerTableButton() {
        const tableContainer = this.elRef.nativeElement.querySelector<HTMLElement>('.table__table-container');
        const table = this.elRef.nativeElement.querySelector<HTMLElement>('table');
        const buttonContainer = this.elRef.nativeElement.querySelector<HTMLElement>('.table__button-container');

        if (tableContainer && table && buttonContainer) {
            if (tableContainer.offsetWidth < table.offsetWidth) {
                buttonContainer.style.width = `${tableContainer.offsetWidth}px`;
                buttonContainer.style.margin = '0 -32px';
            } else {
                buttonContainer.removeAttribute('style');
            }
        }
    }

    setPage(page: number) {
        this.currentPage = page;

        if (this.data) {
            this.totalPages = Math.ceil(this.data.rows.length / this.pageSize);

            const startIndex = (this.currentPage - 1) * this.pageSize;

            const endIndex = Math.min(startIndex + this.pageSize, this.data.rows.length);

            this.dataDisplayed = {
                headerTitles: this.data.headerTitles,
                rows: this.data.rows.slice(startIndex, endIndex),
            };
        }
    }

    rowClickEvent(row: any) {
        this.rowClick.emit(row);
    }
}
