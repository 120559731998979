import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
    selector: 'app-impersonate',
    template: '',
})
export class ImpersonateComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            const accessToken = params['accessToken'];
            const refreshToken = params['refreshToken'];
            // If the employee is already authenticated as a fund partner, log them out first before logging in via impersonation
            if (this.authService.authenticated) {
                this.authService.logout();
                this.processTokens(accessToken, refreshToken);
            } else {
                this.processTokens(accessToken, refreshToken);
            }
        });
    }

    private processTokens(accessToken: string, refreshToken: string) {
        // Refresh the access token to set all necessary properties
        if (accessToken && refreshToken) {
            this.authService.accessToken = accessToken;
            this.authService.refreshToken = refreshToken;
            this.authService.refreshAccessToken().subscribe({
                next: () => {
                    this.router.navigate(['']);
                },
                error: () => {
                    this.router.navigate(['login']);
                },
            });
        }
    }
}
