@if (label.length > 0) {
    <div class="label {{ isLabelHidden ? 'label--hidden' : '' }}">
        {{ label }}
        @if (isRequired) {
            <span class="label__asterisk">*</span>
        }
    </div>
}
<p-calendar
    class="datepicker"
    inputId="{{ id }}"
    placeholder="{{ placeholder }}"
    dateFormat="dd.mm.yy"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [showIcon]="true"
    [iconDisplay]="'input'"
    [showTransitionOptions]="'0ms'"
    [hideTransitionOptions]="'0ms'"
    stepYearPicker="12"
    [(ngModel)]="date"
    (onSelect)="onDateChange($event)"
    [disabled]="isDisabled"
    (keydown)="preventInput($event)"
    required="isRequired"
>
    <ng-template pTemplate="inputicon">
        <lsr-icon icon="calendar"></lsr-icon>
    </ng-template>
</p-calendar>
