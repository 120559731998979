<section class="table-box">
    <h2 class="table-box__title headline4" tabindex="0">
        {{ title }}
        @if (subtitle) {
            <p class="table-box__title-subtitle">{{ subtitle }}</p>
        }
        @if (cornerLinkHref && cornerLinkImage) {
            <a class="table-box__title-logo" href="{{ cornerLinkHref }}" target="_blank">
                <img src="assets/images/{{ cornerLinkImage }}" alt="{{ cornerLinkTitle }}" />
            </a>
        }
    </h2>
    @if (text) {
        <p class="table-box__text body1" tabindex="0">{{ text }}</p>
    }
    @if (data) {
        <div class="table-box__table">
            <table>
                <thead>
                    <tr>
                        @for (headerTitle of data.headerTitles; track headerTitle) {
                            <th tabindex="0">
                                {{ headerTitle | transloco }}
                            </th>
                        }
                    </tr>
                </thead>
                <tbody>
                    @for (row of data.rows; track row) {
                        <tr class="{{ row.modifiers }}">
                            @for (rowCell of row.rowTitles; track rowCell) {
                                <td
                                    [attr.colspan]="!isString(rowCell) ? rowCell.options?.colSpan : 1"
                                    class="{{ !isString(rowCell) ? rowCell.options?.modifiers : '' }}"
                                >
                                    {{
                                        isString(rowCell)
                                            ? (rowCell | transloco)
                                            : (rowCell.text | transloco: rowCell?.options?.translationParams)
                                    }}
                                </td>
                            }
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    }
    <ng-content select="#table-footer"></ng-content>
</section>
