@if (!savings && (!contributions || contributions.length === 0) && (!payments || payments.length === 0)) {
    <div class="no-data">
        <div class="no-data--title">
            <h1 class="headline2">{{ 'mpppTraditionalPersonalPensionNoDataTitle' | transloco }}</h1>
            <p class="headline4 no-data--subtitle">{{ 'mpppTraditionalPersonalPensionNoDataSubtitle' | transloco }}</p>
        </div>
        <p class="body2 no-data--text">{{ 'mpppTraditionalPersonalPensionNoDataText' | transloco }}</p>
        <lsr-button
            modifier="button--secondary"
            text="{{ 'mpppTraditionalPersonalPensionNoDataButtonText' | transloco }}"
            (click)="openApplication()"
        ></lsr-button>
    </div>
}

@if (savings) {
    <section class="grid grid--1-column">
        <lsr-amount-card
            title="{{ 'mpppRegularSavingsBalanceCardTitle' | transloco }}"
            amountString="{{ savings.balance | translocoCurrency }}"
            subtitle="{{ getBalanceCardSubtitle() }}"
            type="button"
            buttonTitle="{{ 'mpppRegularSavingsBalanceCardButtonText' | transloco }}"
            buttonIcon="pdf"
            (buttonClicked)="downloadPersonalPensionOverviewSummary()"
            [buttonLoading]="loadingPersonalPensionSummary"
        >
        </lsr-amount-card>
    </section>
    <lsr-table-box
        title="{{ 'mpppInvestmentPlanTableTitle' | transloco }}"
        subtitle="{{ 'mpppInvestmentPlanTableText' | transloco: { date: currentDateString } }}"
        [data]="investmentPlanTableData"
    >
        @if (chartOptions) {
            <div id="table-footer">
                <apx-chart
                    #interestChart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [xaxis]="chartOptions.xaxis"
                    [colors]="chartOptions.colors"
                    [yaxis]="chartOptions.yaxis"
                    [dataLabels]="chartOptions.dataLabels"
                    [markers]="chartOptions.markers"
                    [stroke]="chartOptions.stroke"
                    [grid]="chartOptions.grid"
                    [tooltip]="chartOptions.tooltip"
                    [fill]="chartOptions.fill"
                    [annotations]="chartOptions.annotations"
                >
                </apx-chart>
            </div>
        }
    </lsr-table-box>
}
@if (contributions && contributions.length > 0) {
    <lsr-table-expandable
        title="{{ 'mpppTableTitleContributions' | transloco }}"
        [data]="contributionsTableData"
    ></lsr-table-expandable>
}

@if (payments && payments.length > 0) {
    <lsr-table-expandable
        title="{{ 'mpppTableTitlePayouts' | transloco }}"
        [data]="paymentsTableData"
    ></lsr-table-expandable>
}
