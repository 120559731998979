import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRInputTextComponent } from '@lsr/ui-components/lsr-input/lsr-input-text';
import { getRandomInt } from '../../../helpers/randomNumber';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: 'login.component.html',
    styleUrl: 'login.component.scss',
    imports: [LSRButtonComponent, LSRInputTextComponent, ReactiveFormsModule, NgClass, TranslocoModule],
})
export class LoginComponent implements OnInit {
    verificationCode: string | null = null;
    isAuthenticating = false;
    errorMessage: string | null = null;
    phonenumberValidators = [Validators.required, Validators.minLength(7)];
    ssnValidators = [Validators.required, Validators.minLength(10)];

    form = {} as FormGroup<{
        authenticationMethod: FormControl<string | null>;
        phonenumber: FormControl<string | null>;
        ssn: FormControl<string | null>;
    }>;

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router,
        public translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            authenticationMethod: ['sim', [Validators.required]],
            phonenumber: new FormControl<string | null>(null, [Validators.required, Validators.minLength(7)]),
            ssn: new FormControl<string | null>(null, []),
        });

        this.form.controls.authenticationMethod.valueChanges.subscribe((value) => {
            switch (value) {
                case 'sim':
                    this.form.controls.phonenumber.setValidators(this.phonenumberValidators);
                    this.form.patchValue({ ssn: null });
                    this.form.controls.ssn.setValidators([]);
                    break;
                case 'app':
                    this.form.controls.ssn.setValidators(this.ssnValidators);
                    this.form.patchValue({ phonenumber: null });
                    this.form.controls.phonenumber.setValidators([]);
                    break;
            }
            this.form.controls.phonenumber.updateValueAndValidity();
            this.form.controls.ssn.updateValueAndValidity();
        });

        this.form.valueChanges.subscribe(() => {
            // Reset error message
            if (this.errorMessage) {
                this.errorMessage = null;
            }
        });
    }

    toggleLoginMethod(e: MouseEvent) {
        const el = e.target as HTMLButtonElement;
        this.form.patchValue({
            authenticationMethod: el.id,
        });
    }

    onSubmit(e: SubmitEvent) {
        e.preventDefault();

        if (!this.form.valid) {
            return;
        }

        this.isAuthenticating = true;
        this.errorMessage = null;

        const hashString = getRandomInt(1000, 9999).toString();
        this.authService.getVerificationCode(hashString).subscribe((verificationCodeRes) => {
            this.verificationCode = verificationCodeRes;
            this.authService
                .login({
                    hashString: hashString,
                    phonenumber: this.form.value.phonenumber!,
                    socialSecurityNumber: this.form.value.ssn!,
                })
                .subscribe({
                    next: (res) => {
                        if (res) {
                            this.router.navigateByUrl(this.route.snapshot.queryParams['redirectUrl'] || '');
                        }
                    },
                    error: (err) => {
                        this.errorMessage = err.error.errorItem.friendlyMessage;
                    },
                })
                .add(() => {
                    this.isAuthenticating = false;
                });
        });
    }
}
