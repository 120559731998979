<pdf-viewer
    id="id"
    [src]="src"
    [original-size]="originalSize"
    [show-all]="showAll"
    [fit-to-page]="fitToPage"
    [zoom]="zoom"
    [zoom-scale]="'page-width'"
    [stick-to-page]="stickToPage"
    [render-text]="renderText"
    [external-link-target]="externalLinkTarget"
    [autoresize]="autoresize"
    [show-borders]="showBorders"
    style="display: block; width: 100%; height: 100%"
>
</pdf-viewer>
