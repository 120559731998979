import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators, } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { TAX_DETAILS_VALUE } from '@lsr/constants/taxDetailsValue.constants';
import { LSRBottomBarComponent } from '@lsr/ui-components/lsr-bottom-bar';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRDatepickerComponent } from '@lsr/ui-components/lsr-datepicker';
import { LSRDialogConfirmComponent } from '@lsr/ui-components/lsr-dialog/lsr-dialog-confirm/lsr.dialog-confirm.component';
import { LSRInputCheckboxComponent } from '@lsr/ui-components/lsr-input/lsr-input-checkbox/lsr-input-checkbox.component';
import { LSRInputRadioComponent } from '@lsr/ui-components/lsr-input/lsr-input-radio/lsr-input-radio.component';
import { LSRInputTextComponent } from '@lsr/ui-components/lsr-input/lsr-input-text';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRSelectComponent, SelectOption } from '@lsr/ui-components/lsr-select';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { ValidateEmail } from '@lsr/utils/validators';
import { MessageService } from 'primeng/api';
import { Observable, Subscription, debounceTime, first, forkJoin, tap } from 'rxjs';
import { formatDate } from '../../../helpers/formatDate';
import { CanComponentDeactivate, CanDeactivateType } from '../../../helpers/guards/canDeactivateGuard';
import { ExposedBankAccountsRes } from '../../../interfaces/responses/exposed/exposedBankAccountsRes';
import { ExposedMeRes } from '../../../interfaces/responses/exposed/exposedMeRes';
import { ExposedTaxDetailsRes, ExposedKriaPersonalTaxCreditList } from '../../../interfaces/responses/exposedTaxDetailsRes';
import { ExposedlTaxConfigs } from '../../../interfaces/responses/exposedlTaxConfigs';
import { BankAccountsService } from '../../../services/bankAccounts/bankAccounts.service';
import { PensionService } from '../../../services/pension/pension.service';
import { UserService } from '../../../services/user/user.service';
import { TableData, TableRowData  } from '@lsr/ui-components/lsr-table/lsr-table-interfaces';
import { LSRTableExpandableComponent } from '@lsr/ui-components/lsr-table/lsr-table-expandable';
import { SanitizeHtmlPipe } from '@lsr/pipes/sanitizeHTML.pipe';

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [
        FormsModule,
        LSRDatepickerComponent,
        LSRTitleComponent,
        LSRInputTextComponent,
        LSRButtonComponent,
        LSRSelectComponent,
        ReactiveFormsModule,
        LSRBottomBarComponent,
        LSRLoaderComponent,
        LSRDialogConfirmComponent,
        CommonModule,
        LSRInputCheckboxComponent,
        LSRInputRadioComponent,
        TranslocoModule,
        LSRTableExpandableComponent,
        SanitizeHtmlPipe,
    ],
    templateUrl: 'settings.component.html',
    styleUrl: './settings.component.scss',
    host: { class: 'main__content' },
})
export class SettingsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    @ViewChild('unmountConfirm') unmountConfirmDialog!: LSRDialogConfirmComponent;
    userDetailsSubscription: Subscription | null = null;
    userDetails: ExposedMeRes | null = null;

    contactForm = {} as FormGroup<{
        name: FormControl<string | null>;
        ssn: FormControl<string | null>;
        phone: FormControl<string | null>;
        email: FormControl<string | null>;
    }>;
    emailErrorMessage: string = '';
    otherValueErrorMessage: string = '';

    bankForm = {} as FormGroup<{
        bank: FormControl<string | null>;
        ledger: FormControl<string | null>;
        account: FormControl<string | null>;
    }>;

    bankAccountRes: ExposedBankAccountsRes | null = null;
    validatingBankAccount = false;
    validBankAccount = true;
    showBankAccountUpdateAlertMessage = false;

    taxBracketsForm = {} as FormGroup<{
        taxBrackets: FormControl<string | null>;
        taxBracketsOther: FormControl<number | null>;
    }>;

    taxCreditForm = {} as FormGroup<{
        personalTaxCredit: FormControl<string | null>;
        personalTaxCreditValue: FormControl<number | null>;
        personalTaxCreditValidFrom: FormControl<Date | null>;
        addNewPersonalTaxCredit: FormControl<boolean | null>;
    }>;

    taxCreditSpouseForm = {} as FormGroup<{
        spousePersonalTaxCredit: FormControl<string | null>;
    }>;

    taxDetailsRes: ExposedTaxDetailsRes | null = null;
    taxConfigsRes: ExposedlTaxConfigs | null = null;
    dateSelectionList: SelectOption[] = [];
    updateTaxCredit = false;

    TaxDetailsValue = TAX_DETAILS_VALUE;

    saving = false;

    personalTaxCreditTableData: TableData = { headerTitles: [], rows: [] };
    spousePersonalTaxCreditTableData: TableData = { headerTitles: [], rows: [] };

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private bankAccountsService: BankAccountsService,
        private pensionService: PensionService,
        public translocoService: TranslocoService,
        private messageService: MessageService
    ) {}

    // Handle browser refresh or tab close event
    @HostListener('window:beforeunload')
    handleBeforeUnload = () => this.bankForm.pristine && this.contactForm.pristine;

    // Triggered when navigating to other app route
    canDeactivate = (): CanDeactivateType => {
        if (!this.bankForm.pristine || !this.contactForm.pristine) {
            this.unmountConfirmDialog.open();
            return new Observable<boolean>((observer) => {
                this.unmountConfirmDialog.dialogClosed.pipe(first()).subscribe((confirmed) => {
                    observer.next(confirmed);
                    observer.complete();
                });
            });
        }
        return true;
    };

    ngOnDestroy(): void {
        this.userDetailsSubscription?.unsubscribe();
    }

    ngOnInit() {
        this.contactForm = this.fb.group({
            name: [{ value: '', disabled: true }, [Validators.required]],
            ssn: [{ value: '', disabled: true }, [Validators.required]],
            phone: [{ value: '', disabled: true }, [Validators.required]],
            email: ['', [Validators.required, ValidateEmail]],
        });

        this.bankForm = this.fb.group({
            bank: ['', [Validators.required]],
            ledger: ['', [Validators.required]],
            account: ['', [Validators.required]],
        });

        this.taxBracketsForm = this.fb.group({
            taxBrackets: [TAX_DETAILS_VALUE.BRACKET_1, [Validators.required]],
            taxBracketsOther: new FormControl<number | null>(null),
        });

        this.taxCreditSpouseForm = this.fb.group({
            spousePersonalTaxCredit: [TAX_DETAILS_VALUE.NO_SPOUSE_PERSONAL_TAX_CREDIT],
        });

        this.userDetailsSubscription = this.userService.currentUser.subscribe((user) => {
            this.userDetails = user;

            // Only update if the form is pristine to prevent updating values while the user is updating the form
            if (user && this.contactForm.pristine) {
                this.contactForm.patchValue({
                    name: user?.name,
                    email: user?.email,
                    phone: user?.phone,
                    ssn: user?.ssn,
                });
                this.contactForm.markAsPristine();
            }
        });

        this.getBankAccount();

        this.contactForm.valueChanges.subscribe(() => {
            if (this.emailErrorMessage) {
                this.emailErrorMessage = '';
            }
        });
        this.bankForm.valueChanges
            .pipe(
                // Bank account is no longer valid if the value is changed until it is revalidated
                tap(() => (this.validBankAccount = false)),
                debounceTime(300)
            )
            .subscribe(() => this.validateBankAccount());
    }

    getBankAccount = (): void => {
        this.bankAccountsService.getPensionBankAccounts().subscribe((bankAccount) => {
            this.bankAccountRes = bankAccount;
            if (bankAccount && this.bankForm.pristine) {
                this.bankForm.patchValue(
                    {
                        account: bankAccount?.bankAccount?.toString() ?? '',
                        bank: bankAccount?.bank?.toString() ?? '',
                        ledger: bankAccount?.ledger?.toString() ?? '',
                    },
                    { emitEvent: false }
                );
                this.bankForm.markAsPristine();
            }
        });

        this.initTaxCreditForm();
        this.initTaxFormsData();

        this.contactForm.valueChanges.subscribe(() => {
            if (this.emailErrorMessage) {
                this.emailErrorMessage = '';
            }
        });
        this.bankForm.valueChanges.pipe(debounceTime(300)).subscribe(() => this.validateBankAccount());
        
        this.taxBracketsValueChanges();
        this.taxCreditValueChanges();
        
        // Check if the tax details forms need to be due to payroll process is in progress in the end of month.
        this.isTaxDetailFormsToBeDisabled();
    };

    isTaxDetailFormsToBeDisabled() {
        this.pensionService.getTaxConfigs().subscribe((taxConfigs) => {
            if (!taxConfigs.isDateValidToUpdateTaxDetails) {
                this.disableTaxDetailsForm();
            }
        });
    }

    taxBracketsValueChanges() {
        this.taxBracketsForm.controls.taxBrackets.valueChanges.subscribe((value) => {
            if (value == TAX_DETAILS_VALUE.OTHER) {
                this.setTaxBracketsOtherValidators();

                // Mark form as pristine in cases when the user changes the values in the form and selects again the initial values for other salary.
                if (
                    this.taxDetailsRes?.taxBrackets?.salaryOther == this.taxBracketsForm.value.taxBracketsOther &&
                    this.taxBracketsForm.value.taxBracketsOther! > 0
                ) {
                    this.taxBracketsForm.markAsPristine();
                }
            } else {
                this.removeTaxBracketsOtherValidators();

                // Mark form as pristine in cases when the user changes the values in the form and selects again the initial values.
                if (value == this.taxDetailsRes?.taxBrackets?.taxBracket) {
                    this.taxBracketsForm.markAsPristine();
                }
            }
        });
    }

    taxCreditValueChanges() {
        this.taxCreditForm.controls.personalTaxCredit.valueChanges.subscribe((value) => {
            // Set addNewPersonalTaxCredit value and/or set form control to display save button and/or show fields for update
            if (
                value == TAX_DETAILS_VALUE.NO_PERSONAL_TAX_CREDIT &&
                this.taxCreditForm.value.addNewPersonalTaxCredit &&
                this.taxDetailsRes?.personalTaxCredit?.usePersonalTaxCredit
            ) {
                this.taxCreditForm.patchValue({ addNewPersonalTaxCredit: false });
                this.taxCreditForm.markAsDirty();
            } else if (
                this.taxDetailsRes?.personalTaxCredit?.usePersonalTaxCredit &&
                value == TAX_DETAILS_VALUE.PERSONAL_TAX_CREDIT
            ) {
                this.taxCreditForm.markAsPristine();
            } else if (
                !this.taxDetailsRes?.personalTaxCredit?.usePersonalTaxCredit &&
                value == TAX_DETAILS_VALUE.NO_PERSONAL_TAX_CREDIT
            ) {
                this.taxCreditForm.patchValue({ addNewPersonalTaxCredit: false });
                this.taxCreditForm.markAsPristine();
            } else if (
                !this.taxDetailsRes?.personalTaxCredit?.usePersonalTaxCredit &&
                value == TAX_DETAILS_VALUE.PERSONAL_TAX_CREDIT
            ) {
                this.taxCreditForm.patchValue({ addNewPersonalTaxCredit: true });
            }
        });

        // Set validators for update fields
        this.taxCreditForm.controls.addNewPersonalTaxCredit.valueChanges.subscribe((value) => {
            if (value) {
                this.setTaxCreditValidators();
            } else {
                this.removeTaxCreditValidators();
                this.taxCreditForm.markAsPristine();
            }
        });
    }

    initTaxCreditForm() {
        this.taxCreditForm = this.fb.group({
            personalTaxCredit: [TAX_DETAILS_VALUE.NO_PERSONAL_TAX_CREDIT],
            personalTaxCreditValue: [null as number | null],
            personalTaxCreditValidFrom: [null as Date | null],
            addNewPersonalTaxCredit: [false],
        });
    }

    initTaxFormsData() {
        this.initDropdowns();
        this.pensionService.getTaxDetails().subscribe((taxDetails) => {
            this.taxDetailsRes = taxDetails;
            if (taxDetails && this.taxBracketsForm.pristine) {
                this.taxBracketsForm.patchValue(
                    {
                        taxBrackets: taxDetails?.taxBrackets?.taxBracket ?? TAX_DETAILS_VALUE.BRACKET_1,
                        taxBracketsOther: taxDetails?.taxBrackets?.salaryOther ?? null,
                    },
                    { emitEvent: false }
                );
                if (this.taxDetailsRes?.taxBrackets != null && this.taxDetailsRes.taxBrackets.salaryOther > 0) {
                    this.taxBracketsForm.patchValue({ taxBrackets: TAX_DETAILS_VALUE.OTHER });
                    this.setTaxBracketsOtherValidators();
                }
                this.taxBracketsForm.markAsPristine();
            }
            if (
                taxDetails &&
                taxDetails.personalTaxCredit &&
                taxDetails.personalTaxCredit.usePersonalTaxCredit &&
                this.taxCreditForm.pristine
            ) {
                this.taxCreditForm.patchValue({
                    personalTaxCredit: TAX_DETAILS_VALUE.PERSONAL_TAX_CREDIT,
                });
                if (
                    this.taxDetailsRes?.personalTaxCredit?.personalTaxCreditList &&
                    this.taxDetailsRes.personalTaxCredit.personalTaxCreditList.length > 0
                ) {
                    this.initPersonalTaxCreditTableData();
                }
                this.taxCreditForm.markAsPristine();
            }
            if (
                taxDetails &&
                taxDetails.spousePersonalTaxCredit &&
                taxDetails.spousePersonalTaxCredit.usePersonalTaxCredit &&
                this.taxCreditSpouseForm.pristine
            ) {
                this.taxCreditSpouseForm.patchValue({
                    spousePersonalTaxCredit: TAX_DETAILS_VALUE.SPOUSE_PERSONAL_TAX_CREDIT,
                });
                this.taxCreditSpouseForm.markAsPristine();

                this.initSpousePersonalTaxCreditTableData(taxDetails);
            }
        });
    }

    initPersonalTaxCreditTableData() {
        this.personalTaxCreditTableData = { headerTitles: [], rows: [] };
        this.personalTaxCreditTableData.headerTitles = [
            'mpsPersonalTaxCreditPercentage',
            'mpsPersonalTaxCreditValidFrom',
            'mpsPersonalTaxCreditValidTo'
        ];

        this.taxDetailsRes?.personalTaxCredit?.personalTaxCreditList?.forEach((element: ExposedKriaPersonalTaxCreditList) => {
            let validTo = formatDate(element.validTo?.toString(), false)
            if (element.validTo == null) {
                validTo =  "";
            }
            this.personalTaxCreditTableData.rows.push({
                rowTitles: [element.taxCreditPercentage.toString() + "%", formatDate(element.validFrom.toString(), false), validTo]
            } as TableRowData)
        }); 
    }

    initSpousePersonalTaxCreditTableData(taxDetails: ExposedTaxDetailsRes) {
        this.spousePersonalTaxCreditTableData.headerTitles = [
            'mpsPersonalTaxCreditPercentage',
            'mpsPersonalTaxCreditValidFrom',
            'mpsPersonalTaxCreditValidTo'
        ];

        let validToValue = formatDate(taxDetails!.spousePersonalTaxCredit!.validTo?.toString(), false);
        if (taxDetails!.spousePersonalTaxCredit!.validTo == null) {
            validToValue = "";
        }

        this.spousePersonalTaxCreditTableData.rows.push({
            rowTitles: [taxDetails.spousePersonalTaxCredit?.taxCreditPercentage!.toString() + "%", formatDate(taxDetails.spousePersonalTaxCredit!.validFrom.toString(), false), validToValue ]
        } as TableRowData);
    }

    initDropdowns() {
        this.pensionService.getTaxConfigs().subscribe((taxConfigs) => {
            this.taxConfigsRes = taxConfigs;
            this.dateSelectionList = [];
            this.taxConfigsRes.dateSelectionList.forEach((x) => {
                this.dateSelectionList.push({
                    value: x,
                    title: formatDate(x.toString(), false),
                });
            });
        });
    }

    initTaxCreditFormData() {
        this.initDropdowns();
        this.pensionService.getTaxDetails().subscribe((taxDetails) => {
            this.taxDetailsRes = taxDetails;
            if (
                taxDetails &&
                taxDetails.personalTaxCredit &&
                taxDetails.personalTaxCredit.usePersonalTaxCredit &&
                this.taxCreditForm.pristine
            ) {
                this.taxCreditForm.patchValue({
                    personalTaxCredit: TAX_DETAILS_VALUE.PERSONAL_TAX_CREDIT,
                });
                if (
                    this.taxDetailsRes?.personalTaxCredit?.personalTaxCreditList &&
                    this.taxDetailsRes.personalTaxCredit.personalTaxCreditList.length > 0
                ) {
                    this.initPersonalTaxCreditTableData();
                }
                this.taxCreditForm.markAsPristine();
            }
        });
    }

    setTaxBracketsOtherValidators() {
        this.taxBracketsForm.controls.taxBracketsOther.setValidators([Validators.required, Validators.min(1)]);
        this.taxBracketsForm.controls.taxBracketsOther.updateValueAndValidity();
    }

    removeTaxBracketsOtherValidators() {
        this.taxBracketsForm.controls.taxBracketsOther.clearValidators();
        this.taxBracketsForm.controls.taxBracketsOther.updateValueAndValidity();
    }

    setTaxCreditValidators() {
        this.taxCreditForm.controls.personalTaxCreditValue.setValidators([
            Validators.required,
            Validators.min(0),
            Validators.max(100),
        ]);
        this.taxCreditForm.controls.personalTaxCreditValue.updateValueAndValidity();
        this.taxCreditForm.controls.personalTaxCreditValidFrom.setValidators([Validators.required]);
        this.taxCreditForm.controls.personalTaxCreditValidFrom.updateValueAndValidity();
    }

    removeTaxCreditValidators() {
        this.taxCreditForm.controls.personalTaxCreditValue.clearValidators();
        this.taxCreditForm.controls.personalTaxCreditValue.updateValueAndValidity();
        this.taxCreditForm.controls.personalTaxCreditValidFrom.clearValidators();
        this.taxCreditForm.controls.personalTaxCreditValidFrom.updateValueAndValidity();
    }

    taxDescription() {
        window.open('https://lsr.is/lifeyrir/a-deild/skattlagning/', "_blank")
    }

    validateBankAccount = (): void => {
        const { bank, ledger, account } = this.bankForm.value;

        if (!bank || !ledger || !account) {
            return;
        }
        this.validatingBankAccount = true;

        this.bankAccountsService
            .validateBankAccount({
                bank: parseInt(bank),
                ledger: parseInt(ledger),
                bankAccount: parseInt(account),
            })
            .subscribe((valid) => {
                this.validBankAccount = valid;

                if (
                    this.bankAccountRes?.bank !== parseInt(bank) ||
                    this.bankAccountRes.ledger !== parseInt(ledger) ||
                    this.bankAccountRes.bankAccount !== parseInt(account)
                ) {
                    this.showBankAccountUpdateAlertMessage = valid;
                }
            })
            .add(() => {
                this.validatingBankAccount = false;
            });
    };

    canSave = (): boolean => {
        if (this.contactForm.dirty && this.contactForm.valid) {
            return true;
        }

        if (this.bankForm.dirty && this.bankForm.valid && this.validBankAccount) {
            return true;
        }

        if (this.taxBracketsForm.dirty && this.taxBracketsForm.valid) {
            return true;
        }

        if (this.taxCreditForm.dirty && this.taxCreditForm.valid) {
            return true;
        }

        if (this.saving) {
            return false;
        }

        return false;
    };

    save = (): void => {
        // Do nothing if no form section is valid
        if (!this.contactForm.valid && !this.bankForm.valid && !this.taxBracketsForm.valid && !this.taxCreditForm.valid) {
            return;
        }

        this.saving = true;

        const observables = {} as {
            contact: Observable<boolean>;
            bankAccount: Observable<boolean>;
            taxBrackets: Observable<boolean>;
            taxCredit: Observable<boolean>;
        };
        if (this.contactForm.valid && this.contactForm.dirty) {
            observables.contact = this.userService.updateUserPublic({
                kennitala: this.userDetails!.ssn,
                netfang: this.contactForm.value.email!,
            });
        }
        if (this.bankForm.valid && this.bankForm.dirty) {
            observables.bankAccount = this.bankAccountsService.updatePensionBankAccounts({
                bank: parseInt(this.bankForm.value.bank!),
                ledger: parseInt(this.bankForm.value.ledger!),
                bankAccount: parseInt(this.bankForm.value.account!),
            });
        }
        if (this.taxBracketsForm.valid && this.taxBracketsForm.dirty) {
            let taxBracketValue = "";
            let salaryOtherValue = 0;
            if (this.taxBracketsForm.value.taxBrackets == TAX_DETAILS_VALUE.OTHER) {
                salaryOtherValue = this.taxBracketsForm.value.taxBracketsOther!;
            }
            else {
                taxBracketValue = this.taxBracketsForm.value.taxBrackets!;
            }
            observables.taxBrackets = this.pensionService.updateTaxBracket({
                taxBracket: taxBracketValue,
                salaryOther: salaryOtherValue
            });
        }
        if (this.taxCreditForm.valid && this.taxCreditForm.dirty) {
            let percentage = 0;
            let percentageValidFrom = null;
            if (this.taxCreditForm.value.personalTaxCredit == TAX_DETAILS_VALUE.PERSONAL_TAX_CREDIT) {
                percentage = this.taxCreditForm.value.personalTaxCreditValue!;
                percentageValidFrom = this.taxCreditForm.value.personalTaxCreditValidFrom!
            }
            else if (this.taxCreditForm.value.personalTaxCredit == TAX_DETAILS_VALUE.NO_PERSONAL_TAX_CREDIT) {
                percentage = 0;
            }
            this.updateTaxCredit = true;
            observables.taxCredit = this.pensionService.updatePersonalTaxCredits({
                taxCreditPercentage: percentage,
                taxCreditPercentageValidFrom: percentageValidFrom,
            });
        }

        this.disableForm();

        forkJoin(observables)
            .subscribe({
                next: () => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Upplýsingar hafa verið uppfærðar',
                    });
                },
                error: () => {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Tókst ekki að uppfæra upplýsingar',
                    });
                },
            })
            .add(() => {
                if (this.updateTaxCredit) {
                    this.resetTaxCreditForm();
                }
                this.saving = false;
                this.resetForm();
            });
    };

    disableForm = () => {
        this.contactForm.disable({ emitEvent: false });
        this.bankForm.disable({ emitEvent: false });
        this.disableTaxDetailsForm();
    };

    disableTaxDetailsForm = () => {
        try {
            this.taxBracketsForm.disable({ emitEvent: false });
            this.taxCreditForm.disable({ emitEvent: false });
        } catch {
            console.log('Tax form has not been initalized.');
        }
    };

    resetTaxCreditForm() {
        this.initTaxCreditForm();
        this.initTaxCreditFormData();
        this.taxCreditValueChanges();
    }

    // Enable and mark form controls as pristine
    resetForm = () => {
        this.contactForm.controls.email.enable({ emitEvent: false });
        this.contactForm.markAsPristine();
        this.showBankAccountUpdateAlertMessage = false;
        this.bankForm.enable({ emitEvent: false });
        this.bankForm.markAsPristine();
        this.getBankAccount();
        this.taxBracketsForm.enable({ emitEvent: false });
        this.taxBracketsForm.markAsPristine();
        this.taxCreditForm.controls.addNewPersonalTaxCredit.enable( { emitEvent: false });
        this.taxCreditForm.controls.personalTaxCreditValue.enable( { emitEvent: false });
        this.taxCreditForm.controls.personalTaxCreditValidFrom.enable( { emitEvent: false });
        this.taxCreditForm.markAsPristine();
    };

    setContactValidationMessages = (): void => {
        const { email: emailControl } = this.contactForm.controls;
        if (emailControl.errors) {
            if (emailControl.errors?.['required']) {
                this.emailErrorMessage = 'Visamlegast sláðu inn netfang';
            } else if (emailControl.errors?.['validEmail']) {
                this.emailErrorMessage = 'Netfang er ekki gilt';
            }
        } else {
            this.emailErrorMessage = '';
        }
    };

    setTaxBracketsValidationMessages = (): void => {
        const { taxBracketsOther: otherValueControl } = this.taxBracketsForm.controls;
        if (otherValueControl.errors) {
            if (otherValueControl.errors?.['min']) {
                this.otherValueErrorMessage = this.translocoService.translate('mpsInfoTaxBracketsAmountNotValid');
            }
        } else {
            this.otherValueErrorMessage = '';
        }
    }
}
