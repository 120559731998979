<div class="{{ identifier }} input-group {{ modifier }} {{ isDisabled ? 'input-group--disabled' : '' }}">
    <!-- If the label is not empty, add the label element -->
    @if (label.length > 0) {
        <label class="label {{ isLabelHidden ? 'label--hidden' : '' }}" for="{{ id }}">
            {{ label }}
            @if (isRequired) {
                <span class="label__asterisk">*</span>
            }
        </label>
    }
    <!-- This wrap element is needed for positioning extra elements  -->
    <div class="input-group__wrap">
        <input
            [type]="type"
            [id]="id"
            [name]="id"
            class="input-group__input"
            [value]="displayValue"
            (input)="onInputChange($event)"
            [placeholder]="placeholder"
            [disabled]="isDisabled"
            [attr.maxlength]="maxLength"
            (blur)="onInputBlur()"
            (focus)="onInputFocus()"
        />

        @if (icon && icon.length > 0) {
            <div class="input-group__icon">
                <lsr-icon icon="{{ icon }}" color="{{ iconColor }}"></lsr-icon>
            </div>
        } @else if (suffix && suffix.length > 0) {
            <span class="input-group-suffix">{{ suffix }}</span>
        }
    </div>
    @if (errorMessage && errorMessage.length > 0) {
        <div aria-hidden="true" class="input-group__error">{{ errorMessage }}</div>
    }
</div>
