import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';

@Component({
    selector: 'lsr-dialog-base',
    standalone: true,
    imports: [LSRIconComponent, LSRButtonComponent],
    template: '',
    styleUrl: `lsr-dialog-base.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-dialog' },
})
export class LSRDialogBaseComponent {
    @Input() modifier?: string;
    @Input() headerTitle?: string;
    @Input() headerIcon?: string;
    @Input() isOpen: boolean = false;
    @Output() beingClosed = new EventEmitter<void>();

    open = () => {
        this.isOpen = true;
    };

    close = () => {
        this.isOpen = false;
        this.beingClosed.emit();
    };

    onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            this.close();
        }
    };
}
