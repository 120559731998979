import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM } from '@lsr/constants/generateExternalServiceJWTSystem.constants';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { environment } from '../../../../environments/environment';
import { CoreDataApplicationInfo } from '../../../../interfaces/support/applications/coreDataApplicationInfo';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'app-common-pension',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRActionCardComponent,
        LSRIframeWrapperComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
    ],
    templateUrl: 'common-pension-applications.component.html',
    styleUrl: './common-pension-applications.component.scss',
    host: { class: 'main__content' },
})
export class CommonPensionApplicationsComponent {
    @ViewChild(LSRDialogBasicComponent) dialog!: LSRDialogBasicComponent;

    public redirectUrl = '';
    public url = '';

    public selectedApplication: CoreDataApplicationInfo | undefined;
    public applications: CoreDataApplicationInfo[] = [];

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.initApplications();
        this.checkUrl();
    }

    /**
     * Opens the pension application dialog for the selected application.
     * Retrieves a token for authentication and sets up the redirect URL.
     * @param application The pension application to open
     */
    openApplication(application: CoreDataApplicationInfo) {
        application.isLoading = true;
        this.selectedApplication = application;
        this.url = '';
        this.redirectUrl = '';

        const updateUrlPath = (pathSuffix: string) => {
            this.location.go(`/lifeyrir/umsoknir/${pathSuffix}`);
        };

        const openDialogAndSubscribeClose = () => {
            this.dialog.open();
            this.dialog.beingClosed.subscribe(() => {
                updateUrlPath('');
            });
        };

        updateUrlPath(`${application.coreDataPageId}/${application.coreDataFormId}`);

        this.authService
            .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.COREDATA)
            .subscribe((token) => {
                this.url = `${environment.coredataPortalUrl}?token=${token}`;
                this.redirectUrl = `${environment.coredataPortalUrl}/${application.coreDataPageId}/forms/${application.coreDataFormId}/`;
                application.isLoading = false;
                openDialogAndSubscribeClose();
            });
    }

    /**
     * Checks the current route for specific application parameters and,
     * if present, opens the corresponding application.
     */
    checkUrl() {
        if (this.route.snapshot.paramMap.get('coreDataPageId') && this.route.snapshot.paramMap.get('coreDataFormId')) {
            const application = this.applications.find(
                (x) =>
                    x.coreDataPageId == this.route.snapshot.paramMap.get('coreDataPageId') &&
                    x.coreDataFormId == this.route.snapshot.paramMap.get('coreDataFormId')
            );
            this.openApplication(application as CoreDataApplicationInfo);
        }
    }

    /**
     * Initializes the available pension applications with their respective details.
     */
    initApplications() {
        this.applications = [
            {
                titleTranslation: 'mpaPensionPensionApplicationTitle',
                descriptionTranslation: 'mpaPensionPensionApplicationDescription',
                coreDataPageId: '18',
                coreDataFormId: '39',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionAverageAndSuccessorRuleTitle',
                descriptionTranslation: 'mpaPensionAverageAndSuccessorRuleDescription',
                coreDataPageId: '18',
                coreDataFormId: '40',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPension32Or95RuleTitle',
                descriptionTranslation: 'mpaPension32Or95RuleDescription',
                coreDataPageId: '18',
                coreDataFormId: '41',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionSpousalPensionTitle',
                descriptionTranslation: 'mpaPensionSpousalPensionDescription',
                coreDataPageId: '18',
                coreDataFormId: '42',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionTaxReturnTitle',
                descriptionTranslation: 'mpaPensionTaxReturnDescription',
                coreDataPageId: '18',
                coreDataFormId: '43',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionDisabilityTitle',
                descriptionTranslation: 'mpaPensionDisabilityDescription',
                coreDataPageId: '18',
                coreDataFormId: '51',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPensionReassessmentOfDisabilityTitle',
                descriptionTranslation: 'mpaPensionReassessmentOfDisabilityDescription',
                coreDataPageId: '18',
                coreDataFormId: '55',
                isLoading: false,
            },
        ];
    }
}
