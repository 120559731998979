import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM } from '@lsr/constants/generateExternalServiceJWTSystem.constants';
import { LSRActionCardComponent } from '@lsr/ui-components/lsr-action-card/lsr-action-card.component';
import { LSRDialogBasicComponent } from '@lsr/ui-components/lsr-dialog';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { environment } from '../../../../environments/environment';
import { CoreDataApplicationInfo } from '../../../../interfaces/support/applications/coreDataApplicationInfo';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'app-personal-pension-applications',
    templateUrl: 'personal-pension-applications.component.html',
    styleUrl: './personal-pension-applications.component.scss',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRActionCardComponent,
        LSRIframeWrapperComponent,
        LSRDialogBasicComponent,
        LSRLoaderComponent,
        TranslocoPipe,
    ],
    host: { class: 'main__content' },
})
export class PersonalPensionApplicationsComponent {
    @ViewChild(LSRDialogBasicComponent) dialog!: LSRDialogBasicComponent;

    public redirectUrl = '';
    public url = '';
    public selectedApplication: CoreDataApplicationInfo | undefined;
    public generalApplications: CoreDataApplicationInfo[] = [];
    public specifiedApplications: CoreDataApplicationInfo[] = [];

    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.initApplications();
        this.checkUrl();
    }

    /**
     * Opens the selected personal pension application in a dialog.
     * Retrieves a token for authentication and sets up the redirect URL.
     * @param application The personal pension application to open
     */
    openApplication(application: CoreDataApplicationInfo) {
        application.isLoading = true;
        this.selectedApplication = application;
        this.url = '';
        this.redirectUrl = '';

        const updateUrlPath = (pathSuffix: string) => {
            this.location.go(`/sereign/umsoknir/${pathSuffix}`);
        };

        const openDialogAndSubscribeClose = () => {
            this.dialog.open();
            this.dialog.beingClosed.subscribe(() => {
                updateUrlPath('');
            });
        };

        updateUrlPath(`${application.coreDataPageId}/${application.coreDataFormId}`);

        this.authService
            .generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.COREDATA)
            .subscribe((token) => {
                this.url = `${environment.coredataPortalUrl}?token=${token}`;
                this.redirectUrl = `${environment.coredataPortalUrl}/${application.coreDataPageId}/forms/${application.coreDataFormId}/`;
                application.isLoading = false;
                openDialogAndSubscribeClose();
            });
    }

    /**
     * Checks the current route for specific application parameters and,
     * if present, opens the corresponding application.
     */
    checkUrl() {
        if (this.route.snapshot.paramMap.get('coreDataPageId') && this.route.snapshot.paramMap.get('coreDataFormId')) {
            const application =
                this.generalApplications.find(
                    (x) =>
                        x.coreDataPageId == this.route.snapshot.paramMap.get('coreDataPageId') &&
                        x.coreDataFormId == this.route.snapshot.paramMap.get('coreDataFormId')
                ) ||
                this.specifiedApplications.find(
                    (x) =>
                        x.coreDataPageId == this.route.snapshot.paramMap.get('coreDataPageId') &&
                        x.coreDataFormId == this.route.snapshot.paramMap.get('coreDataFormId')
                );

            if (application) {
                this.openApplication(application as CoreDataApplicationInfo);
            }
        }
    }

    /**
     * Initializes the available personal pension applications with their respective details.
     * Separates applications into general and specified categories.
     */
    initApplications() {
        this.generalApplications = [
            {
                titleTranslation: 'mpaPersonalPensionAgreementOnPersonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionAgreementOnPersonalPensionSavingsDescription',
                coreDataPageId: '23',
                coreDataFormId: '46',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionAmendmentOfExistingAgreementOnPersonalPensionSavingsTitle',
                descriptionTranslation:
                    'mpaPersonalPensionAmendmentOfExistingAgreementOnPersonalPensionSavingsDescription',
                coreDataPageId: '23',
                coreDataFormId: '47',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionPayoutOfPesonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionPayoutOfPesonalPensionSavingsDescription',
                coreDataPageId: '23',
                coreDataFormId: '44',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionSuspensionOfPayoutOfPesonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionSuspensionOfPayoutOfPesonalPensionSavingsDescription',
                coreDataPageId: '23',
                coreDataFormId: '76',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionDivisionOfPersonalPensionSavingsTitle',
                descriptionTranslation: 'mpaPersonalPensionDivisionOfPersonalPensionSavingsDescription',
                coreDataPageId: '23',
                coreDataFormId: '45',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaPersonalPensionTerminationOfPersonalPensionSavingsAgreementTitle',
                descriptionTranslation: 'mpaPersonalPensionTerminationOfPersonalPensionSavingsAgreementDescription',
                coreDataPageId: '23',
                coreDataFormId: '86',
                isLoading: false,
            },
        ];

        this.specifiedApplications = [
            {
                titleTranslation: 'mpaSpecifiedPersonalPensionApplicationTitle',
                descriptionTranslation: 'mpaSpecifiedPersonalPensionApplicationDescription',
                coreDataPageId: '23',
                coreDataFormId: '82',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaSpecifiedPersonalPensionApplicationForPayoutTitle',
                descriptionTranslation: 'mpaSpecifiedPersonalPensionApplicationForPayoutDescription',
                coreDataPageId: '23',
                coreDataFormId: '87',
                isLoading: false,
            },
            {
                titleTranslation: 'mpaSpecifiedPersonalPensionTerminationTitle',
                descriptionTranslation: 'mpaSpecifiedPersonalPensionTerminationDescription',
                coreDataPageId: '23',
                coreDataFormId: '85',
                isLoading: false,
            },
        ];
    }
}
