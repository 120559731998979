import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { interval, map, Subscription } from 'rxjs';
import { LSRLoaderComponent } from '../lsr-loader';

@Component({
    selector: 'lsr-countdown',
    standalone: true,
    imports: [RouterLink, LSRIconComponent, LSRLoaderComponent, NgClass],
    templateUrl: `lsr-countdown.component.html`,
    styleUrl: `lsr-countdown.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-countdown' },
})
export class LSRCountdownComponent implements OnInit, OnDestroy {
    @Input() targetDate: Date | null = null;
    @Output() countdownComplete = new EventEmitter<void>();

    private subscription: Subscription | null = null;
    public formattedCountdown: string = '';

    ngOnInit() {
        if (this.targetDate) {
            this.formattedCountdown = this.formatTime(this.calculateTimeLeft()!);
            this.createTimer();
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    createTimer = () => {
        this.subscription = interval(1000)
            .pipe(map(() => this.calculateTimeLeft()))
            .subscribe((timeLeft) => {
                if (timeLeft == null || (timeLeft.hours == 0 && timeLeft.minutes == 0 && timeLeft.seconds == 0)) {
                    this.countdownComplete.emit();
                    return;
                }

                if (timeLeft) {
                    this.formattedCountdown = this.formatTime(timeLeft);
                }
            });
    };

    calculateTimeLeft = (): CountDownTime | null => {
        if (!this.targetDate) {
            return null;
        }

        const now = new Date();
        const timeDifference = this.targetDate.getTime() - now.getTime();

        if (timeDifference > 0) {
            const seconds = Math.floor((timeDifference / 1000) % 60);
            const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
            const hours = Math.floor(timeDifference / (1000 * 60 * 60));

            return {
                hours,
                minutes,
                seconds,
            } as CountDownTime;
        } else {
            return {
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
    };

    formatTime(timeLeft: CountDownTime) {
        const { hours, minutes, seconds } = timeLeft;

        // Format as HH:mm:ss if hours > 0
        if (hours > 0) {
            return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
        }
        // Format as mm:ss if minutes > 0 and hours == 0
        else if (minutes > 0) {
            return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
        }
        // Format as ss if only seconds are left
        else {
            return `${this.padZero(seconds)}`;
        }
    }

    padZero(value: number) {
        return value < 10 ? `0${value}` : value;
    }
}

interface CountDownTime {
    hours: number;
    minutes: number;
    seconds: number;
}
