@if (
    route && route.path !== '' && route.path !== '**' && route.path !== 'stillingar' && route.path !== 'lanaumsoknir'
) {
    <li [ngClass]="itemClassNames()">
        @if (!isChildItem) {
            <a
                routerLink="{{ route.path }}"
                routerLinkActive="{{ identifier }}__link--active"
                class="{{ identifier }}__link"
                title="{{ route.path }}"
            >
                {{ route.data?.['name'] | transloco }}
            </a>
        } @else {
            <a routerLink="{{ route.path }}" class="{{ identifier }}__link" title="{{ route.path }}">
                {{ route.data?.['name'] | transloco }}
            </a>
        }

        @if (routeChildrens().length > 0 && displayChildren) {
            <ng-container>
                <button class="{{ identifier }}__chevron" title="Meira" (click)="forwardTrigger($event)">
                    <lsr-icon icon="chevron-right"></lsr-icon>
                </button>
                <ul class="{{ identifier }}__list">
                    <li class="{{ identifier }}__item">
                        <ul class="{{ identifier }}__list {{ identifier }}">
                            <li class="{{ identifier }}__item">
                                <button class="{{ identifier }}__back" (click)="backTrigger($event)" title="Til baka">
                                    <lsr-icon icon="chevron-left"></lsr-icon>
                                    <span>{{ route.data?.['name'] | transloco }}</span>
                                </button>
                            </li>
                            @for (childRoute of routeChildrens(); track childRoute) {
                                <lsr-navigation-item
                                    [route]="childRoute"
                                    [identifier]="identifier"
                                    [displayChildren]="displayChildren"
                                    [isChildItem]="true"
                                ></lsr-navigation-item>
                            }
                        </ul>
                    </li>
                </ul>
            </ng-container>
        }
    </li>
}
