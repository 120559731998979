import { AfterViewInit, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { Routes } from '@angular/router';
import { LSRNavigationItemComponent } from '@lsr/ui-components/lsr-navigation-item';

@Component({
    selector: 'lsr-navigation',
    standalone: true,
    imports: [LSRNavigationItemComponent],
    templateUrl: `lsr-navigation.component.html`,
    styleUrl: `lsr-navigation.component.scss`,
    host: { class: 'lsr-navigation' },
    encapsulation: ViewEncapsulation.None,
})
export class LSRNavigationComponent implements AfterViewInit {
    @Input() modifier = '';
    @Input() identifier = '';
    @Input() ariaLabel = '';
    @Input() routes?: Routes = [];
    @Input() displayChildren?: boolean = false;
    @Input() displayPriority?: boolean = false;
    @Input() isPriorityOpen?: boolean = false;

    constructor(private elRef: ElementRef<HTMLElement>) {}

    ngAfterViewInit() {
        if (this.displayPriority) {
            this.togglePriorityItems();
            window.addEventListener('resize', () => this.togglePriorityItems());
        }
    }

    togglePriorityDropdown() {
        this.isPriorityOpen = !this.isPriorityOpen;

        const header = document.querySelector<HTMLElement>('.lsr-header');

        if (header) {
            header.style.zIndex = this.isPriorityOpen ? '4' : '';
        }
    }

    togglePriorityItems() {
        const navigation = this.elRef.nativeElement;

        const el = {
            dropdown: navigation.querySelector<HTMLElement>('.priority-navigation__dropdown'),
            dropdownItems: navigation.querySelectorAll<HTMLElement>(
                '.priority-navigation__dropdown .lsr-navigation-item'
            ),
            dropdownToggle: navigation.querySelector<HTMLElement>('.priority-navigation__dropdown-toggle'),
            items: navigation.querySelectorAll<HTMLElement>('nav > ul > .lsr-navigation-item'),
            list: navigation.querySelector<HTMLElement>('nav > ul'),
        };

        // Get the width of the list (container)
        const listWidth = el.list?.getBoundingClientRect().width || 0;

        let totalItemsWidth = 0;

        // Initially show all items
        el.items.forEach((item, index) => {
            item.style.display = '';
            el.dropdownItems[index].style.display = 'none';
        });

        // Measure total width of all items (including gaps between items & dropdown toggle) and hide overflowing ones
        const gap = el.list ? Number.parseFloat(getComputedStyle(el.list).rowGap) || 0 : 0;

        el.dropdown?.classList.remove('priority-navigation__dropdown--hidden');
        const dropdownToggleWidth = el.dropdownToggle?.getBoundingClientRect().width || 0;
        el.dropdown?.classList.add('priority-navigation__dropdown--hidden');

        totalItemsWidth += dropdownToggleWidth;

        el.items.forEach((item, index) => {
            const itemWidth = item.getBoundingClientRect().width + gap;

            totalItemsWidth += itemWidth;

            // If the cumulative width exceeds the list width, hide the item
            if (totalItemsWidth > listWidth) {
                item.style.display = 'none';
                el.dropdownItems[index].style.display = '';
            }
        });

        // Display the dropdown toggle if there are hidden items
        if (totalItemsWidth > listWidth) {
            el.dropdown?.classList.remove('priority-navigation__dropdown--hidden');
        } else {
            el.dropdown?.classList.add('priority-navigation__dropdown--hidden');
        }
    }
}
