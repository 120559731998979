<div class="work-in-progress">
    <div class="work-in-progress__header">
        @if (showIcon) {
            <lsr-icon icon="loading-gears" modifier="large"></lsr-icon>
        }
        @if (title) {
            <lsr-title text="{{ title }}"></lsr-title>
        }
    </div>
    @if (description) {
        <p class="body1" [innerHTML]="description"></p>
    }
</div>
