import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRPaginationComponent } from '@lsr/ui-components/lsr-pagination';
import { TableData } from '../lsr-table-interfaces';
import { isString } from '../lsr-table.utils';
import { LSRTableExpandableInnerComponent } from './lsr-table-expandable-inner';

@Component({
    selector: 'lsr-table-expandable',
    standalone: true,
    imports: [LSRPaginationComponent, LSRButtonComponent, LSRTableExpandableInnerComponent, TranslocoPipe],
    templateUrl: `lsr-table-expandable.component.html`,
    styleUrl: `lsr-table-expandable.component.scss`,
    encapsulation: ViewEncapsulation.None,
})
export class LSRTableExpandableComponent implements OnInit, AfterViewInit {
    private _data?: TableData;
    @Input()
    set data(value: TableData | undefined) {
        this._data = value;
        this.setPage(this.currentPage);
    }
    get data() {
        return this._data;
    }
    @Input() modifier?: string;
    @Input() title?: string;
    @Input() maxRows: number = 5;
    @Input() innerTableMaxRows: number = 10;
    @Input() linkTitle?: string;
    @Input() linkHref?: string;
    currentPage: number = 1;
    totalPages: number = 1;
    startIndex: number = 0;
    endIndex: number = this.maxRows;

    constructor(private elRef: ElementRef<HTMLElement>) {}

    // Declare util function for use in component template
    isString = isString;

    ngOnInit() {
        this.setPage(1);
    }

    ngAfterViewInit() {
        const root = this.elRef.nativeElement;
        const tableContainer = root.querySelector<HTMLElement>('.table__table-container');

        if (tableContainer) {
            this.positionToggle(tableContainer);
            window.addEventListener('resize', () => this.positionToggle(tableContainer));
            tableContainer.addEventListener('scroll', () => this.positionToggle(tableContainer));
        }
    }

    toggleInnerTable(e: MouseEvent) {
        const target = e.target as HTMLElement;
        const row = target.closest('.table__expandable-table-row');
        if (row) {
            if (row.classList.contains('table__expandable-table-row--active')) {
                row.classList.remove('table__expandable-table-row--active');
            } else {
                // close any other expanded rows
                const expandedRows = document.querySelectorAll('.table__expandable-table-row--active');
                if (expandedRows) {
                    expandedRows.forEach((er) => {
                        er.classList.remove('table__expandable-table-row--active');
                    });
                }
                // expand target row
                row.classList.add('table__expandable-table-row--active');
            }
        }
    }

    positionToggle(tableContainer: HTMLElement) {
        const table = tableContainer.querySelector<HTMLElement>('.table__table table');
        const toggles = tableContainer.querySelectorAll<HTMLButtonElement>('.table__toggle');
        const maxScrollLeft = tableContainer.scrollWidth - tableContainer.offsetWidth;
        const scrollLeftClamped = Math.min(Math.max(0, tableContainer.scrollLeft), maxScrollLeft);

        toggles.forEach((toggle) => {
            if (table && table.offsetWidth > tableContainer.offsetWidth) {
                toggle.style.right = `${table.offsetWidth - tableContainer.offsetWidth - scrollLeftClamped}px`;
            } else {
                toggle.style.right = '0px';
            }
        });
    }

    setPage(page: number) {
        this.currentPage = page;

        if (this.data) {
            this.totalPages = Math.ceil(this.data.rows.length / this.maxRows);
            this.startIndex = (this.currentPage - 1) * this.maxRows;
            this.endIndex = this.startIndex + this.maxRows;
        }
    }
}
