import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';

@Component({
    selector: 'app-no-auth-layout',
    standalone: true,
    imports: [RouterOutlet, LSRIconComponent, LSRButtonComponent, TranslocoModule],
    templateUrl: './no-auth-layout.component.html',
    styleUrl: './no-auth-layout.component.scss',
    host: { class: 'layout layout--full-height' },
})
export class NoAuthLayoutComponent {

  constructor(public translocoService: TranslocoService) {}

    setLang(lang: string) {
        this.translocoService.setActiveLang(lang);
    }
}
