<section class="auth-form">
    <div class="grid">
        <div class="auth-form__column auth-form__column--form">
            <div class="auth-form__container">
                <nav class="auth-form__nav">
                    <ul class="auth-form__nav-list">
                        <li class="auth-form__nav-item">
                            <button
                                type="button"
                                class="auth-form__nav-button"
                                [ngClass]="{
                                    'auth-form__nav-button--active': form.value.authenticationMethod === 'sim',
                                }"
                                id="sim"
                                (click)="toggleLoginMethod($event)"
                            >
                            {{('mplElectronicId' | transloco)}}
                            </button>
                        </li>
                        <li class="auth-form__nav-item">
                            <button
                                type="button"
                                class="auth-form__nav-button"
                                [ngClass]="{
                                    'auth-form__nav-button--active': form.value.authenticationMethod === 'app',
                                }"
                                id="app"
                                (click)="toggleLoginMethod($event)"
                            >
                            {{('mplIdentityApp' | transloco)}}
                            </button>
                        </li>
                    </ul>
                </nav>

                <div class="auth-form__forms">
                    <form
                        action="#"
                        class="auth-form__form auth-form__form--active"
                        id="auth-form-sim"
                        enctype="multipart/form-data"
                        method="post"
                        (submit)="onSubmit($event)"
                        [formGroup]="form"
                    >
                        <h2 class="headline2 auth-form__title" tabindex="0">
                            {{
                                form.value.authenticationMethod === 'sim'
                                    ? ('authElectronicIdButton' | transloco)
                                    : ('authIdentityAppTab' | transloco)
                            }}
                        </h2>
                        <div class="body2 auth-form__text" tabindex="0">
                            Innskráning á mínar síður hjá LSR krefst rafrænnar innskráningar. Með því tryggjum við
                            öryggi gagna þinna og auðveldum þér alla umsýslu.
                        </div>
                        @if (form.value.authenticationMethod === 'sim') {
                            <lsr-input
                                label="Símanúmer"
                                name="phone"
                                type="tel"
                                placeholder="000-0000"
                                formControlName="phonenumber"
                                format="phone"
                                maxLength="8"
                            >
                            </lsr-input>
                        } @else if (form.value.authenticationMethod === 'app') {
                            <lsr-input
                                label="Kennitala"
                                name="phone"
                                placeholder="000000-0000"
                                maxLength="11"
                                formControlName="ssn"
                                format="ssn"
                            >
                            </lsr-input>
                        }

                        @if (isAuthenticating && verificationCode) {
                            <div class="auth-form__challenge body4">
                                <p tabindex="0">
                                    Öryggistala: <span class="auth-form__challenge-text">{{ verificationCode }}</span>
                                </p>
                            </div>
                        }
                        <lsr-button
                            type="submit"
                            modifier="auth-form__submit auth-form__submit--sim button button--default"
                            title="Innskráning"
                            text="Innskráning"
                            [isDisabled]="!form.valid"
                            [isLoading]="isAuthenticating"
                        ></lsr-button>
                    </form>
                    @if (errorMessage) {
                        <div class="auth-form__message auth-form__message--error body2">
                            <p tabindex="0">{{ errorMessage }}</p>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div class="auth-form__column auth-form__column--picture">
            <picture class="auth-form__picture picture picture--eager">
                <source
                    media="(min-resolution: 120dpi) and (max-width: 412px)"
                    srcset="/assets/images/login--704.jpg"
                    width="704"
                    height="704"
                />
                <source
                    media="(min-width: 413px) and (max-width: 767px)"
                    srcset="/assets/images/login--707.jpg"
                    width="707"
                    height="707"
                />
                <source
                    media="(min-resolution: 120dpi) and (min-width: 413px) and (max-width: 767px)"
                    srcset="/assets/images/login--1414.jpg"
                    width="1414"
                    height="1414"
                />
                <source
                    media="(min-resolution: 120dpi) and (min-width: 768px)"
                    srcset="/assets/images/login--1360.jpg"
                    width="1360"
                    height="1360"
                />
                <source media="(min-width: 768px)" srcset="/assets/images/login--680.jpg" width="680" height="680" />
                <img
                    class="auth-form__image picture__image"
                    src="/assets/images/login--352.jpg"
                    width="352"
                    height="352"
                    alt=""
                    loading="eager"
                    decoding="async"
                />
            </picture>
        </div>
    </div>
</section>
