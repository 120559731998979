import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ExposedKriaPaidContributionsYearGroupRes } from '../../interfaces/responses/exposed/exposedKriaPaidContributionsYearGroupRes';
import { HttpStatus } from '../../interfaces/support/status/httpStatus';
import { CacheService } from '../cache/cache.service';

@Injectable({
    providedIn: 'root',
})
export class ContributionsService {
    private contributionsCache = new Map<string, ExposedKriaPaidContributionsYearGroupRes[]>();

    constructor(
        private http: HttpClient,
        private cacheService: CacheService
    ) {}

    public contributionsLSRA = (): Observable<ExposedKriaPaidContributionsYearGroupRes[]> => {
        return this.cacheService
            .getOrFetch('contributions_LSRA', () =>
                this.http.get<HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>>(
                    environment.localApi + 'Contributions/LSR-A'
                )
            )
            .pipe(
                map((res: HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of();
                })
            );
    };

    public contributionsLSRB = (): Observable<ExposedKriaPaidContributionsYearGroupRes[]> => {
        return this.cacheService
            .getOrFetch('contributions_LSRB', () =>
                this.http.get<HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>>(
                    environment.localApi + 'Contributions/LSR-B'
                )
            )
            .pipe(
                map((res: HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of();
                })
            );
    };

    public contributionsLSRSER = (): Observable<ExposedKriaPaidContributionsYearGroupRes[]> => {
        return this.cacheService
            .getOrFetch('contributions_LSRSER', () =>
                this.http.get<HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>>(
                    environment.localApi + 'Contributions/LSRSER'
                )
            )
            .pipe(
                map((res: HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of();
                })
            );
    };

    public contributionsLSRTS = (): Observable<ExposedKriaPaidContributionsYearGroupRes[]> => {
        return this.cacheService
            .getOrFetch('contributions_LSRTS', () =>
                this.http.get<HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>>(
                    environment.localApi + 'Contributions/LSRTS'
                )
            )
            .pipe(
                map((res: HttpStatus<ExposedKriaPaidContributionsYearGroupRes[]>) => res.returnItem),
                catchError((error) => {
                    console.log(error);
                    return of();
                })
            );
    };
}
