import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationSkipped, NavigationSkippedCode, Router, RouterLink, Routes } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRNavigationComponent } from '@lsr/ui-components/lsr-navigation';
import { filter } from 'rxjs';

@Component({
    selector: 'lsr-mobile-header',
    standalone: true,
    imports: [LSRButtonComponent, LSRNavigationComponent, LSRIconComponent, NgClass, RouterLink, TranslocoPipe],
    templateUrl: `lsr-mobile-header.component.html`,
    styleUrl: `lsr-mobile-header.component.scss`,
    encapsulation: ViewEncapsulation.None,
})
export class LSRMobileHeaderComponent {
    @Input() routes: Routes = [];
    @Output() logout: EventEmitter<void> = new EventEmitter<void>();

    // Ensures a loader is displayed while the user is being logged out.
    isLoggingOut: boolean = false;

    private isInitialLoad = true;
    public showMobileMenu: boolean | null = null;

    constructor(
        private router: Router,
        public translocoService: TranslocoService
    ) {
        this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        (event instanceof NavigationSkipped &&
                            event.code == NavigationSkippedCode.IgnoredSameUrlNavigation)
                )
            )
            .subscribe(() => {
                // Don't trigger on initial load event so the menu wont be open initially
                if (this.isInitialLoad) {
                    this.isInitialLoad = false;
                    return;
                }

                // Always close the menu on a navigation event
                this.showMobileMenu = false;
            });
    }

    onMenuClick() {
        // Ensure that all inline styles for the mobile header are reset whenever the navigation is reopened.
        const mobileHeader = document.querySelector<HTMLElement>('.mobile-header');
        mobileHeader?.querySelectorAll('*').forEach((element) => {
            element.removeAttribute('style');
        });

        mobileHeader?.querySelectorAll('.mobile-navigation__item--active').forEach((element) => {
            element.classList.remove('mobile-navigation__item--active');
        });

        this.showMobileMenu = !this.showMobileMenu;
    }

    logoutClick = () => {
        this.isLoggingOut = true;
        this.logout.emit();
    };

    setLang(lang: string) {
        this.translocoService.setActiveLang(lang);
    }
}
