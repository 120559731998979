import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideZoneChangeDetection } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale, provideTranslocoNumberTransformer } from '@jsverse/transloco-locale';
import { MessageService } from 'primeng/api';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { AuthInterceptor } from './auth/auth.interceptor';
import { CustomNumberTransformer } from './helpers/transloco/transloco.transformes';
import { TranslocoHttpLoader } from './helpers/transloco/translocoHttpLoader';

bootstrapApplication(AppComponent, {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        provideRouter(routes),
        MessageService,
        provideAnimations(),
        provideTransloco({
            config: {
                availableLangs: ['is', 'en'],
                defaultLang: 'is',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: false,
                missingHandler: {
                    logMissingKey: false,
                },
            },
            loader: TranslocoHttpLoader,
        }),
        provideTranslocoLocale({
            // Numbers and dates are always displayed in Icelandic locale format
            langToLocaleMapping: {
                en: 'is-IS',
                is: 'is-IS',
            },
        }),
        provideTranslocoNumberTransformer(CustomNumberTransformer),
    ],
}).catch((e) => console.error(e));
