@if (showLoader && !iframeReady) {
    <div class="loader-wrapper">
        <lsr-loader [loadingText]="loadingText"></lsr-loader>
    </div>
}

<iframe
    [ngClass]="[iframeReady ? 'visible' : 'invisible', iframeStyles]"
    class="iframe-container"
    #iframeWrapper
    [id]="id"
    [src]="url | safe"
>
</iframe>
