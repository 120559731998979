import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Routes } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';
import { LSRNavigationComponent } from '@lsr/ui-components/lsr-navigation';

@Component({
    selector: 'lsr-header',
    standalone: true,
    imports: [LSRButtonComponent, LSRNavigationComponent, LSRIconComponent, TranslocoModule],
    templateUrl: `lsr-header.component.html`,
    styleUrl: `lsr-header.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-header' },
})
export class LSRHeaderComponent {
    @Input() routes: Routes = [];
    @Output() logout: EventEmitter<void> = new EventEmitter<void>();

    // Ensures a loader is displayed while the user is being logged out.
    isLoggingOut: boolean = false;

    constructor(public translocoService: TranslocoService) {}

    setLang(lang: string) {
        this.translocoService.setActiveLang(lang);
    }

    logoutClick = () => {
        this.isLoggingOut = true;
        this.logout.emit();
    };
}
