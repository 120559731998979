import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs';
import { LSRIconComponent } from '../lsr-icon/lsr-icon.component';
import { LSRLoaderComponent } from '../lsr-loader';

@Component({
    selector: 'lsr-splash-screen',
    standalone: true,
    imports: [LSRIconComponent, LSRLoaderComponent],
    templateUrl: `lsr-splash-screen.component.html`,
    styleUrl: `lsr-splash-screen.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-splash-screen' },
})
export class LSRSplashScreenComponent {
    loaded: boolean = false;
    loading: boolean = true;

    constructor(private router: Router) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                this.loaded = true;
                // This timeout is necessary for the splash screen fadeout transition
                setTimeout(() => {
                    this.loading = false;
                }, 200);
            });
    }
}
