<lsr-title
    text="{{ 'mpppPageTitle' | transloco }}"
    linkText="{{ 'mpaApplicationsAndFormsTitle' | transloco }}"
    linkHref="/sereign/umsoknir"
></lsr-title>
<lsr-navigation
    [routes]="routes"
    identifier="subnavigation"
    [displayPriority]="false"
    [displayChildren]="false"
></lsr-navigation>

@if (loading) {
    <div class="loader-wrapper">
        <lsr-loader loadingText="{{ 'mppPageLoadingText' | transloco }}"></lsr-loader>
    </div>
} @else {
    <router-outlet></router-outlet>
}
