import { Component, Input } from '@angular/core';

@Component({
    selector: 'lsr-icon',
    standalone: true,
    imports: [],
    templateUrl: `lsr-icon.component.html`,
    styleUrl: `lsr-icon.component.scss`,
    host: { class: 'lsr-icon' },
})
export class LSRIconComponent {
    @Input() icon?: string;
    @Input() color: string = '#912D27';
    @Input() modifier?: string;
}
