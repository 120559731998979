import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(): Observable<boolean> {
        return this.authService.checkAuthenticated().pipe(
            switchMap((authenticated) => {
                if (authenticated) {
                    this.router.navigate(['']);
                    return of(false);
                }
                return of(true);
            })
        );
    }
}
