<section class="title">
    <div>
        <h1
            class="title__text"
            [ngClass]="{ headline2: size === 'large', headline3: size === 'medium', headline4: size === 'small' }"
            tabindex="0"
        >
            {{ text }}
        </h1>
        @if (subtitle) {
            <p class="title__subtitle body2">{{ subtitle }}</p>
        }
    </div>
    @if (linkHref && linkText) {
        <lsr-button
            buttonType="link"
            identifier="title__button"
            modifier="button--secondary button--red"
            text="{{ linkText }}"
            routerLink="{{ linkHref }}"
        ></lsr-button>
    }
</section>
