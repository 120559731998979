import { Component, Input } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
    selector: 'lsr-pdf-viewer',
    standalone: true,
    imports: [PdfViewerModule],
    templateUrl: `lsr-pdf-viewer.component.html`,
    styleUrl: `lsr-pdf-viewer.component.scss`,
})
export class LSRPdfViewerComponent {
    @Input() id: string = '';
    @Input() src: string | undefined = '';
    @Input() originalSize!: boolean;
    @Input() showAll!: boolean;
    @Input() fitToPage!: boolean;
    @Input() zoom!: number;
    @Input() stickToPage!: boolean;
    @Input() renderText!: boolean;
    @Input() externalLinkTarget: string = '';
    @Input() autoresize!: boolean;
    @Input() showBorders!: boolean;
}
