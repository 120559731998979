import { Component, ViewEncapsulation } from '@angular/core';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'lsr-toast',
    standalone: true,
    imports: [ToastModule],
    templateUrl: `lsr-toast.component.html`,
    styleUrl: `lsr-toast.component.scss`,
    encapsulation: ViewEncapsulation.None,
    host: { class: 'lsr-toast' },
})
export class LSRToastComponent {}
