import { Component, Input } from '@angular/core';
import { LSRIconComponent } from '@lsr/ui-components/lsr-icon';

@Component({
    selector: 'lsr-footer',
    standalone: true,
    imports: [LSRIconComponent],
    templateUrl: `lsr-footer.component.html`,
    styleUrl: `lsr-footer.component.scss`,
})
export class LSRFooterComponent {
    @Input() modifier?: string = '';
    @Input() companyName = 'Lífeyrissjóður starfsmanna ríkisins';
    @Input() companyAddress = 'Engjateigi 11, 105 Reykjavík';
    @Input() companyLocation = 'https://maps.app.goo.gl/YxtbNfa4myecChja7';
    @Input() companyPhone = '510 6100';
    @Input() openingHours: [string, string][] = [
        ['Mán - fim:', '9:00 - 15:30'],
        ['Föstudaga:', '9:00 - 14:00'],
    ];
}
