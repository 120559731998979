import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

// Determines if a route can be deactivated
// Guard checks for the existance of a canDeactivate function on the component being deactivated and runs it, otherwise the component can always be deactivated.
export const canDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (component: CanComponentDeactivate) => {
    return component.canDeactivate ? component.canDeactivate() : true;
};

// Components should extend this interface when implementing the canDeactivate function
export interface CanComponentDeactivate {
    canDeactivate: () => CanDeactivateType;
}

// Custom type to simplify canDeactivate function signatures
export type CanDeactivateType = Observable<boolean> | Promise<boolean> | boolean;
