<lsr-title
    text="{{ 'mplPageTitle' | transloco }}"
    linkText="{{ 'mpaApplicationsAndFormsTitle' | transloco }}"
    linkHref="/lanaumsoknir"
></lsr-title>

<lsr-navigation
    [routes]="loanRoutes"
    identifier="subnavigation"
    [displayPriority]="true"
    [displayChildren]="false"
></lsr-navigation>
@if (showIframe && !isLoading) {
    <section class="libraWeb">
        <div #iframeContainer class="iframe-container">
            <lsr-iframe-wrapper [url]="iframeUrl"></lsr-iframe-wrapper>
        </div>
    </section>
}
@if (isCalculator) {
    <lsr-calculator [embed]="calculatorUrl"></lsr-calculator>
}

@if (isLoanEligibility) {
    <div class="body1">
        <p>
            {{ 'mplLoanEligibilityText' | transloco }}
        </p>
        @if (pdfSrc) {
            <lsr-button
                class="loans-download-button"
                text="Sækja skjal"
                modifier="button--secondary button--red "
                buttonType="click"
                (clickEvent)="downloadPdf()"
                [isLoading]="isLoading"
                icon="pdf"
            >
            </lsr-button>
        }
    </div>
    <div class="loans-pdf-container" #loansPdfContainer>
        <lsr-pdf-viewer
            id="loans-pdf-container"
            [src]="pdfSrc"
            [zoom]="zoomValue"
            [originalSize]="true"
            [showAll]="true"
            [fitToPage]="false"
            [stickToPage]="false"
            [renderText]="true"
            [externalLinkTarget]="'blank'"
            [autoresize]="true"
            [showBorders]="false"
        >
        </lsr-pdf-viewer>
    </div>
}

@if (isLoading) {
    <lsr-loader></lsr-loader>
}
