import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Routes } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM } from '@lsr/constants/generateExternalServiceJWTSystem.constants';
import { LSRButtonComponent } from '@lsr/ui-components/lsr-button';
import { LSRCalculatorComponent } from '@lsr/ui-components/lsr-calculator';
import { LSRIframeWrapperComponent } from '@lsr/ui-components/lsr-iframe-wrapper/lsr-iframe-wrapper.component';
import { LSRLoaderComponent } from '@lsr/ui-components/lsr-loader';
import { LSRNavigationComponent } from '@lsr/ui-components/lsr-navigation';
import { LSRPdfViewerComponent } from '@lsr/ui-components/lsr-pdf-viewer';
import { LSRTitleComponent } from '@lsr/ui-components/lsr-title';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { combineLatest, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { base64ToArrayBuffer, saveByteArray } from '../../../helpers/blobDownload';
import { ExposedMeRes } from '../../../interfaces/responses/exposed/exposedMeRes';
import { ExposedReadDocumentRes } from '../../../interfaces/responses/exposed/exposedReadDocumentRes';
import { ReadLoanEligibilityRes } from '../../../interfaces/responses/Read/readLoanEligibilityRes';
import { AuthService } from '../../../services/auth/auth.service';
import { LoansService } from '../../../services/loans/loans.service';
import { UserService } from '../../../services/user/user.service';
import { WorkInProgressComponent } from '../work-in-progress/work-in-progress.component';

@Component({
    selector: 'app-loans',
    standalone: true,
    imports: [
        LSRTitleComponent,
        LSRNavigationComponent,
        LSRIframeWrapperComponent,
        LSRLoaderComponent,
        TranslocoPipe,
        LSRCalculatorComponent,
        WorkInProgressComponent,
        PdfViewerModule,
        LSRButtonComponent,
        LSRPdfViewerComponent,
    ],
    templateUrl: 'loans.component.html',
    styleUrls: ['./loans.component.scss'],
    host: { class: 'main__content' },
})
export class LoansComponent implements OnInit, OnDestroy {
    //#region Globals

    @ViewChild('iframeContainer') iframeContainer!: ElementRef;
    @ViewChild('loansPdfContainer') loansPdfContainer!: ElementRef;

    public iframeUrl: string | undefined;
    public calculatorUrl: string = '';
    public pdfSrc: string = '';
    zoomValue: number = 1.3;
    selectedDocumentInfo: ExposedReadDocumentRes | null = null;
    loanEligibilityInfo: ReadLoanEligibilityRes | null = null;
    userDetails: ExposedMeRes | null = null;

    // Sub-routes under the loan page
    loanRoutes: Routes = [];

    // Tracks loading state for HTTP requests
    isLoading: boolean = true;

    // Flag to determine if iframe should be shown
    showIframe: boolean = false;

    subscriptions: Subscription[] = [];

    // Flag to determine if calculator should be shown
    isCalculator: boolean = false;

    // Flag to determine if loan eligibility should be shown
    isLoanEligibility: boolean = false;

    //#endregion

    //#region Constructor and Init

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private loansService: LoansService,
        private userService: UserService,
        private renderer: Renderer2
    ) {
        // Initialize loan routes from router configuration
        this.loanRoutes =
            this.router.config
                .find((route) => route.data?.['domain'] === 'menuItems')
                ?.children?.find((child) => child.path === 'lan')?.children || [];
    }

    ngOnInit() {
        // Handle route changes on component initialization
        this.routeChanged();
        this.listenToRouteChanges();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    //#endregion

    //#region Functions

    // Listen for route change events
    listenToRouteChanges() {
        const navigationSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                // Trigger logic when route changes
                this.routeChanged();
            });
        this.subscriptions.push(navigationSubscription);
    }

    // Handles changes in the current route and updates the component state
    routeChanged() {
        this.isCalculator = false;
        this.isLoanEligibility = false;
        let currentRoute = this.activatedRoute;

        // Traverse through nested child routes to find the deepest active route
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        if (currentRoute.snapshot.routeConfig) {
            this.showIframe = false;
            const libraUrl = currentRoute.snapshot.routeConfig?.data?.['libraUrl'] ?? null;

            if (libraUrl) {
                this.getPageData(libraUrl);
            } else if (currentRoute.snapshot.routeConfig?.path == 'lanareiknivel') {
                this.isCalculator = true;
                this.calculatorUrl = `${environment.loanCalculatorUrl}`;
                this.isLoading = false;
            } else if (currentRoute.snapshot.routeConfig?.path == 'lansrettur') {
                this.checkLoanEligibility();
                this.isLoanEligibility = true;
            }
        }
    }

    // Fetches data for the current child route, handles iframe visibility
    getPageData(url: string) {
        this.showIframe = true;

        // Generate a JWT for the Libra service and authenticate the loan portal
        this.authService.generateExternalServiceJWT(GENERATE_EXTERNAL_SERVICE_JWT_SYSTEM.LIBRA).subscribe((token) => {
            if (token) {
                this.authService.authenticateLibraLoan(token).then(() => {
                    this.iframeUrl = `${environment.libraPortalUrl}/${url}`;
                    this.isLoading = false;
                });
            } else {
                this.isLoading = false;
            }
        });
    }

    //#endregion

    //#region loan eligibility

    downloadPdf() {
        const sampleArr = base64ToArrayBuffer(this.pdfSrc);
        saveByteArray(`lansrettur_${this.userDetails?.ssn}` + '.pdf', sampleArr);
    }

    checkLoanEligibility() {
        this.userService.currentUser
            .pipe(
                filter((x) => x != null),
                take(1)
            )
            .subscribe((user) => {
                this.userDetails = user;

                const loanEligibility = this.loansService.loanEligibility(this.userDetails!.ssn);
                const loanEligibilityPdf = this.loansService.loanEligibilityPdf(this.userDetails!.ssn);

                combineLatest([loanEligibility, loanEligibilityPdf])
                    .subscribe(([loanEligibilityRes, loanEligibilityPdfRes]) => {
                        this.selectedDocumentInfo = loanEligibilityPdfRes;
                        this.loanEligibilityInfo = loanEligibilityRes;
                        this.pdfSrc = 'data:application/pdf;base64,' + loanEligibilityPdfRes.base64File;
                        this.setPdfContainerStyle();
                    })
                    .add(() => {
                        this.isLoading = false;
                    });
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setPdfContainerStyle();
    }

    setPdfContainerStyle() {
        // Adjust zoom of pdf document based on screen width
        let viewportHeight = window.innerHeight;
        if (window.innerWidth < 500) {
            this.zoomValue = 2;
            viewportHeight = viewportHeight * 3;
        } else if (window.innerWidth < 1200) {
            this.zoomValue = 1.5;
            viewportHeight = viewportHeight * 2;
        } else {
            this.zoomValue = 1.3;
            viewportHeight = viewportHeight * 2;
        }
        this.renderer.setStyle(this.loansPdfContainer.nativeElement, 'height', `${viewportHeight}px`);
    }

    //#endregion
}
